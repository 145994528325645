import './styles.css';
import {
  IoChevronForwardOutline,
  IoReorderFourOutline,
  IoListOutline,
  IoChevronDownOutline,
  IoPersonCircleOutline,
  IoCalendarOutline,
} from 'react-icons/io5'
import { Link, useNavigate } from 'react-router-dom';
import scheduled_services from '../../../../../languages/scheduled_services'
import tx from '../../translation';

interface SubHeader_ {
  setSideBarIsVisible: any,

  OpenCatSideBar: () => void,
  uid: any

}

function SubHeader({
  setSideBarIsVisible,

  OpenCatSideBar,
  uid
}: SubHeader_) {

  const makeVisible = () => {
    setSideBarIsVisible(true)
  }

  return (
    <div className="SubHeader">
      <div className='sh-center'>
        <div className='sh-c-left'>
          {
            uid
              ? <SHButton
                text={scheduled_services}
                icon={<IoCalendarOutline size={16} />}
              />
              :
              <Link to='/signin'>
                <SHButton
                  text={'Cadastre-se'}
                  icon={<IoPersonCircleOutline size={20} />}
                />
              </Link>
          }
        </div>
        <div className='sh-c-mid'>
          <button
            className='sh-buttons sh-button-allc sh-cats'
            onClick={OpenCatSideBar}
          >
            <div className='sh-icons-wrap sh-icon-list'>
              <IoListOutline />
            </div>
            {tx.cats.main}
            <span style={{ margin: '5px 20px 0 10px' }}>
              <IoChevronDownOutline />
            </span>
          </button>
          <ul className='sh-cats-list center'>
            <Link to='/search?search='>
              <li>
                <h5>{tx.cats.all}</h5>
                <span />
              </li>
            </Link>
            <Link to='/search?search=pintor'>
              <li>
                <h5>{tx.cats.list[0]}</h5>
                <span />
              </li>
            </Link>
            <Link to='/search?search=Designer'>
              <li>
                <h5>{tx.cats.list[1]}</h5>
                <span />
              </li>
            </Link>
            <Link to='/search?search=Diarista'>
              <li>
                <h5>{tx.cats.list[2]}</h5>
                <span />
              </li>
            </Link>
          </ul>
          <button
            className='sh-buttons sh-button-allc sh-visible'
            onClick={makeVisible}
          >
            <div className='sh-icons-wrap sh-icon-list'>
              <IoReorderFourOutline />
            </div>
          </button>
        </div>
      </div>
    </div>
  );
}

const SHButton = ({ text, icon }: { text: string, icon: JSX.Element }) => {
  const navigate = useNavigate();
  return (
    <button
      className='sh-buttons sh-button-location'
      onClick={() => navigate('/orders')}
    >
      <div className='sh-icons-wrap sh-icon-location'>
        {icon}
      </div>
      <p className='ellipsis' style={{ maxWidth: '180px' }}>{text}</p>
      <div className='sh-icons-wrap sh-icon-forward'>
        <IoChevronForwardOutline />
      </div>
    </button>
  )
}
export default SubHeader;
