import { IoChevronForwardOutline } from 'react-icons/io5';
import './styles.css'
function ShLi({ children, text, onClick, borderless=false }: any) {
    return (
        <li
            style={{ border: borderless ? 'none' : '' }}
            className='SB-li'
            onClick={onClick}
        >
            <div>
                <span>
                    {children}
                </span>
                {text}
            </div>
            <span className='SH-li-forward'>
                <IoChevronForwardOutline />
            </span>
        </li>
    );
}

export default ShLi