import translations_ from "../../../interfaces/translations.interface";
import lang from "../../../languages/lang";

interface i_ {
    label: string,
    placeholder: string
}

interface tx_ {
    pics: {
        perfil: string,
        imagens: string
    }
    await: string,
    btn: string,
    anm: string,
    remove_data: {
        w_a: {
            p: string,
            strong: string
        },
        exc_inf: {
            t: string,
            p1: string,
            p2: {
                p21: string,
                p22: string
            }
        },
        input: string,
        btn: string,
        w_a2: string
    },
    regis: {
        alert: [string, string],
        p_id: string,
        inputs: [i_, i_, i_,i_],
        agree: string,
        sav_btn: string
    }
    r_pass: {
        shake:string,
        h4: string,
        p: string,
        btn: string,
        w_a: {
            a1:string,
            a2: string
        }
    },
    r_del: {
        w_a: string,
        w_a2:string,
        rev_acc:string,
        get_pass:string,
        w_a3: {
            strong: string,
            p: string
        }
    },
    r_lo: [
        string, 
        string
    ], 
    main: [
        string,string,string,
        string,string,string
    ]
}

const def_tx: translations_<tx_> = {
    "pt-BR": {
        pics: {
            perfil: 'A foto de perfil será atualizada em breve',
            imagens: 'As suas imagens serão atualizadas em breve'
        },
        await: 'Pode levar alguns minutos para que a mudança seja exibida em todos os serviços',
        btn: 'Entendi',
        anm: 'anúncio:',
        remove_data: {
            w_a: {
                strong: 'Excluir informações',
                p: 'Aguarde, responderemos o mais rápido possível.'
            },
            exc_inf: {
                t: 'Pedir para excluir informações',
                p1: 'Se você deseja excluir determinados dados, mas não encontrou a opção, basta enviar uma mensagem especificando quais dados são, e nossa equipe técnica os removerá para você.',
                p2: {
                    p21: 'Importante: ',
                    p22: ' Ao excluir sua conta todos seus dados são removidos.'
                }
            },
            input: "Quais dados você deseja excluir?",
            btn: 'Pedir remoção',
            w_a2: 'Informe quais dados você deseja excluir.'
        },
        regis: {
            alert: [
                'Nome deve ter no mínimo 3 caracteres',
                'Descrição deve ter no máximo 510 caracteres'
            ],
            p_id: 'Identificador público',
            inputs: [
                {
                    label: 'Nome Completo',
                    placeholder: 'Seu nome e sobrenome ou razão social'
                },
                {
                    label: 'Profissão',
                    placeholder: 'Ex: Psicólogo'
                },
                {
                    label: 'Sobre mim',
                    placeholder: 'Conte-nos sobre você e seu trabalho.'
                },
                {
                    label: 'Telefone',
                    placeholder: 'Exemplo: (11) 9 9988-7766'
                }
            ],
            agree: 'Ao informar seu número de contato você concorda em divulga-lo nos seus anúncios.',
            sav_btn: 'Salvar alterações'
        },
        r_pass: {
            shake: 'Falha ao enviar email, tente novamente mais tarde.' ,
            h4: 'Alteração de senha',
            p: 'Ao confirmar você recebera um e-mail com as instruções e o link para criar uma nova senha.',
            btn: 'Alterar senha',
            w_a:{
                a1:"Alteração de senha",
                a2:"Deseja confirmar a alteração de senha?"
            }
        },
        r_del: {
            w_a: 'Por favor, informe sua senha.',
            w_a2: 'Por favor, remova seus anúncios antes de deletar sua conta.',
            rev_acc: 'Remover minha conta',
            get_pass:"Informe sua senha",
            w_a3: {
                strong: "Remover minha conta",
                p: "Deseja deletar sua conta?"
            }
        },
        r_lo: [
            'Sair', 
            'Você realmente quer sair?'
        ],
        main: [
            'Configurações',
            'Gerenciar dados',
            'Dados pessoais',
            'Configure seus dados pessoais',
            'Gerenciamento de dados',
            'Gerenciamento e exclusão de dados sensíveis'
        ]
    },
    "en-US": {
        pics: {
            perfil: 'Profile picture will be updated soon',
            imagens: 'Your images will be updated soon'
        },
        await: 'It may take a few minutes for the change to appear across all services.',
        btn: 'I understood',
        anm: 'ads:',
        remove_data: {
            w_a: {
                p: 'Delete information',
                strong: 'Please wait, we will respond as soon as possible.'
            },
            exc_inf: {
                t: 'Request to delete information',
                p1: 'If you want to delete certain data but didn\'t find the option, just send a message specifying what data it is, and our technical team will remove it for you.',
                p2: {
                    p21: 'Important: ',
                    p22: ' When you delete your account all your data is removed.'
                }
            },
            input: "What data do you want to delete?",
            btn: 'Request removal',
            w_a2: 'Please let us know what data you want to delete.'
        },
        regis: {
            alert: [
                'Name must have at least 3 characters',
                'Description must be a maximum of 510 characters'
            ],
            p_id: 'Public identifier',
            inputs: [
                {
                    label: 'Full Name',
                    placeholder: 'Your first and last name or company name'
                },
                {
                    label: 'Profession',
                    placeholder: 'Ex: Psychologist'
                },
                {
                    label: 'About me',
                    placeholder: 'Tell us about yourself and your work.'
                },
                {
                    label: 'Phone number',
                    placeholder: 'Example: +44 20 1234 5678'
                }
            ],
            agree: 'By providing your contact number you agree to display it in your advertisements.',
            sav_btn: 'Save changes'
        },
        r_pass: {
            shake: 'Failed to send email, please try again later.' ,
            h4: 'Password change',
            p: 'Upon confirmation, you will receive an email with instructions and a link to create a new password.',
            btn: 'Change password',
            w_a:{
                a1:"Password change",
                a2:"Do you want to confirm the password change?"
            }
        },
        r_del: {
            w_a: 'Please enter your password.',
            w_a2: 'Please remove your ads before deleting your account.',
            rev_acc: 'Remove my account',
            get_pass:"Enter your password",
            w_a3: {
                strong: "Remover minha conta",
                p: "Do you want to delete your account?"
            }
        },
        r_lo: [
            'Log out', 
            'Do you really want to leave?'
        ],
        main: [
            'Settings',
            'Manage data',
            'Personal data',
            'Configure your personal data',
            'Data management',
            'Management and deletion of sensitive data'
        ]
    }
};

const tx = def_tx[lang];
export default tx;