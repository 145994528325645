import { useContext, useState } from "react";
import api from "../../../../config/connection/axios";
import FormButton from "../../../Form/Inputs/nested/FormButton";

import RegCheck from "./RegCheck";
import Input from "../../../Form/Inputs/nested/Input";
import WindowAlertContext from "../../../../context/windowAlert.context";
import headers from "../../../../constants/localStorage/headers";
import tx from "../translation";
import tryAgainLater from "../../../../languages/tryAgainLater";

function RegCAskToRemoveData() {
    const [sent, setSent] = useState<boolean>(false)
    const [spinner, setSpinner] = useState<boolean>(false);
    const [message, setMessage] = useState<string>('');

    const windowAlert = useContext(WindowAlertContext);

    const handleSendEmail = async (e: any, alertfy: any) => {
        e.preventDefault();

        if (message === '')
            return windowAlert?.confirm({
                shake: tx.remove_data.w_a2
            });

        const u = JSON.parse(window.localStorage.getItem('serss-user') || '{}');

        setSpinner(true)

        await api.put(
            '/feedback/manage/data',
            {
                suggestion: '[delete_this_data]' + message,
                evaluation: '',
                uid: u.uid,
                email: 'not_defined',
                phone: 'not_defined',
                name: u.name,
                code: 'manage_data'
            },
            { headers: headers() }
        )
            .then(r => {
                setSpinner(false);
                setSent(true);
                windowAlert?.confirm({ 
                    strong: tx.remove_data.w_a.strong, 
                    p: tx.remove_data.w_a.p
                 })
            })
            .catch(() => {
                setSpinner(false);
                alertfy(tryAgainLater);
            })
    }

    return (
        <form>
            <div className='RegC-title'>
                <h4>{tx.remove_data.exc_inf.t}</h4>
                <p>
                    {tx.remove_data.exc_inf.p1}
                </p>
                <p>
                    <strong>{tx.remove_data.exc_inf.p2.p21}</strong> {tx.remove_data.exc_inf.p2.p22}
                </p>
            </div>
            <div>
                <Input
                    placeholder={tx.remove_data.input}
                    space={0}
                    value={message}
                    onChange={setMessage}
                />
            </div>
            <div className='reg-default-buttons'>
                {
                    sent
                        ? <RegCheck />
                        : <FormButton
                            text={tx.btn}
                            style={{
                                borderRadius: '100px',
                                backgroundColor: 'rgb(240, 190, 181)',
                                color: 'tomato',
                                border: '0px'
                            }}
                            onClick={handleSendEmail}
                            spinner={spinner}
                        />
                }
            </div>
        </form>
    )
}

export default RegCAskToRemoveData;