import translations_ from "../../../interfaces/translations.interface";
import lang from "../../../languages/lang";

interface i_ {     
    text: string,
    to: string
}

type tx_ = [i_, i_, i_, i_, i_]


const def_tx: translations_<tx_> = {
    "pt-BR": [
        {
            
            text: 'Designers',
            
            to: 'designer'
        },
        {
            
            text: 'Advogados',
            
            to: 'advogad'
        },
        {
            
            text: 'Eletricistas',
            
            to: 'eletricista'
        },
        {
            
            text: 'Pintores',
            
            to: 'pintor'
        },
        {
            
            text: 'Pedreiros',
            
            to: 'pedreiro'
        }
    ],
    "en-US": [
        {
            
            text: 'Designers',
            to: 'designer'
        },
        {
            
            text: 'Lawyers',
            to: 'lawyers'
        },
        {
            
            text: 'Electrician',
            to: 'electrician'
        },
        {
            
            text: 'Painter',
            to: 'painter'
        },
        {
            
            text: 'Bricklayer',
            to: 'bricklayer'
        }
    ]
};

const tx = def_tx[lang];
export default tx;