import translations_ from "../../../interfaces/translations.interface";
import lang from "../../../languages/lang";

type tx_ = [
    string, string, string, string, string,
    string, string, string, string, string,
    string, string, string, string, string,
    string, string, string, string, string,
    string, string, string, string, string,
    string
]

const def_tx: translations_<tx_> = {
    "pt-BR": [
        'Confirmar os termos',
        'Idade mínima: 18 anos',
        'Formato de email invalido',
        'Nome deve ter no mínimo 3 caracteres',
        'Nome deve ter no mínimo 3 caracteres',
        'Senha deve ter no mínimo 6 caracteres',
        'Senha deve ter no mínimo 6 caracteres',
        'Nome Completo ou razão social invalido "@"',
        'Profissão invalida "@"',
        'E-mail já está em uso, por favor faça login',
        'Criar seu cadastro',
        'Nome Completo ou razão social *',
        'Seu nome e sobrenome',
        'Profissão (opcional)',
        'Ex: Psicólogo',
        'E-mail *',
        'exemplo@email.com',
        'Senha *',
        'A plataforma é exclusiva para maiores de idade:',
        'Declaro ter mais de 18 anos de idade.',
        'Os Termos e as políticas devem ser respeitados:',
        'Concordo com os termos, condições e políticas, e confirmo que as informações fornecidas são verdadeiras.',
        'Ao criar o seu cadastro, você confirma a veracidade das informações fornecidas e concorda com a ',
        ' Política de Privacidade ',
        ' e os ',
        'Termos e Condições de Uso'

    ],
    "en-US": [
        'Confirm the terms',
        'Minimum age: 18 years',
        'Invalid email format',
        'Name must have at least 3 characters',
        'Name must have at least 3 characters',
        'Password must have at least 6 characters',
        'Password must have at least 6 characters',
        'Invalid full name or company name "@"',
        'Invalid profession "@"',
        'Email is already in use, please log in',
        'Create your account',
        'Full name or company name *',
        'Your first and last name',
        'Profession (optional)',
        'Ex: Psychologist',
        'Email *',
        'example@email.com',
        'Password *',
        'The platform is exclusive for adults:',
        'I declare that I am over 18 years of age.',
        'The Terms and Conditions policies must be respected:',
        'I agree to the terms, conditions and policies, and confirm that the information provided is true.',
        'By creating your registration, you confirm the veracity of the information provided and agree to the ',
        ' Privacy Policy ',
        ' and the ',
        ' Terms and Conditions of Use'
    ]
};

const tx = def_tx[lang];
export default tx;