
import '../styles.css'
import ChatLi from '../ChatLi'
import { useEffect } from 'react';
import Chat_ from '../../chat-interfaces/chat.interface';
import receiver_ from '../../chat-interfaces/receivers.interface';
import inBox_ from '../../chat-interfaces/inBox.interface';
import { screenName_ } from '../../chat-types/screenName.types';



interface List_ {
    socket: any,

    receivers: Array<receiver_>
    setReceivers: (receivers: Array<receiver_>) => void,
    inBox: Array<inBox_> | undefined,
    setInBox: (ib: Array<inBox_>) => void,

    uid: string,
    handleScreen: (
        r: Chat_['simpleReceiver'] | null,
        sn: screenName_
    ) => void
}

function List({
    socket,
    receivers, setReceivers,
    inBox, setInBox,
    uid,
    handleScreen
}: List_) {

    useEffect(() => {
        const fetchList = async () => {
            if (!uid) return;
            socket.emit('chatList', uid);
        }

        !receivers.length && fetchList();
    }, []);

    useEffect(() => {
        !receivers.length &&
            socket.on('loadList', (newList: any) => {

                setReceivers(newList.list);
                setInBox(newList.inBox.senders_list);
                return;
            })
    }, [socket]);

    return (
        <div id='ChatContacts'>
            <div className='chatC-list-wrapper center'>
                <ul className='chatC-list'>
                    <>
                        {
                            receivers.map((it: receiver_, idx: number): any => {
                                return it.name
                                    && <div
                                        key={idx}
                                        onClick={() => handleScreen(
                                            {
                                                name: it.name,
                                                uid: it.receiver
                                            },
                                            'chatting'
                                        )}
                                    >
                                        <ChatLi
                                            inBox={inBox && inBox.find(sender => sender.sender_uid === it.receiver)}
                                            receiver={it.receiver}
                                            name={it.name}
                                        />
                                    </div>

                            })
                        }
                    </>
                </ul>
            </div>
        </div>
    )
}


export default List
