import translations_ from "../../../interfaces/translations.interface";
import lang from "../../../languages/lang";

interface tx_ {
    a: [
        string, string, string, string
    ],
    b: [
        string, string, string, string, string,
        string
    ],
    c: [
        string, string, string, string, string,
        string, string, string, string
    ],
    d: {
        check: string,
        title: string,
        button: string,
        content: [
            string,
            string,
            string
        ]
        terms: string
    },
    e: string,
    taxs: [
        string, string
    ],
    edit: [
        string, string
    ],
    function: [
        string, string, string
    ],
    main: string
}

const def_tx: translations_<tx_> = {
    "pt-BR": {
        a: [
            "Campos obrigatórios",
            'Adquira uma vez e ',
            ' anuncie o quanto quiser!',
            'Anunciar',
        ],
        b: [
            'Serviço',
            'Ex: Assentamento de piso',
            'Descrição',
            'Ex: Colocação de revestimentos no chão, como cerâmica, porcelanato ou laminado, utilizando argamassa e ferramentas adequadas para garantir um acabamento uniforme e duradouro.',
            'Valor (R$)',
            'Confirmar',

        ],
        c: [
            'Serviço',
            'Ex: Assentamento de piso',
            'Descrição',
            'Ex: Colocação de revestimentos no chão, como cerâmica, porcelanato ou laminado, utilizando argamassa e ferramentas adequadas para garantir um acabamento uniforme e duradouro.',
            'Valor (R$)',
            'Contato',
            'Telefone',
            'Exemplo: +44 20 1234 5678',
            'Ao informar seu número de contato você concorda em divulga-lo nos seus anúncios.'
        ],
        d: {
            check: 'Seu anúncio já está disponível!',
            title: 'As suas imagens serão atualizadas em breve:',
            button: 'Concordo',
            content: [
                'Pode levar',
                ' alguns minutos',
                'para que a mudança seja exibida em todos os serviços.'
            ],
            terms: 'Termos de uso'
        },
        e: 'Adicione mais serviços',
        taxs: [
            'Você recebe:',
            'Taxa de intermediação'
        ],
        edit: [
            'Edição do anúncio',
            'Localização'
        ],
        function: [
            'Formato de telefone inválido.',
            'Nome do serviço deve ter no mínimo 3 caracteres.',
            'Descrição deve ter no máximo 510 caracteres.'
        ],
        main: ' Quais serviços você está anunciando?'
    },
    "en-US": {
        a: [
            "Required fields",
            ' Buy once and ',
            ' Advertise as much as you want!',
            'Annunciate',
        ],
        b: [
            'Service',
            'Ex: Floor installation',
            'Description',
            'Ex: Installation of floor coverings, such as ceramic, porcelain or laminate, using mortar and appropriate tools to ensure a uniform and long-lasting finish.',
            'Price ($)',
            'Confirm'
        ],
        c: [
            'Service',
            'Ex: Floor installation',
            'Description',
            'Ex: Installation of floor coverings, such as ceramic, porcelain or laminate, using mortar and appropriate tools to ensure a uniform and long-lasting finish.',
            'Price ($)',
            'Contact',
            'Phone',
            'Example: +44 20 1234 5678',
            'By providing your contact number, you agree to display it in your advertisements.'
        ],
        d: {
            check: 'Your ad is now available!',
            title: 'Your images will be updated soon:',
            button: 'I agree',
            content: [
                'It may take',
                'a few minutes',
                'for the change to be displayed across all services.'
            ],
            terms: 'Terms of use'

        },
        e: 'Add more services',
        taxs: [
            'You receive:',
            'Intermediation fee'
        ],
        edit: [
            'Ad editing',
            'Location'
        ],
        function: [
            'Invalid phone format.',
            'Service name must be at least 3 characters long.',
            'Description must be at most 510 characters long.'
        ],
        main: ' What services are you advertising?'
    }
};

const tx = def_tx[lang];
export default tx;