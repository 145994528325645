import io from 'socket.io-client';
import urlHeroku from './urlHeroku';
const u = JSON.parse(window.localStorage.getItem('serss-user') || '{}');

const url = urlHeroku;

const socket = io(url, {
  query: { token: u.token }
});

export default socket;