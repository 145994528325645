import tx from "../translation";

function DListTitle() {
    return (
        <div style={{ borderBottom: '1px solid var(--border)' }}>
            <h2 style={{

                opacity: 1,
                color: 'var(--dark)',
                fontSize: '18px',
                textTransform: 'uppercase'
            }}
            >
                {tx.choose}
            </h2>
        </div>
    )
}

export default DListTitle;