import translations_ from "../../interfaces/translations.interface";
import lang from "../../languages/lang";

interface tx_ {
    login: {
        t: string,
        p: string
    },
    signin: {
        t: string,
        p: string
    }
}

const def_tx: translations_<tx_> = {
    "pt-BR": {
        login: {
            t: 'Não tem cadastro?',
            p: 'Cadastre-se'
        },
        signin: {
            t: 'Já tem cadastro?',
            p: 'Entrar'
        }
    },
    "en-US": {
        login: {
            t: 'Don\'t have a registration?',
            p: 'Sign in'
        },
        signin: {
            t: 'Already registered?',
            p: 'Login'
        }
    }
};

const tx = def_tx[lang];
export default tx;