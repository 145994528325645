import translations_ from "../../../interfaces/translations.interface";
import lang from "../../../languages/lang";

interface i_ {
    title: string,
    subtitle: string
}

type case_ = string

type tx_ = [i_, i_, i_, case_, i_, i_, case_, i_, i_, case_, i_, i_, case_, i_, case_]

const def_tx: translations_<tx_> = {
    "pt-BR": [
        {
            title: 'Mais pesquisados',
            subtitle: 'Serviços mais pesquisados'
        },
        {
            title: 'Profissões',
            subtitle: 'Os profissionais que você precisa'
        },
        {
            title: 'Mais Procurados',
            subtitle: 'Confira os mais procurados'
        },
        'Recomendados',
        {
            title: 'Junte-se à Comunidade',
            subtitle: 'Quando a comunidade cresce, você também cresce'
        },
        {
            title: 'Recomendados',
            subtitle: 'Confira nossas recomendações'
        },
        'Recomendados',
        {
            title: 'Tudo que você precisa',
            subtitle: 'Tudo que você procura está aqui'
        },
        {
            title: 'Muito Mais',
            subtitle: 'Confira esses destaques'
        },
        'Destaques',
        {
            title: 'Categorias',
            subtitle: 'Categorias mais populares'
        },
        {
            title: 'Descubra',
            subtitle: 'Descubra mais serviços'
        },
        'Descubra',
        {
            title: 'Populares',
            subtitle: 'Veja o que está bombando'
        },
        'Populares'
    ],
    "en-US": [
        {
            title: 'Most searched',
            subtitle: 'Most searched services'
        },
        {
            title: 'Professions',
            subtitle: 'The professionals you need'
        },
        {
            title: 'Most Wanted',
            subtitle: 'Check out the most wanted'
        },
        'Recommended',
        {
            title: 'Join the Community',
            subtitle: 'When the community grows, you grow too'
        },
        {
            title: 'Recommended',
            subtitle: 'Check out our recommendations'
        },
        'Recommended',
        {
            title: 'Everything you need',
            subtitle: 'Everything you are looking for is here'
        },
        {
            title: 'Much More',
            subtitle: 'Check out these highlights'
        },
        'Highlights',
        {
            title: 'Categories',
            subtitle: 'Most popular categories'
        },

        {
            title: 'Discover',
            subtitle: 'Discover more services'
        },
        'Discover',
        {
            title: 'Popular',
            subtitle: 'See what\'s in the most searched'
        },
        'Popular'
    ]
};

const tx = def_tx[lang];
export default tx;