
import CentralContainer from "../components/rlx-components/nested/CentralContainer"
import { IoChevronBack } from "react-icons/io5";
import CentralMenu from "../components/CentralMenu";
import { useState } from "react";
import CentrlMenu_ from "../interfaces/centralMenu.interface";
import { useLocation, useNavigate } from "react-router-dom";
import CMenuManageData from "../components/CentralMenu/nested/CMenuManageData";
import CMenuManageFraud from "../components/CentralMenu/nested/CMenuManageFraud";
import Screen from "../components/ScreenComponent";
import CentralSelector from "../components/CentralSelector";
import help_center from "../languages/help_center";
import tx from "./translation";

function Central() {
  window.scrollTo(0, 0);

  const location: any = useLocation();

  const hash = location.hash;

  const initial = parseInt(hash.replace('#', ''));

  const navigate = useNavigate();

  const [centralMenu, setCentralMenu] = useState<CentrlMenu_>(
    {
      screen: initial ? 3 : 0,
      code: initial ? 'fraud' : ''
    }
  );

  const uid = JSON.parse(window.localStorage.getItem('serss-user') || '{}').uid;

  const handleExitCentral = () => {
    if (centralMenu.screen === 0) {
      navigate(-1)
    } else setCentralMenu({ code: '', screen: 0 })
  }

  return (
    <Screen>
      <div
        className='center'
        style={{
          borderBottom: '1px solid var(--border)',
          height: '80px', width: '100%',
          backgroundColor: 'white',
          justifyContent: 'space-between'
        }}
      >
        <div
          onClick={handleExitCentral}
        >
          <div
            style={{
              width: '70px',
              display: 'flex',
              justifyContent: 'flex-end'
            }}
          >
            <span
              style={{
                border: '1px solid #c4c4c4',
                width: 'fit-content',
                color: '#696969',
                padding: '14px',
                borderRadius: '14px',
                boxShadow: '-2px 2px 6px var(--border)'
              }}
              className="center"
            >
              <IoChevronBack />
            </span>
          </div>
        </div>
        <div
          className="center"
          style={{
            width: '100%'
          }}
        >
          <h4
            style={{
              fontSize: '18px',
              color: '#696969'
            }}
          >
            {help_center}
          </h4>
        </div>
        <div style={{ width: '70px' }} />
      </div >
      <CentralSelector
        strong={
          <>
            {
              centralMenu.code === 'help' &&
              tx.central[0]
            }
            {
              centralMenu.code === 'manage_data' &&
              tx.central[1]
            }
            {
              (
                centralMenu.code !== 'manage_data' &&
                centralMenu.code !== 'help'
              )
              && tx.central[2]
            }
          </>
        }
      />
      {
        centralMenu.screen === 0 &&
        <CentralMenu
          uid={uid}
          setCentralMenu={setCentralMenu}
        />
      }
      {

        centralMenu.screen === 1 &&
        <CentralContainer
          uid={uid}
          centralMenuCode={centralMenu.code}
        />

      }
      {
        centralMenu.screen === 2 &&
        <CMenuManageData
          setCentralMenu={setCentralMenu}
        />
      }
      {
        centralMenu.screen === 3 &&
        <CMenuManageFraud
          setCentralMenu={setCentralMenu}
        />
      }
    </Screen>
  )
}

export default Central