
import CentralSelector from '../../CentralSelector';

import { IoArrowForward, IoCreateOutline, IoTrashBinOutline } from 'react-icons/io5';
import currency from '../../../functions/currency';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import api from '../../../config/connection/axios';
import headers from '../../../constants/localStorage/headers';
import hideContactsDescription from '../../../functions/hideContactsDescription.simple';
import tx from './translation';

interface work_ {
    id: number,
    title: string,
    description: string,
    price: number
}

function ListWorksContainer() {

    const [works, setWorks] = useState<work_[]>([]);

    useEffect(() => {
        const handleSubmit = async () =>
            await api.get(
                '/cards/belongs',
                { headers: headers() }
            )
                .then(r => {
                    const result: work_[] = r.data;
                    setWorks(result);
                })
                .catch(err => console.error(err));

        handleSubmit();
    }, [])

    return (
        <div id='WorkCreate' className='center column'>

            <h2>{tx.my}</h2>
            <div style={{ width: '90%' }}>
                <CentralSelector
                    strong='Ativos'
                />
            </div>
            <ul
                className='center column'
                style={{
                    width: '100%'
                }}
            >
                {
                    (works && works.length)
                        ? works.map((it, idx) =>
                            <ListWorksContainerLi
                                work={it}
                                key={idx}
                            />
                        )
                        : <Link to='/work/create'>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    padding: '20px 20px',
                                    border: '2px solid var(--border)',
                                    color: 'var(--dark)',
                                    borderRadius: '14px',
                                    margin: '80px 0 -50px 0'
                                }}
                            >
                                <p
                                    style={{ margin: '0 0 10px 0', opacity: .5 }}
                                >
                                    {tx.desc}
                                </p>
                                <div
                                    className='center'
                                    style={{ opacity: .7 }}
                                >
                                    <strong style={{ marginRight: '8px' }}>
                                        {tx.now}
                                    </strong>
                                    <IoArrowForward />
                                </div>
                            </div>
                        </Link>
                }
            </ul>

            <div style={{ height: '160px' }} />
        </div>
    )
}

const ListWorksContainerLi = ({ work }: { work: any }) => {
    const [areUSure, setAreUSure] = useState<boolean>(false);

    const deleteWorkSubmit = async () => {
        await api.delete(
            `/works/delete/${work.id}`,
            { headers: headers() }
        ).then(() => window.location.reload())
    }

    return (
        <li style={{
            width: '90%',
            display: 'flex',
            padding: '30px 0',
            borderBottom: '1px solid var(--border)',
            flexDirection: 'column'
        }}>
            <Link to={`/details/${work.id}`}>
                <div style={{ display: 'flex' }}>
                    <div
                        style={{
                            marginRight: '20px'
                        }}
                    >
                        <div
                            style={{
                                position: 'relative',
                                width: '80px',
                                height: '80px',
                                backgroundColor: 'var(--border)',
                                borderRadius: '12px'
                            }}
                        />
                    </div>
                    <div>
                        <strong>{work.title}</strong>
                        <p
                            style={{ opacity: .7, margin: '20px 0' }}
                        >
                            {hideContactsDescription(work.description)}

                        </p>
                        <strong>
                            {currency(work.price)}
                        </strong>
                    </div>
                </div>
            </Link>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginTop: '20px',
                }}
            >

                <Link
                    to='/work/edit'
                    state={{ wid: work.id }}
                >
                    <LWCLiBtn
                        icon={<IoCreateOutline />}
                        txt={'Editar'}
                    />
                </Link>
                <div
                    onClick={
                        areUSure
                            ? deleteWorkSubmit
                            : () => setAreUSure(true)
                    }
                    style={{ cursor: 'pointer' }}
                >
                    <LWCLiBtn
                        icon={<IoTrashBinOutline />}
                        color={
                            areUSure
                                ? 'tomato'
                                : undefined
                        }
                        txt={
                            areUSure
                                ? tx.exc.e1
                                : tx.exc.e2
                        }
                    />
                </div>
            </div>
        </li>
    )
}

const LWCLiBtn = (
    { icon, txt, color }:
        { icon: any, txt: any, color?: string }
) =>
    <div
        className='center'
        style={{
            marginLeft: '20px',
            fontSize: '12px',
            color:
                color
                    ? color
                    : 'inherit'
        }}
    >
        <div
            className='center'
            style={{ marginRight: '4px' }}
        >
            {icon}
        </div>
        {txt}
    </div>

export default ListWorksContainer