import translations_ from "../interfaces/translations.interface";
import lang from "./lang";

type tx_ = string

const def_tx: translations_<tx_> = {
    "pt-BR": "Valor mínimo R$ 50,00",
    "en-US": "Minimum value $ 50"
};

const tryAgainLater = def_tx[lang];
export default tryAgainLater;