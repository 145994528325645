import { IoCheckmarkCircle } from 'react-icons/io5';

import '../../chat-components/styles.css';
import inBox_ from '../../chat-interfaces/inBox.interface';
import Avatar from '../../../components/imagesComponents/Avatar';
import Icon from '../../../components/IconMarginRight';
import tx from './tx/translations';

interface ChatLi_ {
    receiver: string,
    name: string,
    style?: string,
    inBox?: inBox_ | undefined,
}

const ChatLi = (
    {
        receiver,
        name,
        style = 'c',
        inBox
    }: ChatLi_
) => {
    return (
        <li className={`chatC-li chatC-li--${style}`} style={{ position: 'relative' }}>

            <div
                style={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    height: '100%', paddingRight: '14px'
                }}
                className='center'
            >
                {
                    (inBox && inBox.amount) &&
                    <aside
                        className='chat-inBox center'
                    >
                        <p>{inBox.amount.toString()}</p>
                    </aside>
                }
            </div>

            <div className={`chatC-li-avatar--${style} center`}>
                <div style={{ position: 'relative' }}>
                    <Avatar
                        size={16}
                        uid={receiver}
                        borderRadius='16px'
                    />
                </div>
            </div>
            <div className={`chatC-li-infos chatC-li-infos--${style}`}>
                <div className={`chatC-li-texts chatC-li-texts--${style}`}>
                    {receiver === 'admin' && <h5 className='ellipsis'>{tx.t1}</h5>}
                    <h2 className='ellipsis' style={{ display: 'flex' }}>
                        {name}
                        {
                            receiver === 'admin' &&
                            <Icon style={{ margin: '1px 0 0 6px' }}>
                                <IoCheckmarkCircle color='dodgerblue' />
                            </Icon>
                        }
                    </h2>
                    {
                        (inBox && inBox.last_text) &&
                        <p>{inBox.last_text}</p>
                    }
                </div>
            </div>
        </li>
    )
}

export default ChatLi