import translations_ from "../../../interfaces/translations.interface";
import lang from "../../../languages/lang";

type tx_ = {
    home: string,
    search: string,
    Scheduled: string,
    menu: string
}

const def_tx: translations_<tx_> = {
    "pt-BR": {
        home: 'Início',
        search: 'Pesquisar',
        Scheduled: 'Agendados',
        menu: 'Menu'
    },
    "en-US": {
        home: 'Home',
        search: 'Search',
        Scheduled: 'Scheduled',
        menu: 'Menu'
    }
};

const tx = def_tx[lang];
export default tx;