import { useContext } from "react";
import ProfDetailsSquare from "../ProfDetailsSquare"
import DetailsContext from "../../context";
import Banner from "../../../../imagesComponents/Banner";

const TopProfDetails = () => {
  const { user_uid, id } = useContext(DetailsContext).work || {};
  return (
    <div
      className='TopProfDetails center'
    >
      <div style={{
        position: 'absolute',
        top: 0,
        width: '100%',
        height: '220px',
        backgroundColor: 'var(--green)',
        maxHeight: '200px',
        overflow:'hidden'
      }}
      >
        <Banner
          uid={user_uid}
          wid={id}
        />
      </div>
      <ProfDetailsSquare />
    </div>
  )
};

export default TopProfDetails;