
import { IoChevronForward } from "react-icons/io5"
import currency from "../../../../functions/currency"

import { subwork_ } from "../interface"
import { useContext } from "react"
import DetailsContext from "../context"
import { useNavigate } from "react-router-dom"
import Banner from "../../../imagesComponents/Banner"
import hideContactsDescription from "../../../../functions/hideContactsDescription.simple"

function DetailsSubworksList() {
    const { subworks, work } = useContext(DetailsContext);
    return (
        <div className="center">
            <div className='DetailsSubworksList'>
                <DetailsSubworksLi
                    subwork={{
                        id: 0,
                        title: work.title,
                        description: work.description,
                        price: work.price
                    }}

                    work={{
                        work_id: work.id,
                        user_uid: work.user_uid
                    }}
                />
                {
                    subworks.map((subwork, idx) =>
                        <DetailsSubworksLi
                            subwork={subwork}
                            key={idx}
                        />
                    )
                }
            </div>
        </div>
    )
}

interface DetailsSubworksLi_ {
    subwork: subwork_,


    work?: {
        work_id?: number,
        user_uid?: string
    }
}

const DetailsSubworksLi = (
    { subwork, work }:
        DetailsSubworksLi_
) => {
    const { setSelected } = useContext(DetailsContext);
    const navigate = useNavigate();
    const onCLick = () => {
        setSelected(subwork);
        navigate('selected');
    }
    return (<li
        onClick={onCLick}
        className='d-subworks-border-top'

    >
        <div
            className='d-subworks'
        >
            <div>
                <h4
                    style={{ margin: '0 0 6px 0' }}
                >
                    {subwork.title}
                </h4>
                <div
                    style={{
                        maxWidth: '96%',
                        marginBottom: '15px'
                    }}
                >
                    <p
                        className="description-ellipsis d-subwork-description"
                    >
                        {hideContactsDescription(subwork.description)}
                    </p>
                </div>
                <strong>{currency(subwork.price)}</strong>
            </div>
            <div>
                <div
                    className='d-img-wrap'
                >
                    {
                        work && <Banner
                            uid={work.user_uid}
                            wid={work.work_id}
                            idx={1}
                        />
                    }
                    <div
                        style={{
                            position: 'absolute',
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'end'
                        }}
                    >
                        <IoChevronForward
                            size={30}
                            color='var(--border)'
                        />
                    </div>
                </div>
            </div>
        </div>
    </li>)
}

export default DetailsSubworksList