
import ThinHeader from '../../components/Headers/ThinHeader'
import ListWorksContainer from '../../components/pageContent/ListWorksContainer'
import WcGreenBack from '../../components/pageContent/WorkCreateContainer/nested/WcGreenBack'
import SafeDiv from '../../components/SafeDiv'

function ListWorks() {
    return (
        <>
            <ThinHeader />
            <WcGreenBack />
            <SafeDiv>
                <ListWorksContainer />
            </SafeDiv>
        </>
    )
}

export default ListWorks