import { useState } from 'react'
import regex from '../../../functions/regex';
import './styles.css'

// Components:
import SafeDiv from '../../SafeDiv'
import GetAnAccount from '../../Form/GetAnAccount'

import { Link, useNavigate } from 'react-router-dom';
import api from '../../../config/connection/axios';
import Input from '../../Form/Inputs/nested/Input';
import Checkbox from '../../Form/Inputs/nested/Checkbox';
import FormButton from '../../Form/Inputs/nested/FormButton';
import Modal from '../../Modal';
import ContactUsCannot from '../../ContactUs/ContactUsConnot';
import ContactUsOnError from '../../ContactUs/ContactUsOnError';
import tx from './translation';

interface Signincontainer {
    mail: string
}

function SigninContainer({ mail }: Signincontainer) {

    const navigate = useNavigate();

    const [haveError, sethaveError] =
        useState<string | boolean>(false);
    const [name, setName] = useState<string>('');
    const [profession, setProfession] = useState<string>('');
    const [email, setEmail] = useState<string>(mail ? mail : '');
    const [password, setPassword] = useState<string | null>(null);
    const [eighteenPlus, setEighteenPlus] = useState<boolean>(false);
    const [agree, setAgree] = useState<boolean>(false);
    const [spinner, setSpinner] = useState<boolean>(false);

    const handleSubmit = async (e: any, alertfy: any) => {
        e.preventDefault();

        // Validations
        if (!agree)
            return alertfy(tx[0])
        if (!eighteenPlus)
            return alertfy(tx[1]);
        if (!regex.email.test(email.trim()))
            return alertfy(tx[2])
        if (typeof name !== 'string')
            return alertfy(tx[3])
        if (name.trim().length < 3)
            return alertfy(tx[4])
        if (!password)
            return alertfy(tx[5])
        if (password && password.length < 6)
            return alertfy(tx[6])
        if (regex.email.test(name))
            return alertfy(tx[7])
        if (regex.email.test(profession))
            return alertfy(tx[8])

        const data = {
            name: name.trim(),
            profession: profession,
            email: email.trim(),
            password,
            agree,
        }

        setSpinner(true)

        await api.get(
            `/code/generate/new/user/${email}`
        )
            .then(r => {
                const result = r.data;
                navigate('/validation', {
                    state: {
                        data: data
                    }
                })
            })
            .catch((e) => {
                setSpinner(false)
                if (
                    e && e.response && e.response &&
                    e.response.data && e.response.data.message
                ) {
                    if (e.response.data.err === 'err_email409')
                        alertfy(tx[9])
                    else alertfy(e.response.data.message)
                }
                else return sethaveError(e.response.data)
            })

    }

    return (
        <SafeDiv
            style={{
                backgroundColor: '#fff',
                paddingBottom: '40px'
            }}
        >
            <Modal
                visible={Boolean(haveError)}
                setVisible={sethaveError}
                children={<ContactUsOnError />}
            />
            <div id='Sign'>
                <div
                    style={{
                        width: '100%',
                        maxWidth: '560px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        flexDirection:
                            (window.innerWidth < 640)
                                ? 'column'
                                : 'row'
                    }}
                >
                    {window.innerWidth > 640
                        ? <>
                            <h2
                                style={{
                                    paddingBottom: '6px',
                                    borderBottom: '4px solid #00ae74'
                                }}
                            >
                                {tx[10]}
                            </h2>
                            <GetAnAccount to='/login' />
                        </>

                        : <>
                            <div>
                                <GetAnAccount to='/login' />
                            </div>

                            <h2
                                style={{
                                    margin: '50px 0 0 0',
                                    paddingBottom: '6px',
                                    borderBottom: '4px solid #00ae74'
                                }}
                            >
                                {tx[10]}
                            </h2>
                        </>
                    }
                </div>
                <form autoComplete="off">
                    <Input
                        label={tx[11]}
                        placeholder={tx[12]}
                        value={name}
                        onChange={setName}

                    />
                    <Input
                        label={tx[13]}
                        placeholder={tx[14]}
                        value={profession}
                        onChange={setProfession}

                    />
                    <Input
                        label={tx[15]}
                        placeholder={tx[16]}
                        value={email}
                        onChange={setEmail}
                    />
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between'
                        }}
                    >
                    </div>

                    <Input
                        label={tx[17]}
                        placeholder='••••••••'
                        type='password'
                        value={password}
                        onChange={setPassword}
                    />
                    <Checkbox
                        label={tx[18]}
                        space={18}
                        text={tx[19]}
                        value={eighteenPlus}
                        onChange={setEighteenPlus}
                    />
                    <Checkbox
                        label={tx[20]}
                        text={tx[21]}
                        space={10}
                        value={agree}
                        onChange={setAgree}
                    />
                    <ContactUsCannot>
                        <FormButton
                            style={{
                                backgroundColor: '#00ae74',
                                border: '1px solid #00ae74'
                            }}
                            text='Criar conta'
                            onClick={handleSubmit}
                            spinner={spinner}
                        />
                    </ContactUsCannot>
                </form>
                <div className='Sign-Policy'>
                    <p>
                        {tx[22]}
                        <span>
                            <Link
                                to='/politica_de_privacidade'
                                target="_blank"
                            >
                                {tx[23]}
                            </Link>
                        </span>
                        {tx[24]}
                        <span>
                            <Link to='/terms' target="_blank">
                                {tx[25]}
                            </Link>
                        </span>.
                    </p>
                </div>
            </div>
        </SafeDiv>
    )
}

export default SigninContainer