import { WindowAlert_ } from '../../interfaces/windowAlert.interface';
import './styles.css';
import tx from './translation';

export function WindowAlert({
  p,
  strong,
  btn = 'Ok!',
  shake, // Substitue o texto de "p" e adciona o efeito de balançar.

  confirm,
  alertOnly, // Quando tem uma função associada ao "confirm" mas não é uma escolha/pergunta.

  setVisible
}: WindowAlert_) {

  const close = (e: Event, answer: boolean = false) => {
    e.preventDefault();

    setVisible({ visible: false });

    (answer && confirm) && confirm();
  }

  return (
    <div
      id='WindowAlert'
      className={`WindowAlert center ${shake ? 'wa-shake' : 'wa-scroll'}`}
    >
      <div className="wa-box">
        <div className="wa-box-text">
          <strong>{strong}</strong>
          <p>{shake ? shake : p}</p>
        </div>
        <div className="wa-box-btns">
          {
            (confirm && !alertOnly) &&
            <button
              onClick={(e: any) => close(e)}
              className="wa-btns wa-box-btn-cancel"
            >
              {tx[0]}
            </button>
          }
          {
            !(shake && confirm) &&
            <button
              onClick={(e: any) => close(e, true)}
              className={`wa-btns wa-box-btn-okay ${!confirm && 'wa-btn-white'}`}
            >
              {(confirm && !alertOnly) ? tx[1] : btn}
            </button>
          }
        </div>
      </div>
    </div>
  )
}