import { IoCheckmarkSharp } from "react-icons/io5"

function RegCheck() {
    return (
        <div
            id="RegCheck"
            className="center"
        >
            <IoCheckmarkSharp />
        </div>
    )
}

export default RegCheck