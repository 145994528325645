import translations_ from "../interfaces/translations.interface";
import lang from "./lang";

type tx_ = string

const def_tx: translations_<tx_> = {
    "pt-BR": 'Por favor tente novamente mais tarde',
    "en-US": 'Please try again later.'
};

const tryAgainLater = def_tx[lang];
export default tryAgainLater;