import {
    IoAdd,

    IoCalendarClear,
    IoCalendarClearOutline,

    IoHome,
    IoHomeOutline,

    IoReorderFour,
    IoReorderFourOutline,

    IoSearch,
    IoSearchOutline
} from "react-icons/io5"
import { Link, useLocation } from "react-router-dom"

import './styles.css'
import tx from "./translation";

function NavigationBar() {
    const height = '58px'
    const location = useLocation();
    const current = location.pathname;
    const uid = JSON.parse(window.localStorage.getItem('serss-user') || '{}').uid;

    return (
        <div
            style={{
                display: navigator.userAgent.match(/expo/i)
                    ? 'flex'
                    : 'none',
            }}
        >
            <div id='NavigationBar'
                style={{ height }}
            >

                <Link to='/'>

                    {(current === '/')
                        ? <IoHome />
                        : <IoHomeOutline />
                    }
                    <p>{tx.home}</p>

                </Link >
                <Link to='/search'>
                    {(current === '/search')
                        ? <IoSearch />
                        : <IoSearchOutline />
                    }
                    <p>{tx.search}</p>
                </Link >

                <Link to={uid ? '/work/create' : '/login'}>
                    <span className="nav-add-btn center">
                        <IoAdd />
                    </span>
                </Link >
                <Link to={'/orders'}>
                    {(current === '/orders')
                        ? <IoCalendarClear />
                        : <IoCalendarClearOutline />
                    }
                    <p>{tx.Scheduled}</p>
                </Link >
                <Link to={'/menu'}>
                    {(current === '/menu')
                        ? <IoReorderFour />
                        : <IoReorderFourOutline />
                    }
                    <p>{tx.menu}</p>
                </Link >
            </div>
        </div>
    )
}


export default NavigationBar;