import { useState } from "react";
import CountrySelectorList from "./nested/CountrySelectorList";
import CountrySelectorMain from "./nested/CountrySelectorMain";
import { country_selector_list_ } from "../../languages/country_selector";

export default function CountrySelector({ isModal }: { isModal?: boolean }) {
  const [selected, setSelected] = useState<country_selector_list_>({} as country_selector_list_);

  return (
    <div>
      {
        (!selected.cod_iso)
          ? <CountrySelectorList
            setSelected={setSelected}
          />
          : <CountrySelectorMain
            selected={selected}
            setSelected={setSelected}
            isModal={isModal}
          />
      }
    </div>
  )
}
