import { IoCloseOutline } from 'react-icons/io5';
import Icon from '../IconMarginRight';
import SideBarContent from './nested/SideBarContent';
import './styles.css'

import { useState } from 'react';


interface IsideBar {
    setSideBarIsVisible: any,
    setLocationIsVisible: any,
    type?: 'default' | 'categories',
}

function SideBar({
    setSideBarIsVisible,
    setLocationIsVisible,
    type = 'default',
}: IsideBar) {



    const makeLocVisible = () => {
        setSideBarIsVisible(false)
        setLocationIsVisible(true)
    }

    return (
        <div className='SideBar-Container' >
            <div id="SideBar">
                <SideBarContent
                    Button={
                        <button className='SB-close-button' onClick={() => setSideBarIsVisible(false)}>
                            <Icon>
                                <IoCloseOutline />
                            </Icon>
                        </button>
                    }
                    makeLocVisible={makeLocVisible}
                    type={type}
                />
            </div>
            <div className='SB-fill-rest' onClick={() => setSideBarIsVisible(false)} />
        </div >
    )
}
export default SideBar