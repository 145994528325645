import { IoMenu } from 'react-icons/io5';
import './styles.css';

import ChatGoBack from '../ChatGoBack';
import Avatar from '../../../components/imagesComponents/Avatar';
import { useNavigate } from 'react-router-dom';

function ChatHeader({ uid, close }: { uid: string, close: any }) {

  const navigate = useNavigate();

  return (
    <div className='cheader-gradient-wrap'>
      <div className='cheader-gradient'>

        <div style={{ padding: '8px', display: 'flex' }}>
          <div
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              marginBottom: '10px'
            }}
          >
            {
              close
                ? <ChatGoBack
                  onClick={() => close(false)}
                  icon='close'
                />
                : <ChatGoBack
                  onClick={() => navigate(- 1)}
                  icon='back'
                />
            }

          </div>

          <div

          >
            <ChatHeaderAvatar uid={uid} />
          </div>
        </div>

        <div className='cheader-gradient-selects'>
          <div style={{ fontSize: '22px' }}>
            <strong>Mensagens</strong>
          </div>
          <div
            style={{
              height: '100%'
            }}
            className='center'
          >
            <span className='center'>
              <IoMenu size={24} />
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}


const ChatHeaderAvatar = ({ uid }: { uid: string }) => (
  <div
    style={{

      margin: '3px 0 3px 0',

      height: '40px',
      width: '40px',
      position: 'relative',
      border: '2px solid white',
      borderRadius: '100%'

    }}
  >
    <Avatar
      uid={uid}
      borderRadius='100px'
      size={14}
    />
  </div>
)
export default ChatHeader