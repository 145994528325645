const categoriesSliderStorage = [
    'https://firebasestorage.googleapis.com/v0/b/servicess-6e07b.firebasestorage.app/o/assets%2Fc_slider_pintor_200.jpg?alt=media&token=5a3aebc2-7846-4e1c-9ccb-763c78551d19',
    'https://firebasestorage.googleapis.com/v0/b/servicess-6e07b.firebasestorage.app/o/assets%2Fc_slider_jardineiro_200.jpg?alt=media&token=6bb34ea7-82f1-45fe-9835-fccbc960f423',
    'https://firebasestorage.googleapis.com/v0/b/servicess-6e07b.firebasestorage.app/o/assets%2Fc_slider_estetica_200.jpg?alt=media&token=cbfa20c7-73fd-46ed-a1cf-42fcb912b745',
    'https://firebasestorage.googleapis.com/v0/b/servicess-6e07b.firebasestorage.app/o/assets%2Fc_slider_frete_200.jpg?alt=media&token=02fa0e63-1b31-48d6-8cd3-420f576c2f2c',
    'https://firebasestorage.googleapis.com/v0/b/servicess-6e07b.firebasestorage.app/o/assets%2Fc_slider_ti_200.jpg?alt=media&token=6eb75467-4902-45af-8419-1bd235fe6e1e',
    'https://firebasestorage.googleapis.com/v0/b/servicess-6e07b.firebasestorage.app/o/assets%2Fc_slider_nutri_200.jpg?alt=media&token=eedf314d-3bd3-4042-b57b-8b95d4c8949f',
];


export default categoriesSliderStorage;