
import { useContext } from "react";

import GetLocation from "../../../GetLocation";
import CreateWorkContext from "../context";

const WcLocation = () => {

    const { locationData, setLocationData } = 
    useContext(CreateWorkContext).work || {};


    return (
        <div style={{ marginBottom: '30px' }}>
            <GetLocation
                setLocationData={setLocationData}
                city_name={locationData.city_name}
                state_name={locationData.state_name}
                required
            />
        </div>
    )
}

export default WcLocation