import translations_ from "../../../../interfaces/translations.interface";
import lang from "../../../../languages/lang";

type tx_ = [
    string, string, string, string, string,
    string, string, string, string, string,
    string
]

const def_tx: translations_<tx_> = {
    "pt-BR": [
        'Todos os serviços',
        'Diarista',
        'Engenheiro',
        'Estética',
        'Desenvolvedor',
        'Motoboy',
        'Dentista',
        'Consultoria',
        'Designer',
        'Pintor',
        'Faxineira'
    ],

    "en-US": [
        'All services',
        'Housekeeper',
        'Engineer',
        'Beauty',
        'Developer',
        'Motorcycle courier',
        'Dentist',
        'Consultancy',
        'Designer',
        'Painter',
        'Cleaning lady'
    ]
};

const tx = def_tx[lang];
export default tx;