const firebaseKeys = {
    apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: "servicess-6e07b.firebaseapp.com",
  projectId: "servicess-6e07b",
  storageBucket: "servicess-6e07b.firebasestorage.app",
  messagingSenderId: process.env.REACT_APP_FIREBASE_MSID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: "G-TSJN0KBZ3N"
   
}

export default firebaseKeys;
