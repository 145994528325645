import translations_ from "../../../interfaces/translations.interface";
import lang from "../../../languages/lang";

interface tx_ {
    manageData: {
        t1: string,
        t2: string,
        t3: string
    }, 
    list: {
        reports: {
            r1: string,
            r2: string
        },
        cant_crate_a:string,
        compl: string,
        question: string,
        technical_failure: string,
        need_help: string,
        feedback:string,
        suggest: string,
        manage_info: string
    },
    can_contact: string
}

const def_tx: translations_<tx_> = {
    "pt-BR": {
        manageData: {
            t1: 'Existe uma sessão específica para o gerenciamento de dados:',
            t2: 'Ir para o gerenciamento de dados',
            t3: 'Continuar na central de atendimento >'
        },
        list: {
            reports: {
                r1: 'Suspeita de golpe',
                r2: 'Denúncias'
            },
            cant_crate_a: "Não consigo criar minha conta",
            compl: "Reclamação",
            question: "Tirar dúvidas",
            technical_failure:"Relatar falha técnica",
            need_help: 'Preciso de ajuda',
            suggest: 'Sugerir melhoria',
            manage_info: '',
            feedback: 'Feedback'
        },
        can_contact: 'Se preferir você pode entrar em contato pelo e-mail de suporte:'
    },
    "en-US": {
        manageData: {
            t1: 'There is a specific session for data management:',
            t2: 'Go to data management',
            t3: 'Continue to the help center >'
        },
        list: {
            reports: {
                r1: 'Suspected scam',
                r2: 'Reports'
            },
            cant_crate_a: "I can't create my account",
            compl: "Complaint",
            question:"Question",
            technical_failure:"Report technical failure",
            need_help: 'I need help',
            suggest: 'Suggest improvement',
            manage_info: 'Manage my information',
            feedback: 'Feedback'
        },
        can_contact: 'If you prefer, you can contact us via support email:'
    }
};

const tx = def_tx[lang];
export default tx;