function CentralSelector({strong}:{strong:any}) {
  return (
    <div
        style={{
          backgroundColor: 'white',
          borderBottom: '1px solid var(--border)'
        }}
      >
        <div
          style={{
            padding: '14px 26px',
            borderBottom: '4px solid var(--green)',
            color: 'var(--green)',
            width: 'fit-content',
            fontSize: '18px',
            cursor: 'pointer',
          }}
        >
          <strong>
            {strong}
          </strong>
        </div>
      </div>
  )
}

export default CentralSelector