import { subwork_ } from "../DetailsContainer/interface";
import shake from '../../../languages/windowAlertForm';
import min from '../../../languages/minPrice';
import { locationData_ } from "../../GetLocation/interface";
import regex from "../../../functions/regex";
import { removeNotDigitsFromString } from "../../../functions/onlyDigits";
import tx from "./translation";

export const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
        e.preventDefault()
    }
}

export const pushY = (setSubworks: any) => (sw: subwork_) => {
    setSubworks((sws: any) => ([...sws, sw]));
}

export const delY = (setSubworks: any) => (swid: number) => {
    setSubworks(
        (sws: any) =>
            (sws.filter((sw: any) => (sw.id !== swid)))
    );
}

export const editY =
    (setSubworks: any) =>
        (swe: subwork_) => {
            setSubworks(
                (sws: any) => (sws.map((sw: any) =>
                    ((sw.id === swe.id) ? swe : sw)))
            );
        }

export const notAllowed = (
    alertfy: any, title: string,
    price: number, phone: any,
    locationData: locationData_, description: string
) => {
    if (!(
        title && price >= 50
        && phone && locationData &&
        locationData.city_id
    )) { alertfy(shake); return true }

    if (!regex.phone.test(removeNotDigitsFromString(phone))) {
        alertfy(tx.function[0]);
        return true
    }
    if (typeof title !== 'string') {
        alertfy(tx.function[1]);
        return true
    }
    if (title.trim().length < 3) {
        alertfy(tx.function[1]);
        return true
    };
    if (price < 50) {
        alertfy(min);
        return true
    };
    if (description && description.trim().length > 510) {
        alertfy(tx.function[2]);
        return true
    };

    return false;
}