
import '../../mobileComponents/NavigationBar/styles.css';
import './styles.css';
import '../../../css/card156.css';
import './styles.so.css';
import OrdersListEmpty from './nested/outlet/OrdersListEmpty';
import OrdersList from './nested/outlet/OrdersList';
import OrdersListProf from './nested/outlet/OrdersListProf';
import { useEffect, useState } from 'react';
import api from '../../../config/connection/axios';
import headers from '../../../constants/localStorage/headers';
import { SocList_ } from './interface';

function OrdersContainer() {
    const [listData, setListData] = useState<Array<SocList_[]>>([[], []]);
    const [switcher, setSwitcher] = useState<0 | 1 | 2 | 3>(0);

    useEffect(() => {
        const handleSubmit = async () => {

            api.get(
                '/orders/list',
                { headers: headers() }
            )
                .then(r => {
                    const result: Array<SocList_[]> = r.data;
                    setListData(result);

                    if (!(
                        result[0] && result[1] &&
                        result[0].length && result[1].length
                    )) setSwitcher(1)
                    else if (result[0] && result[0].length) setSwitcher(2)
                    else if (result[1] && result[1].length) setSwitcher(3);
                })
                .catch(() => setSwitcher(1))
        }

        handleSubmit();
    }, [])

    return (
        <>

            {switcher === 1 && <OrdersListEmpty />}
            {
                switcher === 2 &&
                <OrdersList
                    switcher={switcher}
                    setSwitcher={setSwitcher}
                    listData={listData[0]}
                />
            }
            {
                switcher === 3 &&
                <OrdersListProf
                    switcher={switcher}
                    setSwitcher={setSwitcher}
                    listData={listData[1]}
                />
            }

        </>

    )
}

export default OrdersContainer;