import { Link } from "react-router-dom"
import { getUser } from "../../../../../constants/localStorage/User"
import tx from "../../translation"

function OrdersListEmpty() {
    return (
        <div style={{

            backgroundColor: 'white',
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-evenly',
            alignItems: 'center'
        }}>
            <div style={{ paddingTop: '70px' }}>
                <img
                    style={{
                        width: '260px',
                        height: '260px'
                    }}
                    alt=''
                    src={
                        require('../../../../../assets/emptyorders.webp')
                    }
                />
            </div>
            <div className="orders-list-empty-w">
                <strong style={{ fontSize: '20px', opacity: .9, }}>
                    {tx.first}
                </strong>
                <p
                    style={{
                        opacity: '.7',
                        textAlign: 'justify'
                    }}
                >
                    {tx.here}
                </p>
                <p
                    style={{
                        opacity: '.7',
                        textAlign: 'justify'
                    }}
                >
                    {tx.here_too}
                </p>
                <Link to={'/search?search='}>
                    <div
                        className="center"
                        style={{
                            width: '100%',
                            height: '50px',
                            borderRadius: '9px',
                            backgroundColor: 'var(--green)',
                            color: 'white',
                            fontSize: '16px'

                        }}
                    >
                        <strong>
                            {tx.expl}
                        </strong>
                    </div>
                </Link>
                {
                    Boolean(getUser()) &&
                    <p style={{ opacity: '.7' }}>
                        {tx.no_acc.p}
                        <Link to={'/signin'}>
                            <strong>
                                {tx.no_acc.strong}
                            </strong>
                        </Link>
                    </p>
                }
            </div>
        </div>
    )
}

export default OrdersListEmpty;