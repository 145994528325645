import { Header_ } from "../headers.interfaces";
import HeaderWithoutChat from "../HeaderWithoutChat";

function Header({
    home = false,
    switcher,
    setSwitcher
}: Header_) {


    return (
        <>
            <HeaderWithoutChat
                home={home}
                switcher={switcher}
                setSwitcher={setSwitcher}
            />
        </>
    )
}

export default Header