import DetailsSubworksList from '../DetailsSubworksList';
import AsideProfDetails from '../square/AsideProfDetails';
import DListTitle from '../DListTitle';
import TopProfDetails from '../square/TopProfDetails copy';

function DetailsDefault() {
    return (
        <div
            id='DetailsContainer'
        >
            <TopProfDetails />
            <main id='details-main'>
                <div
                    className='card156-tmp-copy'
                    style={{ overflow: 'hidden' }}
                >
                    <ul
                        className='d-subworks-wrap'
                    >
                        <div className='center'>
                            <div className='DListTitleWrap'>
                                <DListTitle />
                            </div>
                        </div>
                        <DetailsSubworksList />
                    </ul>
                </div>
            </main>
            <AsideProfDetails />
        </div>
    )
}

export default DetailsDefault;