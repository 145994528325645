import translations_ from "../../interfaces/translations.interface";
import lang from "../../languages/lang";

type tx_ = [
    string, string
]

const def_tx: translations_<tx_> = {
    "pt-BR": [
        'Digite o código que enviamos por e-mail',
        'Enviamos um código no seu e-mail para que possa acessar sua conta. Caso não o encontre, verifique a caixa de spam.'
    ],
    "en-US": [
        'Enter the code we sent you by email',
        'We sent you a code to your email so you can access your account. If you can\'t find it, check your spam folder.'
    ]
};

const tx = def_tx[lang];
export default tx;