import { Link } from 'react-router-dom'
import ShLi from '../ShLi'
import './styles.css';
import tx from './translation';
function CatList() {
    return (

        <ul className='SB-list-scroll'>
            {
                tx.map((cat: string, idx: number) => (
                    <Link to={`/search?search=${cat === tx[0] ? '' : cat}`} key={idx}>
                        <ShLi text={cat} />
                    </Link>
                ))
            }
        </ul>

    )
}

export default CatList