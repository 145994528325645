import { country_selector_list_ } from '../../../languages/country_selector';
import '../styles.css';
import tx from '../translation';
import '../../../components/pageContent/OrdersContainer/styles.css'
import { IoArrowForward, IoLocationOutline } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';

interface CountrySelectorMain_ {
    selected: country_selector_list_,
    setSelected: (c: country_selector_list_) => void,
    isModal?: boolean
}

export default function CountrySelectorMain({ selected, setSelected, isModal }: CountrySelectorMain_) {


    const handleConfirmCountry = () => {

        let newHostname;
        if (selected.cod_int === 'br') {
            newHostname = 'servicess.com.br';
        } else if (selected.cod_int === 'br') {
            newHostname = `${selected.cod_int}.servicess.app`;
        }
        else {
            newHostname = `servicess.app`;
        }

        const newUrl = `${window.location.protocol}//${newHostname}/`;

        window.localStorage.setItem('serss-country', selected.cod_int);
        return window.location.replace(newUrl);
    }

    return (
        <div style={{
            backgroundColor: 'white',
            height: !isModal ? '100vh' : '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-evenly',
            alignItems: 'center'
        }}>
            <div style={{ paddingTop: !isModal ? '0px' : '50px' }}>
                <span
                    className='center'
                    style={{
                        width: !isModal ? '260px' : '130px',
                        height: !isModal ? '260px' : '130px'
                    }}

                >
                    <IoLocationOutline
                        size={180}
                    />
                </span>
            </div>
            <div className="orders-list-empty-w">
                <strong
                    style={{
                        fontSize: '20px',
                        opacity: .9,
                    }}
                >
                    {tx[0]}
                </strong>

                <p
                    style={{
                        opacity: '.7',
                        textAlign: 'center'
                    }}
                >
                    {tx[2]}
                </p>
                <button
                    onClick={() => setSelected({} as country_selector_list_)}
                    style={{
                        width: '100%',
                        height: '50px',
                        borderRadius: '9px',
                        border: '1px solid var(--dark)',
                        color: 'var(--dark)',
                        fontSize: '16px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        margin: '20px 0 60px 0'

                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <img
                            style={{
                                width: '30px',
                                borderRadius: '2px',
                                margin: '0 20px',

                            }}
                            alt='National Flag'
                            src={
                                require(
                                    '../../../assets/flags/'
                                    + selected.cod_int
                                    + '.webp'
                                )
                            }
                        />
                        <strong>
                            {selected.reg}
                        </strong>
                    </div>
                    <span
                        className='center'
                        style={{
                            margin: '0 20px',
                        }}
                    >
                        <IoArrowForward />
                    </span>
                </button>
                <button
                    onClick={handleConfirmCountry}
                    className="center"
                    style={{
                        width: '100%',
                        height: '50px',
                        borderRadius: '9px',
                        backgroundColor: 'var(--dark)',
                        color: 'white',
                        fontSize: '16px'
                    }}
                >
                    <strong>
                        {tx[3]}
                    </strong>
                </button>
            </div>
        </div>
    )
}
