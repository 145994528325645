function WcGreenBack() {
    return (
        <div
            style={{
                width: '100%',
                height: '380px',
                backgroundColor: '#00ae74',
                position: 'absolute',
                zIndex: 0

            }}
        />
    )
}

export default WcGreenBack