import { IoChatbubble, IoChevronForwardOutline, IoMailOpenOutline } from "react-icons/io5"
import tx from "../translation"

function ContactUsOnError({ haveError }: { haveError?: string | boolean }) {

    return (
        <div style={{ maxWidth: '84vw', width: '480px', textAlign: 'center' }}>
            <strong>{tx.error.t}</strong>
            <p>{tx.error.p}</p>
            <div
                style={{
                    marginTop: '40px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%'
                }}
            >
                <ContactUsOnErrorButton
                    text="Central"
                    href='/central'
                />
                <ContactUsOnErrorButton
                    text="E-mail"
                    href='mailto:suporte@servicess.com.br'
                />
            </div>
        </div>
    )
}
const ContactUsOnErrorButton = ({ text, href }: { text: 'Central' | 'E-mail', href: string }) => (
    <a
        href={href}
        style={{
            backgroundColor: 'transparent',
            color: '#222D36',
            border: '1px solid #222D36',
            borderRadius: '6px',
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
            width: '44%'
        }}
    >
        {text === 'Central' && <IoChatbubble size={18} />}
        {text === 'E-mail' && <IoMailOpenOutline size={18} />}
        <p>{text}</p>
        <IoChevronForwardOutline />
    </a>
)
export default ContactUsOnError