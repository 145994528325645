/*
  * Components
*/
import DetailsContainer from "../components/pageContent/DetailsContainer";
import HeaderWithoutChat from "../components/Headers/HeaderWithoutChat";
import Screen from "../components/ScreenComponent";
import Footer from "../components/Footer";

function Details() {
  window.scrollTo(0, 0);

  return (
    <Screen>
      <HeaderWithoutChat />
      <DetailsContainer />
      {window.innerWidth > 960 && <Footer />}
    </Screen>
  )
}

export default Details