import { Link } from "react-router-dom"
import tx from "./translation";

interface To {
    to: string
}
function GetAnAccount({ to }: To) {
    return (
        <Link to={to} style={styles.align}>
            {to === '/signin'
                ? <><p>{tx.login.t}</p><span style={styles.span}>{tx.login.p}</span></>
                : <><p>{tx.signin.t}</p><span style={styles.span}>{tx.signin.p}</span></>
            }
        </Link>
    )
}
const styles = {
    align: {
        fontSize: '12px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#222d36',
        border: '1px solid #c4c4c4', 
        padding: '4px 36px', 
        borderRadius: '12px',
        boxShadow: '-4px 8px 8px #222d3610'
    },

    span: {
        marginLeft: '6px',
        fontWeight: 'bold',
        textDecoration: 'underline',
    }
}

export default GetAnAccount;