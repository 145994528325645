import { removeNotDigitsFromString } from "./onlyDigits";
import regex from "./regex";
import setLocalStoragePhone from "./setlocalStoragePhone";

/*
    * __TMP__
    * Substitui temporariamente a resposta do middleware updatePhone
    *  com o X-updated-phone para validar se o telefone foi alterado ou não
    * Pq não tava conseguindo pegar o "response.headers" com o Axios.
*/
const X_updated_phone_TMP = (phone: string) => {
    if (regex.phone.test(removeNotDigitsFromString(phone)))
        setLocalStoragePhone(phone);
    return;
}

export default X_updated_phone_TMP;
