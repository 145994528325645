import translations_ from "../interfaces/translations.interface";
import lang from "./lang";

type tx_ = string;

const help_center: translations_<tx_> = {
    "pt-BR": "Central de atendimento",
    "en-US": "Help center"
};

export default help_center[lang];