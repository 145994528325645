import { useContext, useState } from "react"
import { IoAlertCircleOutline } from "react-icons/io5"
import { useNavigate } from "react-router-dom"
import api from "../../../../config/connection/axios"
import TrashButton from "../../../Buttons/TrashButton"
import Input from "../../../Form/Inputs/nested/Input"
import WindowAlertContext from "../../../../context/windowAlert.context"
import headers from "../../../../constants/localStorage/headers"
import tx from "../translation"


function RegDelete() {
    const [password, setPassword] = useState<string>('');

    const navigate = useNavigate();
    const windowAlert = useContext(WindowAlertContext);

    const handleAccountDelete = () => {
        const u =
            JSON.parse(
                window.localStorage.getItem('serss-user') || '{}'
            )

        if (password.length < 6)
            return windowAlert?.confirm({
                shake: tx.r_del.w_a
            });

        api.post(
            '/users/delete',
            {
                password
            },
            { headers: headers() }
        )
            .then(() => {
                var formData = new FormData();
                api.post(
                    '/firebase/delete/all',
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': `Bearer ${u.token}`
                        }
                    }
                );
                window.localStorage.removeItem('serss-user');
                navigate('/');
            })
            .catch((err) =>
                windowAlert?.confirm({
                    shake: tx.r_del.w_a2
                })
            )

    }

    return (
        <form>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center'
                }}
            >
                <span
                    className="center"
                    style={{
                        color: 'tomato',
                        fontSize: '30px'
                    }}
                >
                    <IoAlertCircleOutline />
                </span>
                <p
                    className="reg-avatar-pick-image"
                    style={{ margin: '0 0 0 10px' }}>
                    {tx.r_del.rev_acc}
                </p>
            </div>
            <div
                style={{
                    marginTop: '-40px',
                    display: 'flex',
                    alignItems: 'flex-end'
                }}
            >
                <Input
                    placeholder={tx.r_del.get_pass}
                    space={0}
                    type='password'
                    value={password}
                    onChange={setPassword}
                />

                <TrashButton funk={(e) => {
                    e.preventDefault();
                    windowAlert?.confirm(
                        {
                            strong: tx.r_del.w_a3.strong,
                            p: tx.r_del.w_a3.p
                        },
                        handleAccountDelete
                    )
                }} />
            </div>
        </form>
    )
}

export default RegDelete