import { useState } from "react";
import Spinner from "../../../Spinner";
import { FormButton_ } from "../inputs.interfaces";
import Alert from "./Alert";


import '../styles.css';

export default function FormButton({ text, space, onClick, spinner, untouchable = false, style, spinnerColor }: FormButton_) {
    const [show, setShow] = useState(false);
    const [alertText, setAlertText] = useState<string>('');

    var time: any = null;

    const alertfy = (text: string) => {
        time && clearTimeout(time);
        setAlertText(text)
        setShow(true);
        time = setTimeout(() => {
            setShow(false)
        }, 2000)
    }
    const handleOnClick = (e: any) => {
        onClick(e, alertfy)
    }
    return (
        <button
            onClick={handleOnClick}
            type='submit'
            className={`I FormButton ${untouchable && 'untouchable'} ${spinner && 'noPointer'}`}
            style={{
                marginTop: `${space !== undefined ? space : 26}px`,
                ...style
            }}
        >
            <Spinner
                color={spinnerColor ? spinnerColor : ''}
                visible={spinner as boolean}
            >
                {text}
            </Spinner>

            {show && <Alert text={alertText} />}
        </button>
    );
}

