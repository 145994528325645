import translations_ from "../../interfaces/translations.interface";
import lang from "../../languages/lang";

interface tx_ {
    assistance: string,
    error: {
        t: string,
        p: string
    }
}

const def_tx: translations_<tx_> = {
    "pt-BR": {
        assistance: 'Assistência',
        error: {
            t: 'Ocorreu um erro inesperado',
            p: 'Por favor entre em contato com nossa equipe, iremos te ajudar o mais rápido possível.'
        }
    },
    "en-US": {
        assistance: 'Assistance',
        error: {
            t: '',
            p: ''
        }
    }
};

const tx = def_tx[lang];
export default tx;