import './styles.css';
import CentrlMenu_ from '../../interfaces/centralMenu.interface';
import CMenuLi from './nested/CMenuLi';
import tx from './tx/translations';

function CentralMenu({ setCentralMenu, uid }: { uid?: string, setCentralMenu: (s: CentrlMenu_) => void }) {
    return (
        <div className='CentralMenu'>
            <ul >
                <CMenuLi
                    setCentralMenu={setCentralMenu}
                    screen={3}
                    code='fraud'
                    bigger

                >
                    <div style={{ display: 'flex' }}>
                        <div className='center'>
                            🚨
                        </div>
                        <div>
                            <div>
                                {tx.list.reports.r1}
                            </div>
                            <br />
                            <div>
                                {tx.list.reports.r2}
                            </div>
                        </div>
                    </div>
                </CMenuLi>
                <Aa />
                <CMenuLi
                    setCentralMenu={setCentralMenu}
                    screen={1}
                    code='create_account'

                >
                    {tx.list.cant_crate_a}
                </CMenuLi>
                <CMenuLi
                    setCentralMenu={setCentralMenu}
                    screen={1}
                    code='complaint'

                >
                    {tx.list.compl}
                </CMenuLi>
                <CMenuLi
                    setCentralMenu={setCentralMenu}
                    screen={1}
                    code='questions'

                >
                    {tx.list.question}
                </CMenuLi>
                <CMenuLi
                    setCentralMenu={setCentralMenu}
                    screen={1}
                    code='error'

                >
                    {tx.list.technical_failure}
                </CMenuLi>
                <Aa />
                <CMenuLi
                    setCentralMenu={setCentralMenu}
                    screen={1}
                    code='help'

                >
                     {tx.list.need_help}
                </CMenuLi>
                <Aa />
                <CMenuLi
                    setCentralMenu={setCentralMenu}
                    screen={1}
                    code='feedback'

                >
                     {tx.list.feedback}
                </CMenuLi>
                <CMenuLi
                    setCentralMenu={setCentralMenu}
                    screen={1}
                    code='feedback'

                >
                    {tx.list.suggest}
                </CMenuLi>
                {
                    uid &&
                    <>
                        <Aa />
                        <CMenuLi
                            setCentralMenu={setCentralMenu}
                            screen={2}
                            code='manage_data'

                        >
                            {tx.list.manage_info}
                        </CMenuLi>
                    </>
                }
                <Aa />
                <div className='center column' style={{ color: '#696969', textAlign: 'center', width: '90%', marginBottom: '80px' }}>
                    <p> {tx.can_contact}</p>
                    <strong>suporte@servicess.com.br</strong>
                </div>
            </ul>
        </div>
    )
}

const Aa = () => (
    <div style={{ width: '70%', paddingTop: '20px', marginBottom: '50px', borderBottom: '1px solid var(--border)' }} />
)


export default CentralMenu