import translations_ from "../interfaces/translations.interface";
import lang from "./lang";



type tx_ = string

const def_tx: translations_<tx_> = {
    "pt-BR": "Preencha todos os capos obrigatórios(*)",
    "en-US": "Fill in all required fields(*)"
};

const tx = def_tx[lang];
export default tx;