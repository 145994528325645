import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import SearchContainer from '../components/pageContent/SearchContainer';
import Header from '../components/Headers/Header';
import NavigationBar from '../components/mobileComponents/NavigationBar';
import Pagination from '../components/Pagination';
import tx from './translation';

function Search() {
  window.scrollTo(0, 0);

  console.log(window.location.hostname)

  const [searchParams] = useSearchParams();
  const search = searchParams.get('search');

  const [switcher, setSwitcher] = useState<boolean>(false);

  const [seed, setSeed] = useState(1);

  useEffect(() => {
    const reset = () => {
      setSeed(Math.random());
    }
    reset()
  }, [
    window.localStorage.getItem('serss-location'),
    switcher,
    search
  ])

  return (
    <Pagination
      title={
        search
          ? '"' + search?.toString().charAt(0).toUpperCase() +
          search?.toString().slice(1) + '"' +
          tx.search[0]
          : tx.search[1]
      }
    >
      <>
        <Header
          switcher={switcher}
          setSwitcher={setSwitcher}
        />
        <SearchContainer
          key={seed}
          search={search || ''}
          switcher={switcher}
          setSwitcher={setSwitcher}
        />
        <NavigationBar />
      </>
    </Pagination>
  )
}

export default Search