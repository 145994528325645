import translations_ from "../../../interfaces/translations.interface";
import lang from "../../../languages/lang";

type tx_ = [string, string, string, string, string, string]

const def_tx: translations_<tx_> = {
    "pt-BR": ['Pintor', 'Jardineiro', 'Estética', 'Frete', 'Técnico', 'Nutricionista'],
    "en-US": ['Painter', 'Gardener', 'Massage Therapy', 'Transportation', 'Technology', 'Nutritionist']
};

const tx = def_tx[lang];
export default tx;