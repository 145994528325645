import translations_ from "../../../interfaces/translations.interface";
import lang from "../../../languages/lang";

interface tx_ {
    my: string,
    desc: string,
    now: string,
    exc: {
        e1: string,
        e2: string
    }
}
const def_tx: translations_<tx_> = {
    "pt-BR": {
        my: 'Meus anúncios',
        desc: 'Você ainda não possue anúncios ativos',
        now: 'Anuncie agora',
        exc: {
            e1: 'Confirmar Exclusão',
            e2: 'Excluir'
        }
    },
    "en-US": {
        my: 'My ads',
        desc: 'You do not have any active ads yet.',
        now: 'Advertise now',
        exc: {
            e1: 'Confirm Deletion',
            e2: 'Delete'
        }
    }
};

const tx = def_tx[lang];
export default tx;