import translations_ from "../../../interfaces/translations.interface";
import lang from "../../../languages/lang";

type tx_ = [
    string, string, string, string, string,
    string, string, string, string, string,
    string, string, string
]

const def_tx: translations_<tx_> = {
    "pt-BR": [
        'Localização',
        'Nenhum resultado encontrado',
        'para',
        'Anunciar meu serviço',
        'Seja você o primeiro',
        'da sua cidade!',
        'Mais opções',
        'Confira mais opções',
        'para',
        'Limpar filtros',
        'Categorias',
        'Localização',
        'publicidade'
    ],
    "en-US": [
        'Location',
        'No results found',
        'for',
        'Advertise my service',
        'Be the first',
        'in your city!',
        'More options',
        'Check out more options',
        'for',
        'Clear filters',
        'Categories',
        'Location',
        'advertising'
    ]
};

const tx = def_tx[lang];
export default tx;