import { useNavigate } from "react-router-dom"
import CMenuLi from "./CMenuLi"
import tx from "../tx/translations";

function CMenuManageData({ setCentralMenu }: { setCentralMenu: any }) {

    const navigate = useNavigate();

    const handleGoToManageData =()=>{
        navigate('/registration#1')
    }

    return (
        <div
            style={{ backgroundColor: 'white', height: 'calc(100vh - 140px)' }}
            className="center column"
        >
            <div style={{ width: '80%', marginBottom: '30px', fontSize: '18px', color: '#696969' }}>
                <strong >
                    {tx.manageData.t1}
                </strong>
            </div>
            <CMenuLi
                setCentralMenu={handleGoToManageData}
                screen={2}
                code='manage_data'

            >
                {tx.manageData.t2}
            </CMenuLi>

            <button
                onClick={() => setCentralMenu({screen:1, code:'manage_data'})}
                style={{ fontWeight: 'bold', color: 'var(--green)', fontSize: '16px' }}
            >
                {tx.manageData.t3}
            </button>
        </div>
    )
}

export default CMenuManageData;