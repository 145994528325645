import translations_ from "../../../interfaces/translations.interface";
import lang from "../../../languages/lang";
interface i_ {
    label: string,
    placeholder: string
}
interface tx_ {
    client: string,
    inputs: [
        i_, i_
    ],
    from_btn: string,
    acc: {
        t: string,
        input: string,
        btn: string
    }
}
const def_tx: translations_<tx_> = {
    "pt-BR": {
        client: 'Já sou cliente',
        inputs: [
            {
                label: 'E-mail',
                placeholder: 'exemplo@email.com'
            },
            {
                label: 'Senha',
                placeholder: '••••••••'
            },
        ],
        from_btn: 'Entrar',
        acc: {
            t: 'Criar conta',
            input: 'Informe seu e-mail',
            btn: 'Prosseguir'
        }

    },
    "en-US": {
        client: 'I\'m already a customer',
        inputs: [
            {
                label: 'E-mail',
                placeholder: 'exemplo@email.com'
            },
            {
                label: 'Password',
                placeholder: '••••••••'
            }
        ],
        from_btn: 'Log in',
        acc: {
            t: 'Create account',
            input: 'Enter your email',
            btn: 'Continue'
        }
    }
};

const tx = def_tx[lang];
export default tx;