import translations_ from "../../../../interfaces/translations.interface";
import lang from "../../../../languages/lang";

interface tx_ {
    t1: string
}

const def_tx: translations_<tx_> = {
    "pt-BR": {t1: 'Comunidade'},
    "en-US": {t1: 'Community'}
};

const tx = def_tx[lang];
export default tx;