import OrdersContainer from "../components/pageContent/OrdersContainer"
import ThinHeader from "../components/Headers/ThinHeader"
import NavigationBar from "../components/mobileComponents/NavigationBar";

function Orders() {
    return (
        <div
            style={{
                background: 'linear-gradient(to bottom, white, transparent)'
            }}
        >
            <ThinHeader />
            <OrdersContainer />
            <NavigationBar />
        </div>
    )
}

export default Orders;