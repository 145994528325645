import GoogleAd from '../GoogleAd';

function AdsSearchSquareTwoMobile() {
    return (
        <div
            style={{
                width: '100%',
                height: '100%',
            }}
        >
            <GoogleAd googleAdId="ca-pub-1196789424751315" slot='5972554048' />
        </div>
    )
}

export default AdsSearchSquareTwoMobile;