import translations_ from "../../../interfaces/translations.interface";
import lang from "../../../languages/lang";
interface i_ {
    name: string,
    search: string
}
type tx_ = [i_, i_, i_]

const def_tx: translations_<tx_> = {
    "pt-BR": [
        {
            name: "Eletricista",
            search: "eletri"
        },
        {
            name: "Designer",
            search: "design"
        },
        {
            name: "Diaristas",
            search: "faxineira diarista"
        }
    ],
    "en-US": [
        {
            name: "Electrician",
            search: "electrician"
        },
        {
            name: "Designer",
            search: "design"
        },
        {
            name: "Cleaning",
            search: "cleaning"
        }
    ]
};

const tx = def_tx[lang];
export default tx;