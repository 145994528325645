import translations_ from "../../../../interfaces/translations.interface";
import lang from "../../../../languages/lang";

type tx_ = string;

const def_tx: translations_<tx_> = {
    "pt-BR": 'Dúvidas? entre em contato',
    "en-US": 'Doubts? get in touch'
};

const tx = def_tx[lang];
export default tx;