import translations_ from "../../interfaces/translations.interface";
import lang from "../../languages/lang";

interface tx_ {
    suspicious_contact: string,
    protect_yourself: string,
    button_above: string,
    rights: string,
    terms: string,
    pp: string
}

const def_tx: translations_<tx_> = {
    "pt-BR": {
        suspicious_contact: 'Recebeu um contato duvidoso?',
        protect_yourself: 'Proteja-se aqui ➜',
        button_above: 'clique no botão acima ↑',
        rights: 'Todos os direitos reservados',
        terms: 'termos e condições de uso',
        pp: 'política de privacidade'
    },
    "en-US": {
        suspicious_contact: 'Have you received a suspicious contact?',
        protect_yourself: 'Protect yourself here ➜',
        button_above: 'click the button above ↑',
        rights: 'All rights reserved',
        terms: 'terms and conditions of use',
        pp: 'privacy policy'
    }
};

const tx = def_tx[lang];
export default tx;