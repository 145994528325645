import translations_ from "../../../interfaces/translations.interface";
import lang from "../../../languages/lang";

interface i_ {
    text: string,
    to: string
}
type tx_ = [i_, i_, i_, i_, i_, i_, { text: string }]

const def_tx: translations_<tx_> = {
    "pt-BR": [
        {
            text: 'Eletricista',
            to: 'eletricista',
        },
        {
            text: 'Pintura',
            to: 'pintor'
        },
        {
            text: 'Chaveiro',
            to: 'chaveiro'
        },
        {
            text: 'Tecnologia',
            to: 'tecnico'
        },
        {
            text: 'Massagem',
            to: 'Terap'
        },
        {
            text: 'Design',
            to: 'design'
        },
        {
            text: 'Explorar'
        }
    ],
    "en-US": [
        {
            text: 'Electrician',
            to: 'electrician',
        },
        {
            text: 'Painter',
            to: 'painter'
        },
        {
            text: 'Locksmith',
            to: 'locksmith'
        },
        {
            text: 'Technology',
            to: 'technology'
        },
        {
            text: 'Massage',
            to: 'massage'
        },
        {
            text: 'Design',
            to: 'design'
        },
        {
            text: 'Explore'
        }
    ]
};

const tx = def_tx[lang];
export default tx;