import translations_ from "../../../interfaces/translations.interface";
import lang from "../../../languages/lang";

type tx_ = {
    h2: string,
    span: string
}

const def_tx: translations_<tx_> = {
    "pt-BR": {
        h2: 'Baixe Nosso App',
        span: 'Os melhores profissionais na palma da mão!'
    },
    "en-US": {
        h2: 'Download Our App',
        span: 'The best professionals in the palm of your hand!'
    }
};

const tx = def_tx[lang];
export default tx;