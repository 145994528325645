import { Link } from 'react-router-dom'
import './styles.css'

function MiniBanners() {
    return (
        <div id='MiniBanners'>
            <Link
                to={window.localStorage.getItem('serss-user')
                    ? '/work/create'
                    : '/login'}
            >
                <img
                    src={require('../../assets/moneyupf.webp')}
                    alt=''
                />
            </Link>
            <Link
                to={
                    window.localStorage.getItem('serss-user')
                        ? '/'
                        : '/signin'
                }
                target='_blank'
            >
                <img
                    style={{ marginLeft: '21px' }}
                    src={require('../../assets/workerf.webp')} 
                    alt=''
                />
            </Link>
        </div>
    )
}

export default MiniBanners