
import './styles.css'
import { Link } from 'react-router-dom';
import popularProfessionalsStorage from '../../../constants/firebase/popularProfessionalsStorage';
import tx from './translation';

function PopularProfessionals({ children }: any) {

    const iconsList = [
        {
            url: popularProfessionalsStorage[0],
            text: tx[0].text,
            to: `/search?search=${tx[0].to}`
        },
        {
            url: popularProfessionalsStorage[1],
            text: tx[1].text,
            to: `/search?search=${tx[1].to}`
        },
        {
            url: popularProfessionalsStorage[2],
            text: tx[2].text,
            to: `/search?search=${tx[2].to}`
        },
        {
            url: popularProfessionalsStorage[3],
            text: tx[3].text,
            to: `/search?search=${tx[3].to}`
        },
        {
            url: popularProfessionalsStorage[4],
            text: tx[4].text,
            to: `/search?search=${tx[4].to}`
        },
    ]

    return (
        <div className='Hide-popC'>
            {children}
            <div id='PopularProfessionals'>
                <ul>
                    {iconsList.map((prof, idx) => (
                        <Link to={prof.to} key={idx}>
                            <li
                                className='center column'
                                style={{ backgroundImage: `url(${prof.url})` }}
                            >
                                <span className='PopProf-gradient' />
                                <div className='PopProf-absolute-bottom center'>
                                    <div>
                                        <strong>{prof.text}</strong>
                                    </div>
                                </div>
                            </li>
                        </Link>
                    ))}
                </ul>
            </div>
        </div>
    )
}

export default PopularProfessionals