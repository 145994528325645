const iosCameraPermission = {
    camera(): boolean {
        if (
            navigator.userAgent.match(/expo ios/i) &&
            window.localStorage.getItem('camera_permission') !== 'granted'
        ) return false
        else return true;
    }
}

export default iosCameraPermission;