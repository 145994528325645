import translations_ from "../../../../../interfaces/translations.interface";
import lang from "../../../../../languages/lang";


type tx_ = {
    when: string
}

const def_tx: translations_<tx_> = {
    "pt-BR": {
        when: 'Para quando você precisa?'
    },
    "en-US": {

        when: 'When do you need it?'
    }
};

const tx = def_tx[lang];
export default tx;