import translations_ from "../../../interfaces/translations.interface";
import lang from "../../../languages/lang";

interface i_ {
    text: string,
    query: string
}
type tx_ = [i_, i_, i_, i_, i_, i_]

const def_tx: translations_<tx_> = {
    "pt-BR": [
        {
            text: 'Faxina',
            query: 'faxina diarista limpeza',            
        },
        {
            text: 'Barbeiro',
            query: 'barbeir',            
        },
        {
            text: 'Desenvolvedor',
            query: 'Desenvolv',            
        },
        {
            text: 'Para máquinas',
            query: 'maquina',            
        },
        {
            text: 'Limpeza de estofados',
            query: 'higien',            
        },
        {
            text: 'Contador',
            query: 'contad contabilidade',            
        }
    ],
    "en-US": [
        {
            text: 'Cleaning',
            query: 'cleaning',            
        },
        {
            text: 'Barber',
            query: 'barber',            
        },
        {
            text: 'Developer',
            query: 'developer',            
        },
        {
            text: 'For machines',
            query: 'machines',            
        },
        {
            text: 'Upholstery cleaning',
            query: 'upholstery cleaning',            
        },
        {
            text: 'Accounting',
            query: 'accounting',            
        }
    ]
};

const tx = def_tx[lang];
export default tx;