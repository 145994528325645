import { storage } from '../config/firebase/firebaseConfig';
import { ref, getDownloadURL, listAll } from "firebase/storage";

export const getFire = async (firePath: string, setFire: (src: string) => void) => {
    const storageRef = ref(storage, firePath);
    return await getDownloadURL(storageRef)
        .then(url => {
            url && setFire(url)
            return;
        })
}

export const getFireList = async (firePath:string, setFireList: any) => {
    const storageRef = ref(storage, firePath);
    const list = await listAll(storageRef);
    const urlsPromises = list.items.map(async (li) => await getDownloadURL(li));
    const urls = await Promise.all(urlsPromises); // Resolvendo todas as Promises
    console.log(urls);
    setFireList(urls);
    return urls;
  };

export const getFireSingle = async (firePath: string, setFireSingle: any) => {
    const storageRef = ref(storage, firePath);
    return await listAll(storageRef)
        .then(async list => {
            if (list.items[0]) await getDownloadURL(list.items[0])
                .then(url => {
                    setFireSingle(url)
                })
        })
}
