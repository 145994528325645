interface ChatBubble_ {
    message: string | JSX.Element,
    author: string,
    uid: string,

    children?: JSX.Element,
    button?: JSX.Element
}

const ChatBubble = ({
    message,
    author,
    uid,

    children,
    button
}: ChatBubble_) => {
    return (
        <li className={`chat-bubble chat-${author === uid ? 'author' : 'receiver'}-bubble`}>
            <span className='center column'>
                {children}
                {typeof message == 'string'
                    ? <p>{message}</p>
                    : message
                }
                {button}
            </span>
        </li>
    )
}

export default ChatBubble;