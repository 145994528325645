import CategoriesSlider from '../../homePage/CategoriesSlider'
import Stripe from '../../homePage/Stripe'
import MiniBanners from '../../MiniBanners'
import SafeDiv from '../../SafeDiv'
import ProfessionsContainer from '../ProfessionsContainer'
import './styles.css'

import AboutUs from '../../rlx-components/AboutUs'
import HomeWorks from './nested/HomeWorks'
import CardsCase from '../../Cards/CardsCase'
import PopularCategories from '../../homePage/PopularCategories'
import PopularProfessionals from '../../homePage/PopularProfessionals'
import FindItOut from '../../homePage/FindItOut'
import DownApp from '../../homePage/DownApp'
import tx from './translation'
//import CountrySelectorSwitcher from '../../CountrySelector/nested/CountrySelectorSwitcher'
//import { haveCountry } from '../../../constants/localStorage/getCountry'

function HomeContainer() {
    return (
        <SafeDiv center={true}>
            {/*!haveCountry() && <CountrySelectorSwitcher />*/}
            <div id='HomeContainer'>
                <ProfessionsContainer />
                <HomeTitle
                    className='mrg-0'
                    title={tx[0].title}
                    subtitle={tx[0].subtitle}
                />
                <CategoriesSlider />

                <PopularProfessionals>
                    <HomeTitle
                        title={tx[1].title}
                        subtitle={tx[1].subtitle}
                    />
                </PopularProfessionals>


                <HomeTitle
                    title={tx[2].title}
                    subtitle={tx[2].subtitle}

                />

                {
                    window.innerWidth > 960
                        ? <HomeWorks box={2} />
                        : <CardsCase
                            text={tx[3]}
                            box={2}
                        />
                }

                <HomeTitle
                    title={tx[4].title}
                    subtitle={tx[4].subtitle}

                />
                <MiniBanners />
                <Stripe />


                <HomeTitle
                    title={tx[5].title}
                    subtitle={tx[5].subtitle}

                />
                {
                    window.innerWidth > 960
                        ? <HomeWorks box={5} />
                        : <CardsCase
                            text={tx[6]}
                            box={1}
                            content={4}
                        />
                }

                <HomeTitle
                    title={tx[7].title}
                    subtitle={tx[7].subtitle}

                />
                <FindItOut />

                <HomeTitle
                    title={tx[8].title}
                    subtitle={tx[8].subtitle}

                />
                {
                    window.innerWidth > 960
                        ? <HomeWorks box={1} />
                        : <CardsCase
                            text={tx[9]}
                        />
                }

                <PopularCategories>
                    <HomeTitle
                        title={tx[10].title}
                        subtitle={tx[10].subtitle}

                    />
                </PopularCategories>

                <HomeTitle
                    title={tx[11].title}
                    subtitle={tx[11].subtitle}

                />
                {
                    window.innerWidth > 960
                        ? <HomeWorks box={3} />
                        : <CardsCase
                            text={tx[12]}
                            box={2}
                            content={2}
                        />
                }

                <HomeTitle
                    title={tx[11].title}
                    subtitle={tx[11].subtitle}

                />
                {
                    window.innerWidth > 960
                        ? <HomeWorks box={4} />
                        : <CardsCase
                            text={tx[12]}
                            box={1}
                            content={3}
                        />
                }
                {
                    !navigator.userAgent.match(/expo/i) &&
                    <DownApp />
                }
                <AboutUs />
            </div>
        </SafeDiv>
    )
}
interface Hometitle {
    title: string,
    subtitle?: string
    className?: string
}
const HomeTitle = ({ title, subtitle, className }: Hometitle) => {
    return (
        <div
            className={`${className} HomeTitle`}
        >
            <h2>
                {title}
            </h2>
            <p>{subtitle}</p>
        </div>
    );
}
export default HomeContainer;