import { useState } from "react";
import { WindowAlert } from "../WindowAlert";
import { waMethods_, waParams_, waState_ } from "../../interfaces/windowAlert.interface";
import WindowAlertContext from "../../context/windowAlert.context";

interface Screen_ {
    children: React.ReactNode
}

function Screen({ children }: Screen_) {
    const [windowAlertState, setWindowAlertState] = 
    useState<waState_>({ visible: false });

    const windowAlert: waMethods_ = {
        confirm(
            data: waParams_['data'],
            conf?: waParams_['confirm']
        ) {
            setWindowAlertState({
                visible: true,
                ...data,
                confirm: conf
            })
        }
    }

    return (
        <WindowAlertContext.Provider value={windowAlert}>
            {
                windowAlertState.visible &&
                <WindowAlert
                    alertOnly={windowAlertState.alertOnly}
                    strong={windowAlertState.strong}
                    confirm={windowAlertState.confirm}
                    p={windowAlertState.p}
                    shake={windowAlertState.shake}
                    setVisible={
                        () => 
                            setWindowAlertState({ visible: false })
                    }
                />
            }
            {children}
        </WindowAlertContext.Provider>
    )
}

export default Screen