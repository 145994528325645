import translations_ from "../../../interfaces/translations.interface";
import lang from "../../../languages/lang";

interface tx_ {
    title_top: string,
    title: string,
    description: [
        string
    ]
}

const def_tx: translations_<tx_> = {
    "pt-BR": {
        title_top: "Comunidade",
        title: "Servicess",
        description: [
            "A Servicess é a nova plataforma de serviços do Brasil. Conectando pessoas diariamente de forma gratuita. Divulgue seu trabalho, encontre serviços, profissionais e empresas na sua região."
        ]
    },
    "en-US": {
        title_top: "Community",
        title: "Servicess",
        description: [
            "Servicess is the new services platform in Brazil. Connecting people daily for free. Advertise your work, find services, professionals and companies in your region."
        ]
    }
};

const tx = def_tx[lang];
export default tx;