import { IoChevronBack } from "react-icons/io5"
import Icon from "../../IconMarginRight"
import { useNavigate } from 'react-router-dom';
import './styles.css';

function GoBack({ paddingLeft = '0px' }) {
    const navigate = useNavigate();
    return (
        <div
            id="GoBack"
            style={{
                display: navigator.userAgent.match(/expo/i)
                    ? 'flex'
                    : 'none',
                paddingLeft
            }}
        >
            <button
                onClick={() => navigate(-1)}
                className='center'
            >
                <Icon style={{ marginLeft: -2 }}>
                    <IoChevronBack size={30} />
                </Icon>
            </button>
        </div>
    )
}

export default GoBack