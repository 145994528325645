import Icon from '../IconMarginRight';
import './styles.css'

const SearchTitles = (
    {
        title, subtitle, top = 50,
        icon, first = false
    }:
        {
            title: string, subtitle: string,
            top?: number, icon?: JSX.Element,
            first?: boolean
        }
) => {
    return (
        <div
            className={'search-titles'}
            style={{
                marginTop:
                    (first && window.innerWidth < 960)
                        ? 0
                        : (top + 'px')
            }}
        >

            <h2 className='center'>
                {icon && <Icon right={10}>
                    {icon}
                </Icon>}
                {title}
            </h2>
            <p>{subtitle}</p>
        </div>
    );
}

export default SearchTitles;