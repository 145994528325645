import RegistrationContainer from "../components/pageContent/RegistrationContainer"
import ThinHeader from "../components/Headers/ThinHeader"
import Screen from "../components/ScreenComponent"

function Registration() {
  return (
    <Screen>
      <ThinHeader />
      <RegistrationContainer />
    </Screen>
  )
}

export default Registration