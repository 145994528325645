import {
    IoHandLeftOutline,
    IoLockClosedOutline,
    IoPlanetOutline,
    IoRocketOutline,
    IoStarOutline
} from 'react-icons/io5'
import './styles.css'
import tx from './translation'

function Stripe() {
    return (
        <div id='Stripe'>
            <div>
                <span>
                    <IoRocketOutline />
                </span>
                <aside>
                    <h4>{tx[0].h4}</h4>
                    <p>p{tx[0].p}</p>
                </aside>
            </div>
            <div>
                <span>
                    <IoPlanetOutline />
                </span>
                <aside>
                    <h4>{tx[1].h4}</h4>
                    <p>{tx[1].p}</p>
                </aside>
            </div>
            <div>
                <span>
                    <IoStarOutline />
                </span>
                <aside>
                    <h4>{tx[2].h4}</h4>
                    <p>{tx[2].p}</p>
                </aside>
            </div>
            <div>
                <span>
                    <IoLockClosedOutline />
                </span>
                <aside>
                    <h4>{tx[3].h4}</h4>
                    <p>{tx[3].p}</p>
                </aside>
            </div>
            <div>
                <span>
                    <IoHandLeftOutline />
                </span>
                <aside>
                    <h4>{tx[4].h4}</h4>
                    <p>{tx[4].p}</p>
                </aside>
            </div>
        </div>
    )
}

export default Stripe