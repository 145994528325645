import './styles.css'

import GetAnAccount from '../../Form/GetAnAccount'

import { useState } from 'react'
import regex from '../../../functions/regex';
import { useNavigate } from "react-router-dom";
import SendResetPassword from '../../SendResetPassword';
import api from '../../../config/connection/axios';
import Input from '../../Form/Inputs/nested/Input';
import FormButton from '../../Form/Inputs/nested/FormButton';
import tx from './translation';

function LoginContainer() {
    const navigate = useNavigate()

    const [signinEmail, setSigninEmail] = useState<string>('')
    const [loginEmail, setLoginEmail] = useState<string>('')
    const [password, setPassword] = useState<string>('')

    const [spinner, setSpinner] = useState<boolean>(false);

    const handleSigninNavigation = (e: any, alertfy: any) => {
        e.preventDefault();
        if (!regex.email.test(signinEmail.trim()))
            alertfy('Formato de email invalido')
        else navigate('/signin', {
            state: {
                mail: signinEmail
            }
        })
    }

    const handleLoginSubmit = async (e: any, alertfy: any) => {
        e.preventDefault();

        setSpinner(true)

        api.put(
            '/users/login',
            {
                email: loginEmail.toLocaleLowerCase().trim(),
                password
            }
        )
            .then(r => {
                const result = r.data
                window.localStorage.setItem(
                    'serss-user',
                    JSON.stringify(result)
                )
                navigate('/')
            })
            .catch((e) => {
                setSpinner(false)
                if (
                    e.response &&
                    e.response.data &&
                    typeof e.response.data.message === 'string'
                ) alertfy(e.response.data.message)
            })
    }

    return (
        <div id="LoginContainer">
            <div className='LG-blocks LG-enter'>
                <form>
                    <div
                        style={{
                            width: '100%',
                            maxWidth: '560px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            flexDirection:
                                window.innerWidth < 960
                                    ? 'column'
                                    : 'row'
                        }}
                    >
                        {
                            window.innerWidth < 960 &&
                            <>
                                <div style={{ marginTop: '50px' }}>
                                    <GetAnAccount to='/signin' />
                                </div>

                                <div
                                    style={{
                                        width: '100%',
                                        display: 'flex'
                                    }}
                                >
                                    <h2
                                        style={{
                                            margin: '50px 0 0 0',
                                            paddingBottom: '6px',
                                            borderBottom: '4px solid #00ae74'
                                        }}
                                    >
                                        {tx.client}
                                    </h2>
                                </div>
                            </>
                        }
                    </div>
                    <Input
                        label={tx.inputs[0].label}
                        placeholder={tx.inputs[0].placeholder}
                        value={loginEmail}
                        onChange={setLoginEmail}
                    />
                    <Input
                       label={tx.inputs[1].label}
                       placeholder={tx.inputs[1].placeholder}
                        type='password'
                        value={password}
                        onChange={setPassword}
                    />
                    <SendResetPassword
                        email={loginEmail}
                        setEmail={setLoginEmail}
                    />
                    <FormButton
                        space={6}
                        text={tx.from_btn}
                        onClick={handleLoginSubmit}
                        spinner={spinner}
                    />
                </form>
            </div>
            <div className='LG-blocks LG-create'>
                <h2>{tx.acc.t}</h2>
                <form>
                    <Input
                        placeholder={tx.acc.input}
                        value={signinEmail}
                        onChange={setSigninEmail}
                    />
                    <FormButton
                        space={26}
                        text={tx.acc.btn}
                        onClick={handleSigninNavigation}
                    />
                </form>
            </div>
        </div>
    )
}

export default LoginContainer;