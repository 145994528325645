import translations_ from "../../../../interfaces/translations.interface";
import lang from "../../../../languages/lang";

interface tx_ {
    missing_data: string
}

const def_tx: translations_<tx_> = {
    "pt-BR": {missing_data: 'Algo deu errado, tente novamente mais tarde'},
    "en-US": {missing_data: 'Something went wrong, try again later'}
};

const tx = def_tx[lang];
export default tx;