import AsideProfDetails from '../square/AsideProfDetails';
import SubworkDetails from '../SubworkDetails';

function DetailsSelected() {
    return (
        <div
            id='DetailsContainer'
        >

            <main id='details-main'>
                <div
                    className='card156-tmp-copy'
                    style={{ overflow: 'hidden' }}
                >
                    <SubworkDetails />
                </div>
            </main>
            <AsideProfDetails />
        </div>
    )
}

export default DetailsSelected;