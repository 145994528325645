import { useState } from 'react'
import { IoAlertCircle, IoCloseCircleOutline } from 'react-icons/io5'

interface YellowAlert_ {
    children: JSX.Element,
    marginBottom?: number,
    marginTop?: number,
    backgroundColor?: string,
    color?: string,
    heavy?: string
}

function YellowAlert(
    {
        children, 
        marginBottom = 0,
        marginTop = 0, 
        backgroundColor = '#ffdb9b',
        color = '#996405', 
        heavy = '#fea403'
    }: YellowAlert_
) {
    const [show, setShow] = useState<boolean>(true)
    return (
        <>
            {
                show &&
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        alignItems: 'flex-start',
                        backgroundColor,
                        borderRadius: '8px',
                        borderLeft: '8px solid ' + heavy,
                        color,
                        marginTop: marginTop + 'px',
                        marginBottom: marginBottom + 'px',
                    }}
                >
                    <span
                        className='center'
                        style={{ opacity: 0 }}
                    >
                        <IoAlertCircle
                            size={20}
                            color={heavy}
                        />
                    </span>
                    {children}
                    <button
                        onClick={() => setShow(false)}
                        style={{ marginTop: 6 }}
                    >
                        <IoCloseCircleOutline
                            size={24}
                            color={color}
                        />
                    </button>
                </div>
            }
        </>
    )
}

export default YellowAlert