const findItOutStorage = [
    'https://firebasestorage.googleapis.com/v0/b/servicess-6e07b.firebasestorage.app/o/assets%2Fsix_fax.jpg?alt=media&token=7ca56904-3260-438c-8b57-4322fcc7d146',
'https://firebasestorage.googleapis.com/v0/b/servicess-6e07b.firebasestorage.app/o/assets%2Fsix_barber.jpg?alt=media&token=d5a36739-a568-4f90-aea2-e9336f4156c5',
'https://firebasestorage.googleapis.com/v0/b/servicess-6e07b.firebasestorage.app/o/assets%2Fsix_dev.jpg?alt=media&token=7e6b18e0-3a71-408a-a550-230bee7bc869',
'https://firebasestorage.googleapis.com/v0/b/servicess-6e07b.firebasestorage.app/o/assets%2Fsix_wash.png?alt=media&token=41ad129b-098e-4c85-8e26-1a8ddffa60ae',
'https://firebasestorage.googleapis.com/v0/b/servicess-6e07b.firebasestorage.app/o/assets%2Fsix_sofa.jpg?alt=media&token=754c04ff-2258-4154-9a58-0dc5031751b6',
'https://firebasestorage.googleapis.com/v0/b/servicess-6e07b.firebasestorage.app/o/assets%2Fsix_cont.jpg?alt=media&token=4746449c-5379-4b1c-8fe3-c8554adbd07a',


'https://firebasestorage.googleapis.com/v0/b/servicess-6e07b.firebasestorage.app/o/assets%2FprofContainer_1.jpeg?alt=media&token=443f101e-e04c-4f18-ae6b-569a1ad50271'

];
export default findItOutStorage;