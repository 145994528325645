
import { IoCheckmarkCircleOutline, IoOpenOutline } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";
import FormButton from "../../../Form/Inputs/nested/FormButton";
import Modal from "../../../Modal"
import tx from "../translation";

interface customTexts_ {
    check: string,
    title: string,
    content: JSX.Element | string,
    button: string
}

interface WcMessage_ {
    showMessage: number,
    customTexts?: customTexts_
}

function WcMessage({ showMessage, customTexts }: WcMessage_) {
    const texts: customTexts_ = {
        check: tx.d.check,
        title: tx.d.title,
        button: tx.d.button,
        content:
            <p
                style={{ color: '#00000090' }}
            >
                {tx.d.content[0]}
                <strong> {tx.d.content[1]} </strong>
                {tx.d.content[2]}
            </p>
    }
    const nextPage = `/details/${showMessage}`;
    const navigate = useNavigate();
    /*
        * Se fechar o modal tem que mudar de página de qualquer forma
    */
    const handleNext = (f: boolean) => {
        navigate(nextPage)
    }

    return (
        <Modal
            visible={Boolean(showMessage)}
            setVisible={handleNext}
            closeBtn={false}
            padding={'80px 60px'}
            children={
                <div
                    style={{
                        maxWidth: '400px',
                        padding: '0 40px'
                    }}
                    className='center column'
                >
                    <div
                        style={{
                            border: '1px solid #c4c4c4',
                            borderRadius: '12px',
                            padding: '20px'
                        }}
                    >
                        <strong
                            className="center"
                            style={{

                                fontSize: '1.2em'
                            }}
                        >
                            <span
                                className="center"
                                style={{ marginRight: '12px' }}
                            >
                                <IoCheckmarkCircleOutline
                                    size={34}
                                    color='#00ae74'
                                />
                            </span>
                            {
                                customTexts
                                    ? customTexts.check
                                    : texts.check
                            }
                        </strong>
                    </div>
                    <strong
                        style={{
                            marginTop: '30px',
                            fontSize: '1.2em'
                        }}
                    >
                        {
                            customTexts
                                ? customTexts.title
                                : texts.title
                        }
                    </strong>

                    {
                        customTexts
                            ? customTexts.content
                            : texts.content
                    }
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'flex-end',
                            color: 'dodgerblue'
                        }}
                    >
                        <Link to='/terms'>
                            <div className="center">
                                {tx.d.terms}
                                <span
                                    className="center"
                                    style={{ marginLeft: '6px' }}
                                >
                                    <IoOpenOutline />
                                </span>
                            </div>
                        </Link>
                    </div>
                    <Link to={nextPage} style={{ width: '100%' }}>
                        <FormButton
                            style={{
                                backgroundColor: '#00ae74',
                                border: '1px solid #00ae74'
                            }}
                            text={
                                customTexts
                                    ? customTexts.button
                                    : texts.button}
                        />
                    </Link>
                </div>
            }
        />
    )
}



export default WcMessage