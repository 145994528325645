
import { Link } from 'react-router-dom';
import GoBack from '../../mobileComponents/GoBack';

import './styles.css';

function ThinHeader({ button }: { button?: JSX.Element }) {
  return (
    <div className='T-Header-Container'>
      <GoBack paddingLeft='30px' />
      <div id="T-Header">
        <div
          className='T-h-center'
          style={{
            justifyContent:
              button ? 'space-between' : 'center'
          }}
        >
          <div
            className='T-h-c-l-img-wrap'
            style={{
              paddingLeft:
                button
                  ? '6px'
                  : 0
            }}
          >
            <Link to='/'>
              <img
                className='T-h-c-l-img'
                src={require('../../../assets/logo.webp')}
                alt=''
              />
            </Link>
            <div className='T-h-c-l-title'>
              <Link to='/'><h1>Servicess</h1></Link>
            </div>
          </div>
          {button && button}
        </div>
      </div>
    </div>
  );
}

export default ThinHeader;
