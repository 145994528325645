import ProfDetailsSquare from "../ProfDetailsSquare"

const AsideProfDetails = () => {
    return (
      <aside
        className={'DC-aside-fixed'}
        style={{ position: 'absolute', top: '260px' }}
      >
        <ProfDetailsSquare />
      </aside>
    )
  };

export default AsideProfDetails;