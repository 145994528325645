import translations_ from "../../../interfaces/translations.interface";
import lang from "../../../languages/lang";

interface tx_ {
    choose: string
}

const def_tx: translations_<tx_> = {
    "pt-BR": {choose: 'Escolha um serviço'},
    "en-US": {choose: 'Choose a service'}
};

const tx = def_tx[lang];
export default tx;