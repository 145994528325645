import translations_ from "../interfaces/translations.interface";
import lang from "./lang";


type tx_ = string

const tx: translations_<tx_> = {
    "pt-BR": "Serviços agendados",
    "en-US": "Scheduled services"
};


export default tx[lang];