import { Link } from "react-router-dom";
import "./styles.css";
import {
  FaPaintRoller,
  FaScrewdriver,
  FaSeedling,
  FaSpa,
  FaTruck,
  FaUtensils,
} from "react-icons/fa"
import cSlideS from "../../../constants/firebase/categoriesSliderStorage";
import tx from "./translation";

function CategoriesSlider() {

  const categories = [
    {
      icon: <FaPaintRoller />,
      name: tx[0],
      src: cSlideS[0]
    },
    {
      icon: <FaSeedling />,
      name: tx[1],
      src: cSlideS[1]
    },
    {
      icon: <FaSpa />,
      name: tx[2],
      src: cSlideS[2]
    },
    {
      icon: <FaTruck />,
      name: tx[3],
      src: cSlideS[3]
    },
    {
      icon: <FaScrewdriver />,
      name: tx[4],
      src: cSlideS[4]
    },
    {
      icon: <FaUtensils />,
      name: tx[5],
      src: cSlideS[5]
    },
  ]

  return (
    <div id="CategoriesSlider">
      {
        categories.map((it, idx) =>
          <Link
            to={`/search?search=${it.name}`}
            className="CatS-square"
            key={idx}
          >
            <div
              className="CatS-circle center"
              style={{
                backgroundImage: `url(${it.src})`
              }}
            >
              <div className='CatS-circle-gradient' />
            </div>
            <div className="CatS-square-wrap">
              <p
                className="ellipsis"
                style={{ maxWidth: '140px' }}
              >
                <strong
                  style={{ marginRight: '10px' }}
                >
                  {it.icon}
                </strong>
                {it.name}
              </p>
            </div>
          </Link>
        )
      }
    </div>
  );
}

export default CategoriesSlider;
