type lang_ = "en-US" | "pt-BR";

const lang: lang_ =
    (
        window.location.hostname === 'www.servicess.com.br' ||
        window.location.hostname === 'servicess.com.br' ||
        window.location.hostname === 'pt.servicess.app'
    )
        ? "pt-BR"
        : "en-US";

export default lang;