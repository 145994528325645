import '../alert.styles.css';

export default function Alert(
    { text }:
        { text?: string }
) {
    return (
        <div id="alert">
            <span id="closebtn" />
            {text ? text : ''}
        </div>
    )
}