import Input from '../../../Form/Inputs/nested/Input';
import { TextArea } from '../../../Form/Inputs/nested/TextArea';
import MaxLengthDesc from '../../../MaxLengthDesc';
import { useContext, useState } from 'react';
import CreateWorkContext from '../context';
import { IoTrashBinOutline } from 'react-icons/io5';
import WindowAlertContext from '../../../../context/windowAlert.context';
import shake from '../../../../languages/windowAlertForm';
import min from '../../../../languages/minPrice'
import WcTaxs from './WcTaxs';
import { subwork_ } from '../../DetailsContainer/interface';
import tx from '../translation';

const WcFirstModal = (
    { setVisible, sw }:
        { setVisible: any, sw?: subwork_ }
) => {
    const { setSubworks } = useContext(CreateWorkContext);
    const windowAlert = useContext(WindowAlertContext);

    const [title, setTitle] = useState<string>(sw?.title || '');
    const [description, setDescription] =
        useState<string>(sw?.description || '');
    const [price, setPrice] = useState<number>(sw?.price || 0);

    const addEditX = () => {
        if (!title || !price)
            return windowAlert?.confirm({ shake })
        if (price < 50) return windowAlert?.confirm({
            shake: min
        });

        (sw && sw.id)
            ? setSubworks.edit({
                id: sw.id,
                title, description, price
            })
            : setSubworks.push({
                id: new Date().getTime(),
                title, description, price
            });
        setVisible();
    }

    const delX = () => {
        (sw && sw.id) && setSubworks.del(sw.id);
        setVisible();
    }

    return (
        <div className='wc-subworks-modal'>
            <Input
                label={tx.b[0]}
                placeholder={tx.b[1]}
                value={title}
                onChange={setTitle}
                required
            />
            <TextArea
                label={tx.b[2]}
                placeholder={tx.b[3]}
                value={description}
                onChange={setDescription}

            />
            <MaxLengthDesc
                countDesc={
                    description
                        ? description.length
                        : 0
                }
            />
            <div
                style={{
                    display: 'flex',
                    alignItems: 'flex-end',
                    justifyContent: 'space-between'
                }}
            >
                <div
                    style={{
                        width: '48%',
                        position: 'relative'
                    }}
                >
                    <Input
                        label={tx.b[4]}
                        placeholder={min}
                        value={Boolean(price) && price}
                        onChange={setPrice}
                        type='number'
                        min={50}
                        clear={true}
                        required
                    />
                </div>
                <WcTaxs price={price} />
            </div>
            <div
                style={{ marginTop: '50px' }}
                className='center'
            >
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}
                >
                    {
                        Boolean(sw && sw.id) &&
                        <button
                            type='button'
                            onClick={delX}
                            style={{ width: '70px' }}
                            className='center'
                        >
                            <IoTrashBinOutline size={22} />
                        </button>
                    }
                    <button
                        type='button'
                        onClick={addEditX}
                        style={{
                            backgroundColor: 'var(--border)',
                            height: '48px',
                            flex: 1,
                            borderRadius: '8px'
                        }}
                    >
                        <strong>{tx.b[5]}</strong>
                    </button>
                </div>
            </div>
        </div>
    )
}


export default WcFirstModal;