import { useState } from "react";

import Header from "../../../components/Headers/ThinHeader";
import './styles.css';
import Footer from "../../../components/Footer";
import SafeDiv from "../../../components/SafeDiv";
import LanguageButtom from "../../../components/LanguageButtom";


export default function PrivacyPolicy() {
    const [language, setLanguage] = useState(0)
    window.scrollTo(0, 0);

    return (
        <>
            <Header isHome={1} />
            <SafeDiv style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ width: "80%", marginBottom: '80px' }}>

                    <LanguageButtom onClick={setLanguage} left={{ l: 'Português', n: 0 }} right={{ l: 'English', n: 1 }} />

                    {language === 0 && <Portuguese />}
                    {language === 1 && <English />}


                </div>

            </SafeDiv>
            <Footer />
        </>
    );
}

const English = () => {
    return (
        <>
            <p styles="background-color: white; box-sizing: inherit; margin: 0px; padding: 0px;">
                <span styles="color: #4a4a4a; font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial, sans-serif;">
                    <b>
                        <h2>Privacy Policy</h2>
                    </b>
                </span>
            </p>
            <p styles="background-color: white; box-sizing: inherit; color: #4a4a4a; font-family: BlinkMacSystemFont, -apple-system, &quot;Segoe UI&quot;, Roboto, Oxygen, Ubuntu, Cantarell, &quot;Fira Sans&quot;, &quot;Droid Sans&quot;, &quot;Helvetica Neue&quot;, Helvetica, Arial, sans-serif; font-size: 16px; margin: 0px; padding: 0px;">
                Servicess LTDA. built the Servicess app as a Free app. This SERVICE is provided by Servicess LTDA. at no cost and is intended for use as is.</p>
            <p styles="background-color: white; box-sizing: inherit; color: #4a4a4a; font-family: BlinkMacSystemFont, -apple-system, &quot;Segoe UI&quot;, Roboto, Oxygen, Ubuntu, Cantarell, &quot;Fira Sans&quot;, &quot;Droid Sans&quot;, &quot;Helvetica Neue&quot;, Helvetica, Arial, sans-serif; font-size: 16px; margin: 0px; padding: 0px;">
                This page is used to inform visitors regarding our policies with the collection, use, and disclosure of Personal Information if anyone decided to use our Service.</p>
            <p styles="background-color: white; box-sizing: inherit; color: #4a4a4a; font-family: BlinkMacSystemFont, -apple-system, &quot;Segoe UI&quot;, Roboto, Oxygen, Ubuntu, Cantarell, &quot;Fira Sans&quot;, &quot;Droid Sans&quot;, &quot;Helvetica Neue&quot;, Helvetica, Arial, sans-serif; font-size: 16px; margin: 0px; padding: 0px;">
                If you choose to use our Service, then you agree to the collection and use of information in relation to this policy. The Personal Information that we collect is used for providing and improving the Service. We will not use or share your information with anyone except as described in this Privacy Policy.</p>
            <p styles="background-color: white; box-sizing: inherit; color: #4a4a4a; font-family: BlinkMacSystemFont, -apple-system, &quot;Segoe UI&quot;, Roboto, Oxygen, Ubuntu, Cantarell, &quot;Fira Sans&quot;, &quot;Droid Sans&quot;, &quot;Helvetica Neue&quot;, Helvetica, Arial, sans-serif; font-size: 16px; margin: 0px; padding: 0px;">
                The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, which is accessible at Servicess unless otherwise defined in this Privacy Policy.</p>
            <p styles="background-color: white; box-sizing: inherit; color: #4a4a4a; font-family: BlinkMacSystemFont, -apple-system, &quot;Segoe UI&quot;, Roboto, Oxygen, Ubuntu, Cantarell, &quot;Fira Sans&quot;, &quot;Droid Sans&quot;, &quot;Helvetica Neue&quot;, Helvetica, Arial, sans-serif; font-size: 16px; margin: 0px; padding: 0px;">
                <span styles="box-sizing: inherit; color: #363636; font-weight: 700;">
                    Information Collection and Use</span>
            </p>
            <p styles="background-color: white; box-sizing: inherit; color: #4a4a4a; font-family: BlinkMacSystemFont, -apple-system, &quot;Segoe UI&quot;, Roboto, Oxygen, Ubuntu, Cantarell, &quot;Fira Sans&quot;, &quot;Droid Sans&quot;, &quot;Helvetica Neue&quot;, Helvetica, Arial, sans-serif; font-size: 16px; margin: 0px; padding: 0px;">
                For a better experience, while using our Service, we may require you to provide us with certain personally identifiable information, including but not limited to Name, Addresses, Phone numbers, Asset information, Personal identification number, Personal features, Activities, Geographical location, Employment information, Education information, Financial information. The information that we request will be retained by us and used as described in this privacy policy.</p>
            <div styles="background-color: white; box-sizing: inherit; color: #4a4a4a; font-family: BlinkMacSystemFont, -apple-system, &quot;Segoe UI&quot;, Roboto, Oxygen, Ubuntu, Cantarell, &quot;Fira Sans&quot;, &quot;Droid Sans&quot;, &quot;Helvetica Neue&quot;, Helvetica, Arial, sans-serif; font-size: 16px;">
                <p styles="box-sizing: inherit; margin: 0px; padding: 0px;">
                    The app does use third party services that may collect information used to identify you.</p>
                <p styles="box-sizing: inherit; margin: 0px; padding: 0px;">
                    Link to privacy policy of third party service providers used by the app</p>
                <ul styles="box-sizing: inherit; list-styles-image: initial; list-styles-position: initial; margin: 0px; padding: 0px;">
                    <li styles="box-sizing: inherit; margin: 10px 10px 10px 2.5em; padding: 0px;">
                        <a href="https://www.google.com/policies/privacy/" rel="noopener noreferrer" styles="background-color: transparent; box-sizing: inherit; color: #3273dc; cursor: pointer; text-decoration-line: none;" target="_blank">
                            Google Play Services</a>
                    </li>
                    <li styles="box-sizing: inherit; margin: 10px 10px 10px 2.5em; padding: 0px;">
                        <a href="https://firebase.google.com/policies/analytics" rel="noopener noreferrer" styles="background-color: transparent; box-sizing: inherit; color: #3273dc; cursor: pointer; text-decoration-line: none;" target="_blank">
                            Google Analytics for Firebase</a>
                    </li>
                    <li styles="box-sizing: inherit; margin: 10px 10px 10px 2.5em; padding: 0px;">
                        <a href="https://firebase.google.com/support/privacy/" rel="noopener noreferrer" styles="background-color: transparent; box-sizing: inherit; color: #3273dc; cursor: pointer; text-decoration-line: none;" target="_blank">
                            Firebase Crashlytics</a>
                    </li>
                    <li styles="box-sizing: inherit; margin: 10px 10px 10px 2.5em; padding: 0px;">
                        <a href="https://www.facebook.com/about/privacy/update/printable" rel="noopener noreferrer" styles="background-color: transparent; box-sizing: inherit; color: #3273dc; cursor: pointer; text-decoration-line: none;" target="_blank">
                            Facebook</a>
                    </li>
                    <li styles="box-sizing: inherit; margin: 10px 10px 10px 2.5em; padding: 0px;">
                        <a href="https://expo.io/privacy" rel="noopener noreferrer" styles="background-color: transparent; box-sizing: inherit; color: #3273dc; cursor: pointer; text-decoration-line: none;" target="_blank">
                            Expo</a>
                    </li>
                </ul>
            </div>
            <p styles="background-color: white; box-sizing: inherit; color: #4a4a4a; font-family: BlinkMacSystemFont, -apple-system, &quot;Segoe UI&quot;, Roboto, Oxygen, Ubuntu, Cantarell, &quot;Fira Sans&quot;, &quot;Droid Sans&quot;, &quot;Helvetica Neue&quot;, Helvetica, Arial, sans-serif; font-size: 16px; margin: 0px; padding: 0px;">
                <span styles="box-sizing: inherit; color: #363636; font-weight: 700;">
                    Log Data</span>
            </p>
            <p styles="background-color: white; box-sizing: inherit; color: #4a4a4a; font-family: BlinkMacSystemFont, -apple-system, &quot;Segoe UI&quot;, Roboto, Oxygen, Ubuntu, Cantarell, &quot;Fira Sans&quot;, &quot;Droid Sans&quot;, &quot;Helvetica Neue&quot;, Helvetica, Arial, sans-serif; font-size: 16px; margin: 0px; padding: 0px;">
                We want to inform you that whenever you use our Service, in a case of an error in the app we collect data and information (through third party products) on your phone called Log Data. This Log Data may include information such as your device Internet Protocol (“IP”) address, device name, operating system version, the configuration of the app when utilizing our Service, the time and date of your use of the Service, and other statistics.</p>
            <p styles="background-color: white; box-sizing: inherit; color: #4a4a4a; font-family: BlinkMacSystemFont, -apple-system, &quot;Segoe UI&quot;, Roboto, Oxygen, Ubuntu, Cantarell, &quot;Fira Sans&quot;, &quot;Droid Sans&quot;, &quot;Helvetica Neue&quot;, Helvetica, Arial, sans-serif; font-size: 16px; margin: 0px; padding: 0px;">
                <span styles="box-sizing: inherit; color: #363636; font-weight: 700;">
                    Cookies</span>
            </p>
            <p styles="background-color: white; box-sizing: inherit; color: #4a4a4a; font-family: BlinkMacSystemFont, -apple-system, &quot;Segoe UI&quot;, Roboto, Oxygen, Ubuntu, Cantarell, &quot;Fira Sans&quot;, &quot;Droid Sans&quot;, &quot;Helvetica Neue&quot;, Helvetica, Arial, sans-serif; font-size: 16px; margin: 0px; padding: 0px;">
                Cookies are files with a small amount of data that are commonly used as anonymous unique identifiers. These are sent to your browser from the websites that you visit and are stored on your device's internal memory.</p>
            <p styles="background-color: white; box-sizing: inherit; color: #4a4a4a; font-family: BlinkMacSystemFont, -apple-system, &quot;Segoe UI&quot;, Roboto, Oxygen, Ubuntu, Cantarell, &quot;Fira Sans&quot;, &quot;Droid Sans&quot;, &quot;Helvetica Neue&quot;, Helvetica, Arial, sans-serif; font-size: 16px; margin: 0px; padding: 0px;">
                This Service does not use these “cookies” explicitly. However, the app may use third party code and libraries that use “cookies” to collect information and improve their services. You have the option to either accept or refuse these cookies and know when a cookie is being sent to your device. If you choose to refuse our cookies, you may not be able to use some portions of this Service.</p>
            <p styles="background-color: white; box-sizing: inherit; color: #4a4a4a; font-family: BlinkMacSystemFont, -apple-system, &quot;Segoe UI&quot;, Roboto, Oxygen, Ubuntu, Cantarell, &quot;Fira Sans&quot;, &quot;Droid Sans&quot;, &quot;Helvetica Neue&quot;, Helvetica, Arial, sans-serif; font-size: 16px; margin: 0px; padding: 0px;">
                <span styles="box-sizing: inherit; color: #363636; font-weight: 700;">
                    Service Providers</span>
            </p>
            <p styles="background-color: white; box-sizing: inherit; color: #4a4a4a; font-family: BlinkMacSystemFont, -apple-system, &quot;Segoe UI&quot;, Roboto, Oxygen, Ubuntu, Cantarell, &quot;Fira Sans&quot;, &quot;Droid Sans&quot;, &quot;Helvetica Neue&quot;, Helvetica, Arial, sans-serif; font-size: 16px; margin: 0px; padding: 0px;">
                We may employ third-party companies and individuals due to the following reasons:</p>
            <ul styles="background-color: white; box-sizing: inherit; color: #4a4a4a; font-family: BlinkMacSystemFont, -apple-system, &quot;Segoe UI&quot;, Roboto, Oxygen, Ubuntu, Cantarell, &quot;Fira Sans&quot;, &quot;Droid Sans&quot;, &quot;Helvetica Neue&quot;, Helvetica, Arial, sans-serif; font-size: 16px; list-styles-image: initial; list-styles-position: initial; margin: 0px; padding: 0px;">
                <li styles="box-sizing: inherit; margin: 10px 10px 10px 2.5em; padding: 0px;">
                    To facilitate our Service;</li>
                <li styles="box-sizing: inherit; margin: 10px 10px 10px 2.5em; padding: 0px;">
                    To provide the Service on our behalf;</li>
                <li styles="box-sizing: inherit; margin: 10px 10px 10px 2.5em; padding: 0px;">
                    To perform Service-related services; or</li>
                <li styles="box-sizing: inherit; margin: 10px 10px 10px 2.5em; padding: 0px;">
                    To assist us in analyzing how our Service is used.</li>
            </ul>
            <p styles="background-color: white; box-sizing: inherit; color: #4a4a4a; font-family: BlinkMacSystemFont, -apple-system, &quot;Segoe UI&quot;, Roboto, Oxygen, Ubuntu, Cantarell, &quot;Fira Sans&quot;, &quot;Droid Sans&quot;, &quot;Helvetica Neue&quot;, Helvetica, Arial, sans-serif; font-size: 16px; margin: 0px; padding: 0px;">
                We want to inform users of this Service that these third parties have access to your Personal Information. The reason is to perform the tasks assigned to them on our behalf. However, they are obligated not to disclose or use the information for any other purpose.</p>
            <p styles="background-color: white; box-sizing: inherit; color: #4a4a4a; font-family: BlinkMacSystemFont, -apple-system, &quot;Segoe UI&quot;, Roboto, Oxygen, Ubuntu, Cantarell, &quot;Fira Sans&quot;, &quot;Droid Sans&quot;, &quot;Helvetica Neue&quot;, Helvetica, Arial, sans-serif; font-size: 16px; margin: 0px; padding: 0px;">
                <span styles="box-sizing: inherit; color: #363636; font-weight: 700;">
                    Security</span>
            </p>
            <p styles="background-color: white; box-sizing: inherit; color: #4a4a4a; font-family: BlinkMacSystemFont, -apple-system, &quot;Segoe UI&quot;, Roboto, Oxygen, Ubuntu, Cantarell, &quot;Fira Sans&quot;, &quot;Droid Sans&quot;, &quot;Helvetica Neue&quot;, Helvetica, Arial, sans-serif; font-size: 16px; margin: 0px; padding: 0px;">We value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.</p>
            <p styles="background-color: white; box-sizing: inherit; color: #4a4a4a; font-family: BlinkMacSystemFont, -apple-system, &quot;Segoe UI&quot;, Roboto, Oxygen, Ubuntu, Cantarell, &quot;Fira Sans&quot;, &quot;Droid Sans&quot;, &quot;Helvetica Neue&quot;, Helvetica, Arial, sans-serif; font-size: 16px; margin: 0px; padding: 0px;">
                <span styles="box-sizing: inherit; color: #363636; font-weight: 700;">
                    Links to Other Sites</span>
            </p>
            <p styles="background-color: white; box-sizing: inherit; color: #4a4a4a; font-family: BlinkMacSystemFont, -apple-system, &quot;Segoe UI&quot;, Roboto, Oxygen, Ubuntu, Cantarell, &quot;Fira Sans&quot;, &quot;Droid Sans&quot;, &quot;Helvetica Neue&quot;, Helvetica, Arial, sans-serif; font-size: 16px; margin: 0px; padding: 0px;">
                This Service may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that these external sites are not operated by us. Therefore, we strongly advise you to review the Privacy Policy of these websites. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.</p>
            <p styles="background-color: white; box-sizing: inherit; color: #4a4a4a; font-family: BlinkMacSystemFont, -apple-system, &quot;Segoe UI&quot;, Roboto, Oxygen, Ubuntu, Cantarell, &quot;Fira Sans&quot;, &quot;Droid Sans&quot;, &quot;Helvetica Neue&quot;, Helvetica, Arial, sans-serif; font-size: 16px; margin: 0px; padding: 0px;">
                <span styles="box-sizing: inherit; color: #363636; font-weight: 700;">
                    Children’s Privacy</span>
            </p>
            <p styles="background-color: white; box-sizing: inherit; color: #4a4a4a; font-family: BlinkMacSystemFont, -apple-system, &quot;Segoe UI&quot;, Roboto, Oxygen, Ubuntu, Cantarell, &quot;Fira Sans&quot;, &quot;Droid Sans&quot;, &quot;Helvetica Neue&quot;, Helvetica, Arial, sans-serif; font-size: 16px; margin: 0px; padding: 0px;">
                These Services do not address anyone under the age of 13. We do not knowingly collect personally identifiable information from children under 13 years of age. In the case we discover that a child under 13 has provided us with personal information, we immediately delete this from our servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us so that we will be able to do necessary actions.</p>
            <p styles="background-color: white; box-sizing: inherit; color: #4a4a4a; font-family: BlinkMacSystemFont, -apple-system, &quot;Segoe UI&quot;, Roboto, Oxygen, Ubuntu, Cantarell, &quot;Fira Sans&quot;, &quot;Droid Sans&quot;, &quot;Helvetica Neue&quot;, Helvetica, Arial, sans-serif; font-size: 16px; margin: 0px; padding: 0px;">
                <span styles="box-sizing: inherit; color: #363636; font-weight: 700;">
                    Changes to This Privacy Policy</span>
            </p>
            <p styles="background-color: white; box-sizing: inherit; color: #4a4a4a; font-family: BlinkMacSystemFont, -apple-system, &quot;Segoe UI&quot;, Roboto, Oxygen, Ubuntu, Cantarell, &quot;Fira Sans&quot;, &quot;Droid Sans&quot;, &quot;Helvetica Neue&quot;, Helvetica, Arial, sans-serif; font-size: 16px; margin: 0px; padding: 0px;">
                We may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page.</p>
            <p styles="background-color: white; box-sizing: inherit; color: #4a4a4a; font-family: BlinkMacSystemFont, -apple-system, &quot;Segoe UI&quot;, Roboto, Oxygen, Ubuntu, Cantarell, &quot;Fira Sans&quot;, &quot;Droid Sans&quot;, &quot;Helvetica Neue&quot;, Helvetica, Arial, sans-serif; font-size: 16px; margin: 0px; padding: 0px;">
                This policy is effective as of 2021-11-13</p>
            <p styles="background-color: white; box-sizing: inherit; color: #4a4a4a; font-family: BlinkMacSystemFont, -apple-system, &quot;Segoe UI&quot;, Roboto, Oxygen, Ubuntu, Cantarell, &quot;Fira Sans&quot;, &quot;Droid Sans&quot;, &quot;Helvetica Neue&quot;, Helvetica, Arial, sans-serif; font-size: 16px; margin: 0px; padding: 0px;">
                <span styles="box-sizing: inherit; color: #363636; font-weight: 700;">
                    Contact Us</span>
            </p>
            <p styles="background-color: white; box-sizing: inherit; color: #4a4a4a; font-family: BlinkMacSystemFont, -apple-system, &quot;Segoe UI&quot;, Roboto, Oxygen, Ubuntu, Cantarell, &quot;Fira Sans&quot;, &quot;Droid Sans&quot;, &quot;Helvetica Neue&quot;, Helvetica, Arial, sans-serif; font-size: 16px; margin: 0px; padding: 0px;">
                If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us at suporte@servicess.com.br.
            </p>
            <p styles="background-color: white; box-sizing: inherit; color: #4a4a4a; font-family: BlinkMacSystemFont, -apple-system, &quot;Segoe UI&quot;, Roboto, Oxygen, Ubuntu, Cantarell, &quot;Fira Sans&quot;, &quot;Droid Sans&quot;, &quot;Helvetica Neue&quot;, Helvetica, Arial, sans-serif; font-size: 16px; margin: 0px; padding: 0px;">
                <br />
            </p>
            <p styles="background-color: white; box-sizing: inherit; color: #4a4a4a; font-family: BlinkMacSystemFont, -apple-system, &quot;Segoe UI&quot;, Roboto, Oxygen, Ubuntu, Cantarell, &quot;Fira Sans&quot;, &quot;Droid Sans&quot;, &quot;Helvetica Neue&quot;, Helvetica, Arial, sans-serif; font-size: 16px; margin: 0px; padding: 0px;">
                <br />
            </p>
            <p styles="background-color: white; box-sizing: inherit; color: #4a4a4a; font-family: BlinkMacSystemFont, -apple-system, &quot;Segoe UI&quot;, Roboto, Oxygen, Ubuntu, Cantarell, &quot;Fira Sans&quot;, &quot;Droid Sans&quot;, &quot;Helvetica Neue&quot;, Helvetica, Arial, sans-serif; font-size: 16px; margin: 0px; padding: 0px;">
                <br />
            </p>

            <p>At Servicess, accessible from https://servicess.com.br/, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by Servicess and how we use it.</p>

            <p>If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us.</p>

            <p>This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect in Servicess. This policy is not applicable to any information collected offline or via channels other than this website.</p>

            <strong>Consent</strong>

            <p>By using our website, you hereby consent to our Privacy Policy and agree to its terms.</p>

            <strong>Information we collect</strong>

            <p>The personal information that you are asked to provide, and the reasons why you are asked to provide it, will be made clear to you at the point we ask you to provide your personal information.</p>
            <p>If you contact us directly, we may receive additional information about you such as your name, email address, phone number, the contents of the message and/or attachments you may send us, and any other information you may choose to provide.</p>
            <p>When you register for an Account, we may ask for your contact information, including items such as name, company name, address, email address, and telephone number.</p>

            <strong>How we use your information</strong>

            <p>We use the information we collect in various ways, including to:</p>

            <ul>
                <li>Provide, operate, and maintain our website</li>
                <li>Improve, personalize, and expand our website</li>
                <li>Understand and analyze how you use our website</li>
                <li>Develop new products, services, features, and functionality</li>
                <li>Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the website, and for marketing and promotional purposes</li>
                <li>Send you emails</li>
                <li>Find and prevent fraud</li>
            </ul>

            <strong>Log Files</strong>

            <p>Servicess follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and a part of hosting services' analytics. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users' movement on the website, and gathering demographic information.</p>


            <strong>Google DoubleClick DART Cookie</strong>

            <p>Google is one of a third-party vendor on our site. It also uses cookies, known as DART cookies, to serve ads to our site visitors based upon their visit to www.servicess.com.br and other sites on the internet. However, visitors may choose to decline the use of DART cookies by visiting the Google ad and content network Privacy Policy at the following URL – <a href="https://policies.google.com/technologies/ads">https://policies.google.com/technologies/ads</a></p>


            <strong>Advertising Partners Privacy Policies</strong>

            <p>You may consult this list to find the Privacy Policy for each of the advertising partners of Servicess.</p>

            <p>Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons that are used in their respective advertisements and links that appear on Servicess, which are sent directly to users' browser. They automatically receive your IP address when this occurs. These technologies are used to measure the effectiveness of their advertising campaigns and/or to personalize the advertising content that you see on websites that you visit.</p>

            <p>Note that Servicess has no access to or control over these cookies that are used by third-party advertisers.</p>

            <strong>Third Party Privacy Policies</strong>

            <p>Servicess's Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options. </p>

            <p>You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers, it can be found at the browsers' respective websites.</p>

            <strong>CCPA Privacy Rights (Do Not Sell My Personal Information)</strong>

            <p>Under the CCPA, among other rights, California consumers have the right to:</p>
            <p>Request that a business that collects a consumer's personal data disclose the categories and specific pieces of personal data that a business has collected about consumers.</p>
            <p>Request that a business delete any personal data about the consumer that a business has collected.</p>
            <p>Request that a business that sells a consumer's personal data, not sell the consumer's personal data.</p>
            <p>If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.</p>

            <strong>GDPR Data Protection Rights</strong>

            <p>We would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:</p>
            <p>The right to access – You have the right to request copies of your personal data. We may charge you a small fee for this service.</p>
            <p>The right to rectification – You have the right to request that we correct any information you believe is inaccurate. You also have the right to request that we complete the information you believe is incomplete.</p>
            <p>The right to erasure – You have the right to request that we erase your personal data, under certain conditions.</p>
            <p>The right to restrict processing – You have the right to request that we restrict the processing of your personal data, under certain conditions.</p>
            <p>The right to object to processing – You have the right to object to our processing of your personal data, under certain conditions.</p>
            <p>The right to data portability – You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.</p>
            <p>If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.</p>

            <strong>Children's Information</strong>

            <p>Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.</p>

            <p>Servicess does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.</p>

        </>
    )
}

const Portuguese = () => {
    return (
        <>
            <p styles="background-color: white; box-sizing: inherit; margin: 0px; padding: 0px;">
                <span styles="color: #4a4a4a; font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial, sans-serif;">
                    <b>
                        <h2>Política de Privacidade em Português</h2></b>
                </span>
            </p>
            <p styles="background-color: white; box-sizing: inherit; margin: 0px; padding: 0px;">
                <span styles="color: #4a4a4a; font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial, sans-serif;">
                    <br />
                </span>
            </p>
            <p styles="background-color: white; box-sizing: inherit; margin: 0px; padding: 0px;">
                <span styles="color: #4a4a4a; font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial, sans-serif;">
                    Servicess LTDA. construiu o aplicativo Servicess como um aplicativo gratuito. Este SERVIÇO é prestado pela Servicess LTDA. sem nenhum custo e deve ser usado no estado em que se encontra.</span>
            </p>
            <p styles="background-color: white; box-sizing: inherit; margin: 0px; padding: 0px;">
                <span styles="color: #4a4a4a; font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial, sans-serif;">
                    <br />
                </span>
            </p>
            <p styles="background-color: white; box-sizing: inherit; margin: 0px; padding: 0px;">
                <span styles="color: #4a4a4a; font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial, sans-serif;">
                    Esta página é usada para informar os visitantes sobre nossas políticas de coleta, uso e divulgação de Informações Pessoais, caso alguém decida usar nosso Serviço.</span>
            </p>
            <p styles="background-color: white; box-sizing: inherit; margin: 0px; padding: 0px;">
                <span styles="color: #4a4a4a; font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial, sans-serif;">
                    <br />
                </span>
            </p>
            <p styles="background-color: white; box-sizing: inherit; margin: 0px; padding: 0px;">
                <span styles="color: #4a4a4a; font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial, sans-serif;">
                    Se você optar por usar nosso Serviço, você concorda com a coleta e uso de informações em relação a esta política. As informações pessoais que coletamos são usadas para fornecer e melhorar o serviço. Não usaremos ou compartilharemos suas informações com ninguém, exceto conforme descrito nesta Política de Privacidade.</span>
            </p>
            <p styles="background-color: white; box-sizing: inherit; margin: 0px; padding: 0px;">
                <span styles="color: #4a4a4a; font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial, sans-serif;">
                    <br />
                </span>
            </p>
            <p styles="background-color: white; box-sizing: inherit; margin: 0px; padding: 0px;">
                <span styles="color: #4a4a4a; font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial, sans-serif;">
                    Os termos usados nesta Política de Privacidade têm os mesmos significados que em nossos Termos e Condições, que podem ser acessados na Servicess, a menos que definido de outra forma nesta Política de Privacidade.</span>
            </p>
            <p styles="background-color: white; box-sizing: inherit; margin: 0px; padding: 0px;">
                <span styles="color: #4a4a4a; font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial, sans-serif;">
                    <br />
                </span>
            </p>
            <p styles="background-color: white; box-sizing: inherit; margin: 0px; padding: 0px;">
                <span styles="color: #4a4a4a; font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial, sans-serif;">
                    Coleta e uso de informações</span>
            </p>
            <p styles="background-color: white; box-sizing: inherit; margin: 0px; padding: 0px;">
                <span styles="color: #4a4a4a; font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial, sans-serif;">
                    <br />
                </span>
            </p>
            <p styles="background-color: white; box-sizing: inherit; margin: 0px; padding: 0px;">
                <span styles="color: #4a4a4a; font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial, sans-serif;">
                    Para uma melhor experiência, ao usar nosso Serviço, podemos exigir que você nos forneça certas informações de identificação pessoal, incluindo, mas não se limitando a Nome, Endereços, Números de Telefone, Informações de Ativos, Número de Identificação Pessoal, Características Pessoais, Atividades, Localização geográfica, Informações sobre emprego, informações sobre educação, informações financeiras. As informações que solicitamos serão retidas por nós e usadas conforme descrito nesta política de privacidade.</span>
            </p>
            <p styles="background-color: white; box-sizing: inherit; margin: 0px; padding: 0px;">
                <span styles="color: #4a4a4a; font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial, sans-serif;">
                    <br />
                </span>
            </p>
            <p styles="background-color: white; box-sizing: inherit; margin: 0px; padding: 0px;">
                <span styles="color: #4a4a4a; font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial, sans-serif;">
                    O aplicativo usa serviços de terceiros que podem coletar informações usadas para identificá-lo.</span>
            </p>
            <p styles="background-color: white; box-sizing: inherit; margin: 0px; padding: 0px;">
                <span styles="color: #4a4a4a; font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial, sans-serif;">
                    <br />
                </span>
            </p>
            <p styles="background-color: white; box-sizing: inherit; margin: 0px; padding: 0px;">
                <span styles="color: #4a4a4a; font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial, sans-serif;">
                    Link para a política de privacidade de provedores de serviços terceirizados usados pelo aplicativo</span>
            </p>
            <p styles="background-color: white; box-sizing: inherit; margin: 0px; padding: 0px;">
                <span styles="color: #4a4a4a; font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial, sans-serif;">
                    <br />
                </span>
            </p>
            <ul styles="box-sizing: inherit; list-styles-image: initial; list-styles-position: initial; margin: 0px; padding: 0px;">
                <li styles="box-sizing: inherit; margin: 10px 10px 10px 2.5em; padding: 0px;">
                    <a href="https://www.google.com/policies/privacy/" rel="noopener noreferrer" styles="background-color: transparent; box-sizing: inherit; color: #3273dc; cursor: pointer; text-decoration-line: none;" target="_blank">
                        Google Play Services</a>
                </li>
                <li styles="box-sizing: inherit; margin: 10px 10px 10px 2.5em; padding: 0px;">
                    <a href="https://firebase.google.com/policies/analytics" rel="noopener noreferrer" styles="background-color: transparent; box-sizing: inherit; color: #3273dc; cursor: pointer; text-decoration-line: none;" target="_blank">
                        Google Analytics for Firebase</a>
                </li>
                <li styles="box-sizing: inherit; margin: 10px 10px 10px 2.5em; padding: 0px;">
                    <a href="https://firebase.google.com/support/privacy/" rel="noopener noreferrer" styles="background-color: transparent; box-sizing: inherit; color: #3273dc; cursor: pointer; text-decoration-line: none;" target="_blank">
                        Firebase Crashlytics</a>
                </li>
                <li styles="box-sizing: inherit; margin: 10px 10px 10px 2.5em; padding: 0px;">
                    <a href="https://www.facebook.com/about/privacy/update/printable" rel="noopener noreferrer" styles="background-color: transparent; box-sizing: inherit; color: #3273dc; cursor: pointer; text-decoration-line: none;" target="_blank">
                        Facebook</a>
                </li>
                <li styles="box-sizing: inherit; margin: 10px 10px 10px 2.5em; padding: 0px;">
                    <a href="https://expo.io/privacy" rel="noopener noreferrer" styles="background-color: transparent; box-sizing: inherit; color: #3273dc; cursor: pointer; text-decoration-line: none;" target="_blank">
                        Expo</a>
                </li>
            </ul>
            <br />
            <p styles="background-color: white; box-sizing: inherit; margin: 0px; padding: 0px;">
                <span styles="color: #4a4a4a; font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial, sans-serif;">
                    Dados de registro</span>
            </p>
            <p styles="background-color: white; box-sizing: inherit; margin: 0px; padding: 0px;">
                <span styles="color: #4a4a4a; font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial, sans-serif;">
                    <br />
                </span>
            </p>
            <p styles="background-color: white; box-sizing: inherit; margin: 0px; padding: 0px;">
                <span styles="color: #4a4a4a; font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial, sans-serif;">
                    Queremos informá-lo que sempre que você utilizar nosso Serviço, em caso de erro no aplicativo, coletamos dados e informações (por meio de produtos de terceiros) em seu telefone denominado Log Data. Esses dados de registro podem incluir informações como endereço de protocolo de Internet ("IP") do dispositivo, nome do dispositivo, versão do sistema operacional, configuração do aplicativo ao utilizar nosso serviço, hora e data de uso do serviço e outras estatísticas .</span>
            </p>
            <p styles="background-color: white; box-sizing: inherit; margin: 0px; padding: 0px;">
                <span styles="color: #4a4a4a; font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial, sans-serif;">
                    <br />
                </span>
            </p>
            <p styles="background-color: white; box-sizing: inherit; margin: 0px; padding: 0px;">
                <span styles="color: #4a4a4a; font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial, sans-serif;">
                    Cookies</span>
            </p>
            <p styles="background-color: white; box-sizing: inherit; margin: 0px; padding: 0px;">
                <span styles="color: #4a4a4a; font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial, sans-serif;">
                    <br />
                </span>
            </p>
            <p styles="background-color: white; box-sizing: inherit; margin: 0px; padding: 0px;">
                <span styles="color: #4a4a4a; font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial, sans-serif;">
                    Cookies são arquivos com uma pequena quantidade de dados que são comumente usados como identificadores exclusivos anônimos. Eles são enviados para o seu navegador a partir dos sites que você visita e são armazenados na memória interna do seu dispositivo.</span>
            </p>
            <p styles="background-color: white; box-sizing: inherit; margin: 0px; padding: 0px;">
                <span styles="color: #4a4a4a; font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial, sans-serif;">
                    <br />
                </span>
            </p>
            <p styles="background-color: white; box-sizing: inherit; margin: 0px; padding: 0px;">
                <span styles="color: #4a4a4a; font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial, sans-serif;">
                    Este Serviço não usa esses “cookies” explicitamente. No entanto, o aplicativo pode usar código de terceiros e bibliotecas que usam “cookies” para coletar informações e melhorar seus serviços. Você tem a opção de aceitar ou recusar esses cookies e saber quando um cookie está sendo enviado para o seu dispositivo. Se você optar por recusar nossos cookies, pode não ser capaz de usar algumas partes deste Serviço.</span>
            </p>
            <p styles="background-color: white; box-sizing: inherit; margin: 0px; padding: 0px;">
                <span styles="color: #4a4a4a; font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial, sans-serif;">
                    <br />
                </span>
            </p>
            <p styles="background-color: white; box-sizing: inherit; margin: 0px; padding: 0px;">
                <span styles="color: #4a4a4a; font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial, sans-serif;">
                    Provedores de serviço</span>
            </p>
            <p styles="background-color: white; box-sizing: inherit; margin: 0px; padding: 0px;">
                <span styles="color: #4a4a4a; font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial, sans-serif;">
                    <br />
                </span>
            </p>
            <p styles="background-color: white; box-sizing: inherit; margin: 0px; padding: 0px;">
                <span styles="color: #4a4a4a; font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial, sans-serif;">
                    Podemos empregar empresas terceirizadas e indivíduos pelos seguintes motivos:</span>
            </p>
            <p styles="background-color: white; box-sizing: inherit; margin: 0px; padding: 0px;">
                <span styles="color: #4a4a4a; font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial, sans-serif;">
                    <br />
                </span>
            </p>
            <p styles="background-color: white; box-sizing: inherit; margin: 0px; padding: 0px;">
                <span styles="color: #4a4a4a; font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial, sans-serif;">
                    Para facilitar nosso serviço;</span>
            </p>
            <p styles="background-color: white; box-sizing: inherit; margin: 0px; padding: 0px;">
                <span styles="color: #4a4a4a; font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial, sans-serif;">
                    Para fornecer o Serviço em nosso nome;</span>
            </p>
            <p styles="background-color: white; box-sizing: inherit; margin: 0px; padding: 0px;">
                <span styles="color: #4a4a4a; font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial, sans-serif;">
                    Para executar serviços relacionados com o serviço; ou</span>
            </p>
            <p styles="background-color: white; box-sizing: inherit; margin: 0px; padding: 0px;">
                <span styles="color: #4a4a4a; font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial, sans-serif;">
                    Para nos ajudar a analisar como nosso serviço é usado.</span>
            </p>
            <p styles="background-color: white; box-sizing: inherit; margin: 0px; padding: 0px;">
                <span styles="color: #4a4a4a; font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial, sans-serif;">
                    Queremos informar aos usuários deste Serviço que esses terceiros têm acesso às suas Informações Pessoais. O motivo é realizar as tarefas atribuídas a eles em nosso nome. No entanto, eles são obrigados a não divulgar ou usar as informações para qualquer outra finalidade.</span>
            </p>
            <p styles="background-color: white; box-sizing: inherit; margin: 0px; padding: 0px;">
                <span styles="color: #4a4a4a; font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial, sans-serif;">
                    <br />
                </span>
            </p>
            <p styles="background-color: white; box-sizing: inherit; margin: 0px; padding: 0px;">
                <span styles="color: #4a4a4a; font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial, sans-serif;">
                    Segurança</span>
            </p>
            <p styles="background-color: white; box-sizing: inherit; margin: 0px; padding: 0px;">
                <span styles="color: #4a4a4a; font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial, sans-serif;">
                    <br />
                </span>
            </p>
            <p styles="background-color: white; box-sizing: inherit; margin: 0px; padding: 0px;">
                <span styles="color: #4a4a4a; font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial, sans-serif;">
                    Valorizamos sua confiança em nos fornecer suas informações pessoais, portanto, estamos nos esforçando para usar meios comercialmente aceitáveis de protegê-las. Mas lembre-se que nenhum método de transmissão pela internet, ou método de armazenamento eletrônico é 100% seguro e confiável, e não podemos garantir sua segurança absoluta.</span>
            </p>
            <p styles="background-color: white; box-sizing: inherit; margin: 0px; padding: 0px;">
                <span styles="color: #4a4a4a; font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial, sans-serif;">
                    <br />
                </span>
            </p>
            <p styles="background-color: white; box-sizing: inherit; margin: 0px; padding: 0px;">
                <span styles="color: #4a4a4a; font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial, sans-serif;">
                    Links para outros sites</span>
            </p>
            <p styles="background-color: white; box-sizing: inherit; margin: 0px; padding: 0px;">
                <span styles="color: #4a4a4a; font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial, sans-serif;">
                    <br />
                </span>
            </p>
            <p styles="background-color: white; box-sizing: inherit; margin: 0px; padding: 0px;">
                <span styles="color: #4a4a4a; font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial, sans-serif;">
                    Este serviço pode conter links para outros sites. Se você clicar em um link de terceiros, você será direcionado a esse site. Observe que esses sites externos não são operados por nós. Portanto, recomendamos enfaticamente que você analise a Política de Privacidade desses sites. Não temos controle e não assumimos responsabilidade pelo conteúdo, políticas de privacidade ou práticas de quaisquer sites ou serviços de terceiros.</span>
            </p>
            <p styles="background-color: white; box-sizing: inherit; margin: 0px; padding: 0px;">
                <span styles="color: #4a4a4a; font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial, sans-serif;">
                    <br />
                </span>
            </p>
            <p styles="background-color: white; box-sizing: inherit; margin: 0px; padding: 0px;">
                <span styles="color: #4a4a4a; font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial, sans-serif;">
                    Privacidade das crianças</span>
            </p>
            <p styles="background-color: white; box-sizing: inherit; margin: 0px; padding: 0px;">
                <span styles="color: #4a4a4a; font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial, sans-serif;">
                    <br />
                </span>
            </p>
            <p styles="background-color: white; box-sizing: inherit; margin: 0px; padding: 0px;">
                <span styles="color: #4a4a4a; font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial, sans-serif;">
                    Esses Serviços não se dirigem a ninguém com menos de 13 anos. Não coletamos intencionalmente informações de identificação pessoal de crianças com menos de 13 anos. No caso de descobrirmos que uma criança menor de 13 anos nos forneceu informações pessoais, nós as apagamos imediatamente de nossos servidores. Se você é um pai ou responsável e sabe que seu filho nos forneceu informações pessoais, entre em contato para que possamos tomar as medidas necessárias.</span>
            </p>
            <p styles="background-color: white; box-sizing: inherit; margin: 0px; padding: 0px;">
                <span styles="color: #4a4a4a; font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial, sans-serif;">
                    <br />
                </span>
            </p>
            <p styles="background-color: white; box-sizing: inherit; margin: 0px; padding: 0px;">
                <span styles="color: #4a4a4a; font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial, sans-serif;">
                    Mudanças nesta Política de Privacidade</span>
            </p>
            <p styles="background-color: white; box-sizing: inherit; margin: 0px; padding: 0px;">
                <span styles="color: #4a4a4a; font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial, sans-serif;">
                    <br />
                </span>
            </p>
            <p styles="background-color: white; box-sizing: inherit; margin: 0px; padding: 0px;">
                <span styles="color: #4a4a4a; font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial, sans-serif;">
                    Podemos atualizar nossa Política de Privacidade de tempos em tempos. Portanto, é recomendável revisar esta página periodicamente para verificar quaisquer alterações. Iremos notificá-lo de quaisquer alterações, publicando a nova Política de Privacidade nesta página.</span>
            </p>
            <p styles="background-color: white; box-sizing: inherit; margin: 0px; padding: 0px;">
                <span styles="color: #4a4a4a; font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial, sans-serif;">
                    <br />
                </span>
            </p>
            <p styles="background-color: white; box-sizing: inherit; margin: 0px; padding: 0px;">
                <span styles="color: #4a4a4a; font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial, sans-serif;">
                    Esta política está em vigor a partir de 2021-11-13</span>
            </p>
            <p styles="background-color: white; box-sizing: inherit; margin: 0px; padding: 0px;">
                <span styles="color: #4a4a4a; font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial, sans-serif;">
                    <br />
                </span>
            </p>
            <p styles="background-color: white; box-sizing: inherit; margin: 0px; padding: 0px;">
                <span styles="color: #4a4a4a; font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial, sans-serif;">
                    Entre em contato conosco</span>
            </p>
            <p styles="background-color: white; box-sizing: inherit; margin: 0px; padding: 0px;">
                <span styles="color: #4a4a4a; font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial, sans-serif;">
                    <br />
                </span>
            </p>
            <p styles="background-color: white; box-sizing: inherit; margin: 0px; padding: 0px;">
                <span styles="color: #4a4a4a; font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial, sans-serif;">
                    Se você tiver alguma dúvida ou sugestão sobre nossa Política de Privacidade, não hesite em nos contatar pelo e-mail suporte@servicess.com.br.</span>
            </p>
            <p styles="background-color: white; box-sizing: inherit; margin: 0px; padding: 0px;">
                <span styles="color: #4a4a4a; font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial, sans-serif;">
                    <br />
                </span>
            </p>

            <p>No Servicess, acessível em https://servicess.com.br/, uma de nossas principais prioridades é a privacidade de nossos visitantes. Este documento de Política de Privacidade contém tipos de informações que são coletadas e registradas pelos Serviços e como as usamos.</p>

            <p>Se você tiver dúvidas adicionais ou precisar de mais informações sobre nossa Política de Privacidade, não hesite em nos contatar.</p>

            <p>Esta Política de Privacidade se aplica apenas às nossas atividades online e é válida para os visitantes do nosso site com relação às informações que eles compartilharam e/ou coletaram nos Serviços. Esta política não se aplica a nenhuma informação coletada offline ou por outros canais que não este site.</p>

            <strong>Consentimento</strong>

            <p>Ao usar nosso site, você concorda com nossa Política de Privacidade e concorda com seus termos.</p>

            <strong>Informações que coletamos</strong>

            <p>As informações pessoais que você deve fornecer e os motivos pelos quais são solicitados a fornecê-las ficarão claros no momento em que solicitarmos que você forneça suas informações pessoais.</p>
            <p>Se você entrar em contato conosco diretamente, poderemos receber informações adicionais sobre você, como seu nome, endereço de e-mail, número de telefone, o conteúdo da mensagem e/ou anexos que você nos enviar e qualquer outra informação que você decida fornecer .</p>
            <p>Quando você se registra em uma conta, podemos solicitar suas informações de contato, incluindo itens como nome, nome da empresa, endereço, endereço de e-mail e número de telefone.</p>

            <strong>Como usamos suas informações</strong>

            <p>Usamos as informações que coletamos de várias maneiras, inclusive para:</p>

            <ul>
                <li>Fornecer, operar e manter nosso site</li>
                <li>Melhorar, personalizar e expandir nosso site</li>
                <li>Entenda e analise como você usa nosso site</li>
                <li>Desenvolva novos produtos, serviços, recursos e funcionalidades</li>
                <li>Comunique-se com você, diretamente ou por meio de um de nossos parceiros, inclusive para atendimento ao cliente, para fornecer atualizações e outras informações relacionadas ao site e para fins de marketing e promocionais</li>
                <li>Enviar e-mails para você</li>
                <li>Encontre e evite fraudes</li>
            </ul>

            <strong>Arquivos de registro</strong>

            <p>Services segue um procedimento padrão de uso de arquivos de log. Esses arquivos registram os visitantes quando eles visitam sites. Todas as empresas de hospedagem fazem isso e uma parte da análise dos serviços de hospedagem. As informações coletadas pelos arquivos de log incluem endereços de protocolo da Internet (IP), tipo de navegador, provedor de serviços de Internet (ISP), carimbo de data e hora, páginas de referência/saída e, possivelmente, o número de cliques. Eles não estão vinculados a nenhuma informação que seja pessoalmente identificável. O objetivo das informações é analisar tendências, administrar o site, rastrear o movimento dos usuários no site e coletar informações demográficas.</p>

            <strong>Cookie DART do Google DoubleClick</strong>

            <p>O Google é um fornecedor terceirizado em nosso site. Ele também usa cookies, conhecidos como cookies DART, para veicular anúncios aos visitantes do nosso site com base em sua visita a www.servicess.com.br e outros sites na Internet. No entanto, os visitantes podem optar por recusar o uso de cookies DART visitando a Política de privacidade da rede de conteúdo e anúncios do Google no seguinte URL – <a href="https://policies.google.com/technologies/ads">https:/ /policies.google.com/technologies/ads</a></p>

            <strong>Políticas de privacidade de parceiros de publicidade</strong>

            <p>Você pode consultar esta lista para encontrar a Política de Privacidade de cada um dos parceiros de publicidade dos Serviços.</p>

            <p>Os servidores de anúncios ou redes de anúncios de terceiros usam tecnologias como cookies, JavaScript ou Web Beacons que são usados em seus respectivos anúncios e links que aparecem nos Serviços, que são enviados diretamente ao navegador dos usuários. Eles recebem automaticamente seu endereço IP quando isso ocorre. Essas tecnologias são usadas para medir a eficácia de suas campanhas publicitárias e/ou para personalizar o conteúdo publicitário que você vê nos sites que visita.</p>

            <p>Observe que o Servicess não tem acesso ou controle sobre esses cookies que são usados por anunciantes de terceiros.</p>

            <strong>Políticas de privacidade de terceiros</strong>

            <p>A Política de Privacidade dos Serviços não se aplica a outros anunciantes ou sites. Assim, aconselhamos que consulte as respectivas Políticas de Privacidade desses servidores de anúncios de terceiros para obter informações mais detalhadas. Pode incluir suas práticas e instruções sobre como desativar determinadas opções. </p>

            <p>Você pode optar por desativar os cookies por meio das opções individuais do navegador. Para saber informações mais detalhadas sobre o gerenciamento de cookies com navegadores específicos, elas podem ser encontradas nos respectivos sites dos navegadores.</p>

            <strong>Direitos de privacidade da CCPA (não vender minhas informações pessoais)</strong>

            <p>De acordo com a CCPA, entre outros direitos, os consumidores da Califórnia têm o direito de:</p>
            <p>Solicite que uma empresa que coleta dados pessoais de um consumidor divulgue as categorias e partes específicas de dados pessoais que uma empresa coletou sobre os consumidores.</p>
            <p>Solicite que uma empresa exclua quaisquer dados pessoais sobre o consumidor que uma empresa coletou.</p>
            <p>Solicite que uma empresa que venda os dados pessoais de um consumidor, não venda os dados pessoais do consumidor.</p>
            <p>Se você fizer uma solicitação, temos um mês para responder. Se você deseja exercer algum desses direitos, entre em contato conosco.</p>

            <strong>Direitos de proteção de dados do GDPR</strong>

            <p>Gostaríamos de ter certeza de que você está ciente de todos os seus direitos de proteção de dados. Todo usuário tem direito ao seguinte:</p>
            <p>O direito de acesso – Você tem o direito de solicitar cópias de seus dados pessoais. Podemos cobrar uma pequena taxa por este serviço.</p>
            <p>O direito de retificação – Você tem o direito de solicitar que corrijamos qualquer informação que você acredite estar incorreta. Você também tem o direito de solicitar que completemos as informações que você acredita estarem incompletas.</p>
            <p>O direito de apagar – Você tem o direito de solicitar que apaguemos seus dados pessoais, sob certas condições.</p>
            <p>O direito de restringir o processamento – Você tem o direito de solicitar que restrinjamos o processamento de seus dados pessoais, sob certas condições.</p>
            <p>O direito de se opor ao processamento – Você tem o direito de se opor ao nosso processamento de seus dados pessoais, sob certas condições.</p>
            <p>O direito à portabilidade de dados – Você tem o direito de solicitar que transfiramos os dados que coletamos para outra organização ou diretamente para você, sob certas condições.</p>
            <p>Se você fizer uma solicitação, temos um mês para responder. Se você deseja exercer algum desses direitos, entre em contato conosco.</p>

            <strong>Informações para crianças</strong>

            <p>Outra parte de nossa prioridade é adicionar proteção para crianças enquanto usam a Internet. Incentivamos os pais e responsáveis a observar, participar e/ou monitorar e orientar suas atividades on-line.</p>

            <p>Servicess não coleta intencionalmente nenhuma informação de identificação pessoal de crianças menores de 13 anos. Se você acha que seu filho forneceu esse tipo de informação em nosso site, recomendamos que você entre em contato conosco imediatamente e faremos nossos melhores esforços para remover imediatamente essas informações de nossos registros.</p>
        </>
    )
}