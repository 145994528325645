import ThinHeader from "../../components/Headers/ThinHeader"
import Screen from "../../components/ScreenComponent"
import WorkEditContainer from "../../components/pageContent/WorkCreateContainer/edit";

function EditWork() {
  window.scrollTo(0, 0);
  return (
    <Screen>
      <ThinHeader />
      <WorkEditContainer />
    </Screen>
  )
}

export default EditWork;