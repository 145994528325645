import professionsStorage from "../../../constants/firebase/professionsStorage";
import Profession from "../../homePage/Profession";
import "./styles.css";
import tx from "./translation";

function ProfessionsContainer() {
  return (
    <div className="professions-container">
      <Profession
        bki={professionsStorage[0]}
        name={tx[0].name}
        search={tx[0].search}
      />

      <Profession
        bki={professionsStorage[1]}
        name={tx[1].name}
        search={tx[1].search}        
      />

      <Profession
        bki={professionsStorage[2]}
        name={tx[2].name}
        search={tx[2].search}        
      />
    </div>
  );
}

export default ProfessionsContainer;
