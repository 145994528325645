export interface country_selector_list_ {
    "reg": string,
    "cod_iso": string,
    "cod_int": string
}

const country_selector_list = [
    {
        "reg": "Brasil",
        "cod_iso": "BRA",
        "cod_int": "br"
    },
    [
        {
            "reg": "United States",
            "cod_iso": "USA",
            "cod_int": "us"
        },
        {
            "reg": "Canada",
            "cod_iso": "CAN",
            "cod_int": "ca"
        },
        {
            "reg": "Australia",
            "cod_iso": "AUS",
            "cod_int": "au"
        },
        {
            "reg": "United Kingdom",
            "cod_iso": "GBR",
            "cod_int": "uk"
        },
        {
            "reg": "México",
            "cod_iso": "MEX",
            "cod_int": "mx"
        },
        {
            "reg": "भारत | India",
            "cod_iso": "IND",
            "cod_int": "in"
        },
        {
            "reg": "Indonesia",
            "cod_iso": "IDN",
            "cod_int": "id"
        }
    ],
    [
        {
            "reg": "Deutschland",
            "cod_iso": "DEU",
            "cod_int": "de"
        },
        {
            "reg": "Österreich",
            "cod_iso": "AUT",
            "cod_int": "at"
        },
        {
            "reg": "België | Belgique | Belgien",
            "cod_iso": "BEL",
            "cod_int": "be"
        },
        {
            "reg": "България",
            "cod_iso": "BGR",
            "cod_int": "bg"
        },
        {
            "reg": "Κύπρος",
            "cod_iso": "CYP",
            "cod_int": "cy"
        },
        {
            "reg": "Hrvatska",
            "cod_iso": "HRV",
            "cod_int": "hr"
        },
        {
            "reg": "Danmark",
            "cod_iso": "DNK",
            "cod_int": "dk"
        },
        {
            "reg": "Slovensko",
            "cod_iso": "SVK",
            "cod_int": "sk"
        },
        {
            "reg": "Slovenija",
            "cod_iso": "SVN",
            "cod_int": "si"
        },
        {
            "reg": "España",
            "cod_iso": "ESP",
            "cod_int": "es"
        },
        {
            "reg": "Eesti",
            "cod_iso": "EST",
            "cod_int": "ee"
        },
        {
            "reg": "Suomi",
            "cod_iso": "FIN",
            "cod_int": "fi"
        },
        {
            "reg": "France",
            "cod_iso": "FRA",
            "cod_int": "fr"
        },
        {
            "reg": "Ελλάδα",
            "cod_iso": "GRC",
            "cod_int": "gr"
        },
        {
            "reg": "Magyarország",
            "cod_iso": "HUN",
            "cod_int": "hu"
        },
        {
            "reg": "Éire | Ireland",
            "cod_iso": "IRL",
            "cod_int": "ie"
        },
        {
            "reg": "Italia",
            "cod_iso": "ITA",
            "cod_int": "it"
        },
        {
            "reg": "Latvija",
            "cod_iso": "LVA",
            "cod_int": "lv"
        },
        {
            "reg": "Lietuva",
            "cod_iso": "LTU",
            "cod_int": "lt"
        },
        {
            "reg": "Lëtzebuerg | Luxembourg | Luxemburg",
            "cod_iso": "LUX",
            "cod_int": "lu"
        },
        {
            "reg": "Malta",
            "cod_iso": "MLT",
            "cod_int": "mt"
        },
        {
            "reg": "Nederland",
            "cod_iso": "NLD",
            "cod_int": "nl"
        },
        {
            "reg": "Polska",
            "cod_iso": "POL",
            "cod_int": "pl"
        },
        {
            "reg": "Portugal",
            "cod_iso": "PRT",
            "cod_int": "pt"
        },
        {
            "reg": "Česko",
            "cod_iso": "CZE",
            "cod_int": "cz"
        },
        {
            "reg": "România",
            "cod_iso": "ROU",
            "cod_int": "ro"
        },
        {
            "reg": "Sverige",
            "cod_iso": "SWE",
            "cod_int": "se"
        }
    ],
    [
        {
            "reg": "Argentina",
            "cod_iso": "ARG",
            "cod_int": "ar"
        },
        {
            "reg": "Bolivia | Bulivia | Wuliwya",
            "cod_iso": "BOL",
            "cod_int": "bo"
        },
        {
            "reg": "Chile",
            "cod_iso": "CHL",
            "cod_int": "cl"
        },
        {
            "reg": "Colombia",
            "cod_iso": "COL",
            "cod_int": "co"
        },
        {
            "reg": "Ecuador",
            "cod_iso": "ECU",
            "cod_int": "ec"
        },
        {
            "reg": "Guyana",
            "cod_iso": "GUY",
            "cod_int": "gy"
        },
        {
            "reg": "Paraguay | Paraguái",
            "cod_iso": "PRY",
            "cod_int": "py"
        },
        {
            "reg": "Perú | Piruw | Piru",
            "cod_iso": "PER",
            "cod_int": "pe"
        },
        {
            "reg": "Suriname",
            "cod_iso": "SUR",
            "cod_int": "sr"
        },
        {
            "reg": "Uruguay",
            "cod_iso": "URY",
            "cod_int": "uy"
        }
    ]
]

export default country_selector_list;