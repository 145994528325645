import translations_ from "../../../../interfaces/translations.interface";
import lang from "../../../../languages/lang";

interface tx_ {
    t1: string,
    btn: string
}

const def_tx: translations_<tx_> = {
    "pt-BR": {t1:'Acesse esse anúncio para saber mais.',btn:'Ver mais'},
    "en-US": {t1:'Access this ad to find out more.',btn:'See more'}
};

const tx = def_tx[lang];
export default tx;