import translations_ from "../../../interfaces/translations.interface";
import lang from "../../../languages/lang";

interface tx_ {
    tittle: {
        t1: string,
        t2: string,
    },
    subtitle1: {
        t1: string,
        t2: string,
        t3: string,
    },
    subtitle2: {
        t1: string,
        t2: string,
        t3: string,
    },
    channel: {
        title: string
    },
    buttonA: string,
    info: {
        infoT: string,
        infoLi1: string,
        infoLi2: string
    },
    bank_info: {
        t: string,
        s1: string,
        s2: string,
        s3: string,
        s4: string
    },
    advice: {
        what_to_do: {
            t: string,
            p: {
                strong: string,
                normal1: string,
                normal2: string
            }
        },
        step_step: {
            t: string,
            p: {
                strong: string,
                normal: string
            },
            ul: {
                li1: {
                    t: string,
                    p: {
                        strong: string,
                        normal1: string,
                        normal2: string
                    }
                },
                li2: {
                    t: string,
                    p: string
                },
                li3: {
                    t: string,
                    p: string
                },
                li4: {
                    t: string,
                    p: string
                }
            }
        }
    }
}


const def_tx: translations_<tx_> = {
    "pt-BR": {
        tittle: {
            t1: '🚨 Pediram para fazer pagamento?',
            t2: 'NÃO faça, é golpe!',
        },
        subtitle1: {
            t1: 'Nós ',
            t2: ' NUNCA ',
            t3: ' efetuamos cobranças por telefone, e-mail, WhatsApp ou outros.'
        },
        subtitle2: {
            t1: 'Não passe seus dados, nós ',
            t2: 'NUNCA',
            t3: ' pedimos seus dados.'
        },
        channel: {
            title: 'Canais oficiais:'
        },
        buttonA: "Atendimento",
        info: {
            infoT: "Informações complementares:",
            infoLi1: "Gostariamos de reforçar que nós NÃO entramos em contato para efetuar vendas ou muito menos exigir qualquer tipo de pagamento.",
            infoLi2: "Os únicos canais de contato são os mencionados acima, usados exclusivamente para responder às suas solicitações. "
        },
        bank_info: {
            t: "Conta bancaria Oficial:",
            s1: "Para: ",
            s2: "Banco: ",
            s3: "Chave PIX (CNPJ): ",
            s4: "CNPJ: "
        },
        advice: {
            what_to_do: {
                t: "O que fazer agora?",
                p: {
                    normal1: "Em caso de contato não solicitado, ignore, bloqueie o número e informe-nos. Se possível, ",
                    strong: " registre um boletim de ocorrência ",
                    normal2: " no Núcleo de Combate aos Cibercrimes da sua cidade."
                }
            },
            step_step: {
                t: "Pediram seu cartão?",
                p: {
                    strong: "Nunca",
                    normal: "compartilhe fotos dos seus cartões bancários. Se isso aconteceu, siga estes passos:"
                },
                ul: {
                    li1: {
                        t: "Bloqueie Imediatamente:",
                        p: {
                            normal1: "Use o aplicativo do banco ou",
                            strong: "ligue para a central",
                            normal2: "do seu banco."
                        }
                    },
                    li2: {
                        t: "Monitore Transações:",
                        p: "Fique de olho em atividades suspeitas."
                    },
                    li3: {
                        t: "Atualize Senhas e PINs:",
                        p: "Mantenha-os seguros e mude-os regularmente."
                    },
                    li4: {
                        t: "Cuidado com Fraudes:",
                        p: "Esteja atento a tentativas de"
                    }
                }
            }
        }
    },
    "en-US": {
        tittle: {
            t1: '🚨 Did they ask to make payment?',
            t2: 'DON\'T do it, it\'s a scam!',
        },
        subtitle1: {
            t1: 'We ',
            t2: ' NEVER ',
            t3: ' charge by phone, email, WhatsApp or others.'
        },
        subtitle2: {
            t1: 'Do not pass on your details, we ',
            t2: 'NEVER',
            t3: ' ask for your details.'
        },
        channel: {
            title: 'Official channels:'
        },
        buttonA: "Get Help",
        info: {
            infoT: "Additional information:",
            infoLi1: "We would like to reinforce that we DO NOT contact you to make sales or much less demand any type of payment.",
            infoLi2: "The only contact channels are those mentioned above, used exclusively to respond to your requests."
        },
        bank_info: {
            t: "Official bank account:",
            s1: "To: ",
            s2: "Bank: ",
            s3: "PIX (CNPJ): ",
            s4: "CNPJ: "
        },
        advice: {
            what_to_do: {
                t: "What to do now?",
                p: {
                    normal1: "In case of unsolicited contact, ignore, block the number and inform us. If possible, ",
                    strong: " Register a police report",
                    normal2: " at the Cybercrime Combat Center"
                }
            },
            step_step: {
                t: "Did they ask for your card?",
                p: {
                    strong: "NEVER",
                    normal: " share photos of your bank cards. If this happened, follow these steps:"
                },
                ul: {
                    li1: {
                        t: "Block Immediately:",
                        p: {
                            normal1: "Use the bank app or",
                            strong: "call your bank center.",
                            normal2: ""
                        }
                    },
                    li2: {
                        t: "Monitor Transactions:",
                        p: "Keep an eye out for suspicious activity."
                    },
                    li3: {
                        t: "Update Passwords and PINs:",
                        p: "Keep them safe and change them regularly."
                    },
                    li4: {
                        t: "Beware of Fraud:",
                        p: "Be aware of "
                    }
                }
            }
        }
    },
};

const tx = def_tx[lang];
export default tx;