import { useEffect, useState } from 'react'
import './styles.css'

// Components:
import SafeDiv from '../../SafeDiv'

import WcFrist from './nested/WcFrist';
import WcLocation from './nested/WcLocation';
import { IoImages, IoLocationSharp } from 'react-icons/io5';
import Parting from './nested/Parting';

import WcMessage from './nested/WcMessage';

import CreateWorkContext from './context';
import WcGreenBack from './nested/WcGreenBack';
import { subworks_, work_ } from '../DetailsContainer/interface';
import { locationData_ } from '../../GetLocation/interface';
import { getUser } from '../../../constants/localStorage/User';
import { delY, editY, handleKeyDown, notAllowed, pushY } from './functions';
import api from '../../../config/connection/axios';
import { useLocation } from 'react-router-dom';
import headers from '../../../constants/localStorage/headers';
import X_updated_phone_TMP from '../../../functions/onlyDigitsTMP';
import FormButton from '../../Form/Inputs/nested/FormButton';
import tryAgainLater from '../../../languages/tryAgainLater';
import tx from './translation';
import YellowAlert from '../../YellowAlert';

function WorkEditContainer() {
    const User = getUser();

    const location: any = useLocation();
    const wid = location.state?.wid;

    const [spinner, setSpinner] = useState<boolean>(false);
    const [showMessage, setShowMessage] = useState<number | false>(false);

    const [title, setTitle] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [price, setPrice] = useState<number>(0);
    const [phone, setPhone] = useState<string>(User.phone || '');
    const [subworks, setSubworks] = useState<subworks_>([]);
    const [locationData, setLocationData] = useState<locationData_>({ state_name: 'Todo o Brasil' } as locationData_);

    const editWorkSubmit = async (e: any, alertfy: any) => {
        e.preventDefault();
        if (
            notAllowed(
                alertfy, title, price, phone,
                locationData, description
            )
        ) return;
        setSpinner(true);

        const body = {
            work_id: wid,
            work: {

                title,
                description,
                price,
                city_id: locationData.city_id,
            },
            subworks,
            phone: (phone !== User.phone) ? phone : null
        }

        await api.put(
            `/works/subworks/update`,
            body,
            { headers: headers() }
        )
            .then(r => {
                X_updated_phone_TMP(phone);
                setSpinner(false);
                const result: { work_id: number } = r.data;
                return setShowMessage(wid);
            })
            .catch(err => {
                setSpinner(false)
                if (
                    err.response &&
                    err.response.data &&
                    typeof err.response.data.message === 'string'
                ) alertfy(err.response.data.message)
                else alertfy(tryAgainLater);
            })

    }

    useEffect(() => {
        const fetchData = async () => {
            setSpinner(true);

            await api.get(`/works/subworks/load/${wid}`)
                .then(r => {
                    setSpinner(false);
                    const result: { subworks: subworks_, work: work_ } = r.data;
                    setTitle(result.work.title);
                    setDescription(result.work.description);
                    setPrice(result.work.price);
                    setSubworks(result.subworks);
                })
                .catch(() => setSpinner(false));
        }

        wid && fetchData()
    }, [])


    return (
        <CreateWorkContext.Provider
            value={{
                work: {
                    title, setTitle,
                    description, setDescription,
                    price, setPrice,
                    phone, setPhone,
                    locationData,
                    setLocationData
                },
                subworks,
                setSubworks: {
                    push: pushY(setSubworks),
                    del: delY(setSubworks),
                    edit: editY(setSubworks)
                }
            }}
        >
            {
                showMessage && <WcMessage
                    showMessage={showMessage}
                />
            }

            <WcGreenBack />
            <SafeDiv>
                <div
                    id='WorkCreate'
                    className='center column'
                    onKeyDown={handleKeyDown}
                >
                    <form>
                        <h2>{tx.edit[0]}</h2>
                        <WcFrist />
                        <Parting
                            text={tx.edit[1]}
                            icon={
                                <IoLocationSharp
                                    size={20}
                                    color='#75757599'
                                />
                            }
                        />
                        <WcLocation />
                        <div
                            className='done-button center column'
                        >
                            <div
                                style={{
                                    width: '90%',
                                    display: 'flex',
                                    alignItems: 'center'
                                }}
                            >

                                <div
                                    style={{
                                        flex: 1,
                                        height: 'fit-content'
                                    }}
                                >
                                    <FormButton
                                        onClick={editWorkSubmit}
                                        text='Editar anúncio'
                                        spinner={spinner}
                                        spinnerColor='var(--dark)'
                                        space={0}
                                        style={{
                                            borderRadius: '8px',
                                            height: '48px',
                                            width: '100%',
                                            backgroundColor: 'transparent',
                                            color: 'var(--dark)'

                                        }}
                                    />
                                </div>
                            </div>



                        </div>
                    </form>
                    <div>{/* !!!!!!!!!!!!!! Excluir essa div !!!!!!!!!! */}
                        <Parting
                            text={''}
                            icon={<></>
                            }
                        />
                        <div style={{ width: '100%', marginTop: '30px' }}>
                            <YellowAlert
                                children={
                                    <div style={{ display: 'flex', flexDirection: 'column', marginTop: '15px' }}>
                                        <strong>Edição de imagens em manutenção:</strong>
                                        <p>Retorno previsto para terça-feira, <strong>03/04/2025</strong>.</p>
                                    </div>
                                }
                            />
                        </div>
                    </div>
                    <div style={{ height: '160px' }} />
                </div>
            </SafeDiv>
        </CreateWorkContext.Provider >
    )
}

export default WorkEditContainer;