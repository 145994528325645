import DoubtsButton from "../../components/Buttons/DoubtsButton"
import WorkCreateContainer from "../../components/pageContent/WorkCreateContainer"
import ThinHeader from "../../components/Headers/ThinHeader"
import Screen from "../../components/ScreenComponent"

function CreateWork() {
  window.scrollTo(0, 0);
  return (
    <Screen>
      <ThinHeader
        button={<DoubtsButton />}
      />
      <WorkCreateContainer />
    </Screen>
  )
}

export default CreateWork;