import "./contactContainer.style.css";

import Input from "../../../Form/Inputs/nested/Input";
import { TextArea } from "../../../Form/Inputs/nested/TextArea";
import FormButton from "../../../Form/Inputs/nested/FormButton";
import api from "../../../../config/connection/axios";
import { useContext, useState } from "react";
import regex from "../../../../functions/regex";
import { IoChatbubble } from "react-icons/io5";
import CentrlMenu_ from "../../../../interfaces/centralMenu.interface";
import WindowAlertContext from "../../../../context/windowAlert.context";
import headers from "../../../../constants/localStorage/headers";
import help_center from "../../../../languages/help_center";
import tryAgainLater from "../../../../languages/tryAgainLater";
import tx from "./translation";

interface styB_ {
    backgroundColor?: string,
    border?: string
}
export default function ContactContainer(
    { centralMenuCode, uid }:
        { centralMenuCode: CentrlMenu_['code'], uid?: string }
) {

    const windowAlert = useContext(WindowAlertContext);

    const [name, setName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [message, setMessage] = useState<string>('');
    const [phone, setphone] = useState<string>('');

    const [spinner, setSpinner] = useState<boolean>(false);

    const [styB, setStyB] = useState<styB_>({
        backgroundColor: 'var(--dark)',
        border: '1px solid var(--dark)'
    });

    const handleSendEmail = async (e: any, alertfy: any) => {
        e.preventDefault();

        if (
            !name ||
            !email ||
            !message
        ) return alertfy(tx[0])

        if (email && !regex.email.test(email.trim()))
            return alertfy(tx[1])

        setSpinner(true)

        const hedaers =
            centralMenuCode === 'manage_data'
                ? headers
                : {};

        await api.put(
            centralMenuCode === 'manage_data'
                ? '/feedback/manage/data'
                : '/feedback',
            {
                suggestion: message, evaluation: '', uid: uid,
                email, phone, name, code: centralMenuCode
            },
            hedaers

        )
            .then(() => {
                setSpinner(false);
                setStyB({});
                windowAlert?.confirm({
                    p: tx[2],
                    strong: help_center
                });
            })
            .catch(() => {
                setSpinner(false);
                alertfy(tryAgainLater);
            })
    }


    return (
        <div id="ContatoContent" style={{ ...sty }}>
            <div
                id='contato-wrapper'
                style={{
                    width: '80%',
                    ...sty,
                    flexDirection: 'column'
                }}
            >
                <div
                    style={{
                        width: '100%',
                        ...sty,
                        flexDirection: 'column'
                    }}
                >
                    <div className="center column">
                        <div
                            className="center"
                            style={{
                                border: '1px solid #c4c4c4',
                                width: 'fit-content',
                                height: 'fit-content',
                                color: '#696969',
                                padding: '14px',
                                borderRadius: '14px',
                                boxShadow: '-2px 2px 6px var(--border)',
                                margin: '52px 0 -6px 0'
                            }}
                        >
                            <span
                                className="center"
                                style={{ position: 'relative' }}
                            >
                                <IoChatbubble
                                    size={40}
                                    color="var(--green)"
                                />
                                <strong
                                    style={{
                                        fontFamily: 'montserrat',
                                        fontSize: 'bold',
                                        color: 'white',
                                        position: 'absolute'
                                    }}>
                                    S
                                </strong>
                            </span>
                        </div>
                        <h2
                            style={{ color: 'var(--green)' }}
                        >
                            {tx[3]}
                        </h2>
                    </div>
                </div>

                <div
                    id='wrap-contato-formulario'
                    style={{
                        width: '100%', ...sty
                    }}
                >
                    <div
                        id='c-formulario'
                        style={{
                            width: '60%',
                            marginBottom: '60px'
                        }}
                    >
                        <div
                            className={'Formulario Formulario--Contato'}
                        >
                            <form>
                                <div className='form_top_inputs'>
                                    <Input
                                        label={tx[4].label}
                                        placeholder={tx[4].placeholder}
                                        value={name}
                                        onChange={setName}
                                    />

                                </div>

                                <div className='form_top_inputs'>
                                    <Input
                                        label={tx[5].label}
                                        placeholder={tx[5].placeholder}

                                        value={email}
                                        onChange={setEmail}
                                    />
                                    <Input
                                        label={tx[6].label}
                                        placeholder={tx[6].placeholder}

                                        value={phone}
                                        onChange={setphone}
                                    />
                                </div>
                                <div className='form_botton_textArea'>
                                    <TextArea
                                        label={tx[7].label}
                                        placeholder={tx[7].placeholder}

                                        value={message}
                                        onChange={setMessage}
                                    />
                                </div>
                                <div className='form_button'>
                                    <FormButton
                                        style={{ ...styB }}
                                        text={
                                            styB.border
                                                ? "Enviar"
                                                : '✓'
                                        }
                                        onClick={
                                            styB.border
                                                ? handleSendEmail
                                                : (e: Event) => e.preventDefault()
                                        }
                                        spinner={spinner}
                                    />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


const sty = {
    backgroundColor: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
}