import "./style.css";
import tx from "./translation";

export default function AboutUs() {
    return (
        <div id="AboutUs">
            <div className='gradient gradient--left'>
                <div className="sobre_titulo">
                    {
                        window.innerWidth < 960
                            ? <AboutImgOne />
                            : <AboutImgTwo />
                    }
                    <div className="sobre_titulo-comserv">
                        <span>
                            {tx.title_top}
                        </span>
                        <h2>{tx.title}</h2>
                    </div>
                </div>
                <div className="sobre_texto">
                    {tx.description.map((paragraph, idx) => (
                        <p
                            key={idx}
                        >
                            {paragraph}
                        </p>
                    ))}
                </div>

            </div>
            <div className='gradient gradient--right'>
                <img
                    alt=''
                    src={'https://st.depositphotos.com/1700950/1220/i/600/depositphotos_12205740-stock-photo-closeup-of-a-business-hand.jpg'}
                />
                <div className='gradient_absolut_right' />
            </div>
        </div>
    );
}

const AboutImgOne = () => (
    <div
        className='center sobre_titulo_img_one'
        style={{
            backgroundColor: '#222D36',
            borderRadius: '100%',
            height: '84px',
            width: '84px',
            marginRight: '14px'
        }}
    >
        <img
            src={require('../../../assets/logo.webp')}
            alt=''
        />
    </div>
)

const AboutImgTwo = () => (
    <img
        className='sobre_titulo_img_two'
        src={require('../../../assets/servicesscommunity.webp')}
        alt=''
    />
)
