import { useEffect, useState } from 'react';
import './styles.css'
import api from '../../../config/connection/axios';
import Spinner from '../../Spinner';

/*
  * Interface
*/
import { subwork_, subworks_, work_ } from './interface';


import DetailsContext from './context';
import SafeDiv from '../../SafeDiv';
import { Outlet, useParams } from 'react-router-dom';

function DetailsContainer() {

  const [work, setWork] = useState<work_>({} as work_);
  const [subworks, setSubworks] = useState<subworks_>([] as subworks_);
  const [selected, setSelected] = useState<subwork_>({} as subwork_);
  const { work_id } = useParams();
  const [spinner, setSpinner] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {

      setSpinner(true);

      await api.get(`/works/subworks/load/${work_id}`)
        .then(r => {
          const result = r.data;
          setWork(result.work || {});
          setSubworks(result.subworks || {});
          setSpinner(false);
        })
        .catch(() => setSpinner(false));
    }

    if (!isNaN(Number(work_id)))
      fetchData();

  }, [work_id, setSpinner, setWork])

  return (
    <DetailsContext.Provider
      value={{
        work,
        subworks,
        selected,
        setSelected
      }}
    >
      {
        spinner &&
        <Spinner
          color={'var(--dark)'}
          visible={spinner}
        />
      }
      {
        !spinner &&
        <SafeDiv>
          <Outlet />
        </SafeDiv>
      }
    </DetailsContext.Provider>
  )
}

export default DetailsContainer