import { IoArrowForwardOutline } from 'react-icons/io5'
import { Link } from 'react-router-dom'
import Icon from '../../IconMarginRight'
import './styles.css'

interface SeeButton_ {
    to:string,
    text:string,
    type?: 1 | 2
}

function SeeButton({to, text, type=1}:SeeButton_) {
    return (
        <Link to={to} className={`center see-p-link--${type}`}>
        <button className={`CB-see-p-button--${type}`}>
            {text}
            <Icon>
                <IoArrowForwardOutline />
            </Icon>
        </button>
    </Link>
    )
}

export default SeeButton