
import { IoLogoWhatsapp, IoMailOutline } from "react-icons/io5";
import './CMenuManageFraud.css';
import tx from "../tx/fraud.translation";
import call_center_number from '../../../languages/call_center_numbers.json';
import servicess_cnpj from '../../../languages/servicess_cnpj.json';
import mercado_pago_info from '../../../languages/mercado_pago_info.json'

function CMenuManageFraud({ setCentralMenu }: { setCentralMenu: any }) {

    return (
        <div
            style={{ backgroundColor: 'white', minHeight: '1000px', lineHeight: '1.6' }}

        >
            <div
                className="center column"
                id="CMenuManageFraud"
            >
                <strong className="CMenuManageFraud-a">{tx.tittle.t1}</strong>
                <strong className="CMenuManageFraud-b">{tx.tittle.t2}</strong>
                <p className="CMenuManageFraud-c" >{tx.subtitle1.t1}<strong>{tx.subtitle1.t2}</strong>{tx.subtitle1.t3}</p>
                <p className="CMenuManageFraud-d">{tx.subtitle2.t1}<strong>{tx.subtitle2.t2}</strong>{tx.subtitle2.t3}</p>
            </div>
            <div className="CMenuManageFraud-middle-div" style={{ padding: '40px 0' }}>
                <strong className="CMenuManageFraud-e"

                >
                    {tx.channel.title}
                </strong>
                <ul style={{ marginTop: '30px' }}>
                    <li className="CMenuManageFraud-li">
                        <strong>
                            <strong style={{ marginRight: '6px' }}>
                                <IoMailOutline />
                            </strong>
                            E-mail:
                        </strong>
                        suporte@servicess.com.br
                    </li>
                    <li className="CMenuManageFraud-li">
                        <strong>
                            <strong style={{ marginRight: '6px' }}>
                                <IoLogoWhatsapp />
                            </strong>
                            WhatsApp:
                        </strong>
                        <ol id="CMenuManageFraud-ol">
                            <li className="CMenuManageFraud-i">{call_center_number}</li>
                        </ol>
                    </li>
                </ul>
            </div>
            <div className="center" style={{ marginTop: '-10px' }}>
                <button
                    onClick={() => setCentralMenu({ screen: 1, code: 'fraud' })}
                    className="CMenuManageFraud-btn"
                >
                    {tx.buttonA}
                </button>
            </div>
            <div className="CMenuManageFraud-middle-div" style={{ padding: '80px 0 40px 0' }}>
                <strong style={{ fontSize: '30px' }}>{tx.info.infoT}</strong>
                <ol>
                    <li className="CMenuManageFraud-li">{tx.info.infoLi1}</li>
                    <li className="CMenuManageFraud-li">{tx.info.infoLi2}</li>
                    <li className="CMenuManageFraud-li">
                        <strong>
                            {tx.bank_info.t}
                        </strong>
                        <ol style={{ listStyle: 'circle' }}>
                            <li className="CMenuManageFraud-li">
                                <p>{tx.bank_info.s1} <strong>{servicess_cnpj.razao_social}</strong></p>
                            </li>
                            <li className="CMenuManageFraud-li">
                                <p>{tx.bank_info.s2}  <strong>{mercado_pago_info.mp_bank_name}</strong></p>
                            </li>
                            <li className="CMenuManageFraud-li">
                                <p>{tx.bank_info.s3} <strong>{mercado_pago_info.chave_pix}</strong></p>
                            </li>
                            <li className="CMenuManageFraud-li">
                                <p>{tx.bank_info.s4} <strong>{servicess_cnpj.num_cnpj}</strong></p>
                            </li>
                        </ol>
                    </li>
                </ol>
            </div>

            <B1 />

            <div className="CMenuManageFraud-middle-div" style={{ padding: '40px 0' }}>
                <strong style={{ fontSize: '30px' }}>
                    {tx.advice.what_to_do.t}
                </strong>
                <p>
                    {tx.advice.what_to_do.p.normal1} <strong>{tx.advice.what_to_do.p.strong}</strong> {tx.advice.what_to_do.p.normal2}
                </p>
            </div>

            <div className="CMenuManageFraud-middle-div" style={{ padding: '40px 0' }}>
                <strong style={{ fontSize: '30px' }}>
                    {tx.advice.step_step.t}
                </strong>
                <p>
                    <strong>{tx.advice.step_step.p.strong}</strong> {tx.advice.step_step.p.normal}
                </p>
                <ol>
                    <li className="CMenuManageFraud-li--2">
                        <strong>{tx.advice.step_step.ul.li1.t}</strong>
                        <ul style={{ listStyle: 'square', margin: '6px 0 0 20px' }}>
                            <li>{tx.advice.step_step.ul.li1.p.normal1} <strong>{tx.advice.step_step.ul.li1.p.strong}</strong> {tx.advice.step_step.ul.li1.p.normal2}</li>
                        </ul>
                    </li>

                    <li className="CMenuManageFraud-li--2">
                        {tx.advice.step_step.ul.li2.t}
                        <ul style={{ listStyle: 'square', margin: '6px 0 0 20px' }}>
                            <li>{tx.advice.step_step.ul.li2.p}</li>
                        </ul>
                    </li>

                    <li className="CMenuManageFraud-li--2">
                        {tx.advice.step_step.ul.li3.t}
                        <ul style={{ listStyle: 'square', margin: '6px 0 0 20px' }}>
                            <li>{tx.advice.step_step.ul.li3.p}</li>
                        </ul>
                    </li>

                    <li className="CMenuManageFraud-li--2">
                        {tx.advice.step_step.ul.li4.t}
                        <ul style={{ listStyle: 'square', margin: '6px 0 0 20px' }}>
                            <li>{tx.advice.step_step.ul.li4.p} <a href="https://support.microsoft.com/en-US/windows/proteja-se-contra-phishing-0c7ea947-ba98-3bd9-7184-430e1f860a44" target="_blank" style={{ color: 'dodgerblue', textDecoration: 'underline' }}>phishing</a>.</li>
                        </ul>
                    </li>
                </ol>
            </div>
            <B1 />
        </div>
    )
}

const B1 = () => (
    <div style={{ width: '100%', padding: '40px 0' }} className="center">
        <div className="cmenufraud-b1" />
    </div>
)

export default CMenuManageFraud;