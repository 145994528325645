import './styles.css'
import {
    IoImageOutline,
    IoLocationSharp,
    IoRepeatOutline
} from 'react-icons/io5'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import money from '../../../functions/money'
import Avatar from '../../imagesComponents/Avatar'
import Banner from '../../imagesComponents/Banner'
import ImagesCardSlider from '../../imagesComponents/ImagesCardSlider'
import hideContactsDescription from '../../../functions/hideContactsDescription.simple'
import tx from './tx/translations'

interface Card_ {
    work: any,
    className: 'PreDefined' | 'InSearch'
}

function Card({
    work,
    className
}: Card_) {

    const [switcher, setSwitcher] = useState<'a' | 'b'>('b');
    const [haveBanner, setHaveBanner] = useState<boolean>(true);
    const [haveAvatar, setHaveAvatar] = useState<boolean>(work.avatar);

    const [hideWrapper, setHideWrapper] = useState({ zero: false, })

    const handleSwitch = () => {
        setSwitcher(switcher === 'b' ? 'a' : 'b');
    }



    const handleOnBannerError = () => {
        setHaveBanner(false)
        setSwitcher('a')
    }

    const handleOnAvatarError = () => {
        setHaveAvatar(false);
    }

    return (
        <div id='Card' className={className}>

            {
                (window.innerWidth > 500) &&
                <button
                    id='Card-switch-btn'
                    className='center'
                    onClick={handleSwitch}
                >
                    <IoRepeatOutline size={18} />
                </button>
            }

            <Link to={`/details/${work.id}`}>
                <div className={`Card-top Card-top--${className}`}>
                    {window.innerWidth > 500 && <>
                        {(switcher === 'b')
                            ? (
                                <div className='Card-banner'>

                                    <div className='Card-banner-img center'>
                                        <Banner
                                            uid={work.uid}
                                            wid={work.id}
                                            current='card'
                                            handleOnBannerError={handleOnBannerError}

                                        />

                                        <span style={{ position: 'absolute', zIndex: 0 }}>
                                            <IoImageOutline />
                                        </span>

                                    </div>
                                    <div style={{ position: 'absolute', width: '100%', bottom: 0, height: '40%', background: 'linear-gradient(360deg, rgba(34, 45, 54, 0.7) 30.33%, rgba(34, 45, 54, 0) 100%)', zIndex: 2 }}>
                                        <div className='Card-banner-bottom-location'>
                                            <div className='center' style={{ width: '45px', height: '100%' }}>
                                                <aside style={{ width: '32px', height: '32px' }} className='center'>
                                                    <Avatar borderRadius='8px' placeholder='transparent' uid={work.uid} size={10} handleOnAvatarError={handleOnAvatarError} />

                                                </aside>
                                            </div>
                                            <div style={{ width: 'calc(84% - 50px)', height: 'fit-content' }}>
                                                {(work.profession && work.title && work.profession.toString().trim() === work.title.toString().trim())
                                                    ? <></>
                                                    : <p className='card-b-user-name'><p className='ellipsis' style={{ maxWidth: '240px', marginBottom: '2px' }}>{work.profession}</p></p>
                                                }
                                                <h2 style={{ textAlign: 'start', maxWidth: '240px' }} className='ellipsis'>{work.name}</h2>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            )
                            : (
                                <div className={`Card-user Card-user--${className}`}>
                                    <div className={`Card-user-img Card-user-img--${className} center`} >
                                        <Avatar borderRadius='8px' uid={work.uid} size={20} />
                                    </div>
                                    {(work.profession && work.title && work.profession.toString().trim() === work.title.toString().trim())
                                        ? <p style={{ opacity: 0 }}>.</p>
                                        : <p style={{ textAlign: 'center' }}>{work.profession}</p>
                                    }
                                    <h2>{work.name}</h2>


                                </div>
                            )
                        }
                    </>}
                    {
                        window.innerWidth < 500 &&
                        <div className='center column' style={{ width: '100%' }}>
                            <div style={{ position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'flex-start', width: '84%', padding: '14px 8%', borderBottom: '1px solid #DEE6EB' }}>
                                <span>
                                    <div style={{ height: '38px', width: '38px' }} className='center'>
                                        <Avatar uid={work.uid} size={16} borderRadius='12px' placeholder='transparent' />
                                    </div>
                                </span>
                                <div className='card-top-mobile'>
                                    {(work.profession && work.title && (work.profession.toString().trim() === work.title.toString().trim())) ? <></> : <h6>{work.profession}</h6>}
                                    <h4>{work.name}</h4>

                                </div>
                            </div>


                            <ImagesCardSlider
                                type='card'
                                alias='ImagesSlider'
                                hideWrapper={hideWrapper}
                                id={work.id}
                                setHideWrapper={setHideWrapper}
                                user_uid={work.uid}

                            />

                        </div>
                    }
                </div>
                <div className={`Card-bottom Card-bottom--${className}`}>
                    <div>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', position: 'relative' }}>
                            <div style={{ maxWidth: '80%' }} >
                                <h1 className='ellipsis'>{work.title}</h1>
                            </div>
                        </div>
                        <span className='card-b-middle'>
                            <p
                                className={`card-b-desc card-b-desc--${className} description-ellipsis`}
                                style={{ paddingTop: '5px' }}
                            >
                                {
                                    work.description
                                        ? hideContactsDescription(work.description)
                                        : tx
                                }
                            </p>
                        </span>
                        {
                            !!(work.price) &&
                            (
                                (
                                    <span className='card-priceWdiscount ellipsis'>
                                        <h6 style={{ fontSize: '17px' }}>{`R$ ${work.price && money(work.price)}`}</h6>
                                    </span>
                                )
                            )
                        }

                        <span className='card-b-end' style={{ justifyContent: 'flex-start', width: '86%', height: '28px', display: 'flex', alignItems: 'center', overflow: 'hidden' }}>
                            <div style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'flex-start', flexDirection: 'row', overflow: 'hidden', alignItems: 'center', position: 'relative' }}>
                                <span style={{ height: '100%', padding: '0 10px' }} className='center'>
                                    <IoLocationSharp color='#646464' />
                                </span>
                                <p className='card-b-user-name'>{work.city}</p>
                            </div>

                        </span>
                        <button id="Card-contact-btn">Saiba mais</button>
                    </div>
                </div>
            </Link >
        </div >
    )
}



export default Card