import translations_ from "../interfaces/translations.interface";
import lang from "../languages/lang";

interface tx_ {
    central: [
        string, string, string
    ],
    search: [
        string, string
    ]
}

const def_tx: translations_<tx_> = {
    "pt-BR": {
        central: [
            'Preciso de ajuda',
            'Gerenciar minhas informações',
            'Atendimento'
        ],
        search: [
            ' na sua região',
            'Melhores profissionais da sua região'
        ]
    },
    "en-US": {
        central: [
            'I need help',
            'Manage my information',
            'Support'
        ],
        search: [
            ' in your region',
            'Best professionals in your region'
        ]
    }
};

const tx = def_tx[lang];
export default tx;