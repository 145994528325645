import currency from "../../../../functions/currency"
import tx from "../translation"

function WcTaxs({ price, p }: { price: number, p?: string }) {
    return (
        <>
            {
                Boolean(price) &&
                <div
                    style={{
                        height: '48px',
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                >
                    <p
                        style={{
                            margin: '0 0 3px 0',
                            fontSize: '11px',
                            opacity: .6
                        }}
                    >
                        {p ? p : tx.taxs[0]}
                    </p>
                    <p
                        style={{
                            margin: 0,
                            fontSize: '16px'
                        }}
                    >
                        <strong
                            style={{ color: 'var(--green)' }}
                        >
                            {currency((price / 100) * 90)}
                        </strong>
                        {' (- 10%)'}
                    </p>
                    <p
                        style={{
                            margin: 0,
                            fontSize: '11px',
                            opacity: .6
                        }}
                    >
                        {tx.taxs[1]}
                    </p>
                </div>
            }
        </>
    )
}

export default WcTaxs