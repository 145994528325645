import translations_ from "../../../../interfaces/translations.interface";
import lang from "../../../../languages/lang";

interface i_ {
    label: string
    placeholder: string
}

type tx_ = [
    string, string, string, string,
    i_, i_, i_, i_

]

const def_tx: translations_<tx_> = {
    "pt-BR": [
        'Todos os campos obrigatórios(*) devem ser preenchidos',
        'Formato de email invalido',
        'Aguarde, responderemos o mais rápido possível.',
        'Como podemos ajudar?',
        {
            label: "Nome*",
            placeholder: "Nome Completo"
        },
        {
            label: "E-mail*",
            placeholder: "exemplo@email.com"
        },
        {
            label: "WhatsApp (opcional)",
            placeholder: "11 9 9988-7766"
        },
        {
            label: "Descreva o problema*",
            placeholder: "Conte-nos o que aconteceu..."
        }
    ],
    "en-US": [
        'All required fields (*) must be filled in',
        'Invalid email format',
        'Please wait, we will respond as soon as possible.',
        'How can we help?',
        {
            label: "Name*",
            placeholder: "Full Name"
        },
        {
            label: "E-mail*",
            placeholder: "example@email.com"
        },
        {
            label: "WhatsApp (optional)",
            placeholder: "+44 20 1234 5678"
        },
        {
            label: "Describe the problem*",
            placeholder: "Tell us what happened..."
        }
    ]
};

const tx = def_tx[lang];
export default tx;