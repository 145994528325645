import translations_ from "../../interfaces/translations.interface";
import lang from "../../languages/lang";

type tx_ = [
    string, string, string, string, string,
    string, string, string, string, string,
    string
]

const def_tx: translations_<tx_> = {
    "pt-BR": [
        'Por favor informe um e-mail válido',
        'Falha ao enviar email, tente novamente mais tarde',
        'redefinir senha',
        'E-mail enviado',
        'E-mail enviado para',
        'com as instruções e o link para você trocar a senha. se você não receber o e-mail em alguns minutos, verifique a sua caixa de spam ou tente novamente.',
        'Redefinir senha',
        'Identifique-se para receber um e-mail com as instruções e o link para criar uma nova senha.',

        'E-mail',
        'exemplo@email.com',
        'Enviar'

    ],
    "en-US": [
        'Please enter a valid email address',
        'Failed to send email, please try again later',
        'reset password',
        'Email sent',
        'Email sent to',
        'with instructions and a link to change your password. If you don\'t receive the email within a few minutes, check your spam folder or try again.',
        'Reset password',
        'Log in to receive an email with instructions and a link to create a new password.',
        'Email',
        'example@email.com',
        'Send'
    ]
};

const tx = def_tx[lang];
export default tx;