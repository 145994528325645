import './styles.css'

interface IModal {
    children: JSX.Element,
    width?: string | number,
    height?: string | number,
    visible: boolean,
    setVisible: (value: boolean) => void,
    padding?: string,
    closeBtn?: boolean
}

function Modal({ children, visible = false, setVisible, width, height, padding = '80px 100px', closeBtn = true }: IModal) {
    return (
        <div id='Modal' style={{
            display: visible ? 'flex' : 'none'
        }}>
            {
                visible &&
                <>
                    <div
                        className='Modal-closeable'
                        onClick={() => setVisible(false)}
                    />
                    <div className='Modal-white' style={{
                        width, height, padding
                    }}>
                        {
                            closeBtn &&
                            <div
                                className='Modal-close-btn'
                                onClick={
                                    () =>
                                        setVisible(false)
                                }
                            />
                        }
                        {children}
                    </div>
                </>
            }
        </div>
    )
}

export default Modal