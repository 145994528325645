import { IoOpenOutline, IoSearchOutline } from 'react-icons/io5';
import country_selector_list, { country_selector_list_ } from '../../../languages/country_selector';
import '../styles.css';
import tx from '../translation';
import { useState } from 'react';
import lang from '../../../languages/lang';

interface CountrySelectorList_ {
    setSelected: (c: country_selector_list_) => void
}

export default function CountrySelectorList({ setSelected }: CountrySelectorList_) {
    const [query, setQuery] = useState<string>('');
    return (
        <div className="CountrySelectorList center column">

            <div className='CountrySelectorListH'>
                <div className='cslh_top'>
                    <div>
                        <strong>
                            {tx[0]}
                        </strong>
                    </div>
                    <div className='center'>

                    </div>
                </div>
                <div className='cslh_bottom center'>
                    <form>
                        <span className='center'>
                            <IoSearchOutline />
                        </span>
                        <input
                            value={query}
                            onChange={e => setQuery(e.target.value)}
                            placeholder={tx[1]}
                        />
                    </form>
                </div>

            </div>
            <ul>
                {
                    country_selector_list.flat(2).filter(s => s.reg.toLowerCase().normalize("NFD").includes(query)).map(
                        (it, idx) => (
                            <li key={idx}>
                                <button
                                    onClick={() => setSelected(it)}
                                >
                                    <div className='CountrySelectorList_button'>
                                        <div className='cselec_flag'>
                                            <img
                                                alt='National Flag'
                                                src={require('../../../assets/flags/' + it.cod_int + '.webp')}
                                            />
                                        </div>
                                        <div>
                                            <p className='cselec_p'>{it.reg}</p>
                                        </div>
                                    </div>
                                    <span />
                                </button>
                            </li>
                        )
                    )
                }
            </ul>
        </div>
    )
}
