//Components:
import SubHeader from './nested/SubHeader';
import HIconsFixed from './nested/HIconsFixed';
import SideBar from '../../SideBar';

import './styles.css';
import { IoSearch } from 'react-icons/io5'
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Location from '../../Locations/Location';

import { userData_, HeaderWithoutChat_ } from '../headers.interfaces';
import GoBack from '../../mobileComponents/GoBack';
import tx from './translation';

function HeaderWithoutChat({
  home = false,
  switcher,
  setSwitcher,
}: HeaderWithoutChat_) {
  const navigate = useNavigate();

  const [userData, setUserData] = useState<userData_>({})
  const [scrolling, setScrolling] = useState<boolean>(false);
  const [sideBarIsVisible, setSideBarIsVisible] = useState<Array<any>>([false]);
  const [locationIsVisible, setLocationIsVisible] = useState<boolean>(false);
  const [value, setValue] = useState<string>('')

  const handleSideBar = (bool: any) => {
    window.innerWidth <= 960
      ? navigate('/menu')
      : setSideBarIsVisible([bool])
  }

  const OpenCatSideBar = () => {
    setSideBarIsVisible([true, 'categories'])
  }

  function handleScroll() {
    if (window.scrollY > 40) {
      setScrolling(true)
    } else {
      setScrolling(false)
    }
  }

  window.addEventListener('scroll', handleScroll)

  const handleSearch = (e: any) => {
    e.preventDefault();
    window.sessionStorage.setItem('serss-search', value);
    navigate(`/search?search=${value}`);
  }

  useEffect(() => {
    let storage = JSON.parse(window.localStorage.getItem('serss-user') || '{}')
    if (typeof storage === 'object') setUserData(storage)

    return window.removeEventListener('scroll', handleScroll)
  }, [])
  return (
    <div className={
      `Header-Container 
      ${(home && !scrolling && window.innerWidth <= 960)
        ? ''
        : 'H-Shadow '}`
    }>
      {
        sideBarIsVisible[0] &&
        <SideBar
          setSideBarIsVisible={handleSideBar}
          setLocationIsVisible={setLocationIsVisible}
          type={sideBarIsVisible[1] && sideBarIsVisible[1]}
        />
      }
      <Location
        locationIsVisible={locationIsVisible}
        setLocationIsVisible={setLocationIsVisible}
        switcher={switcher}
        setSwitcher={setSwitcher}
      />
      <div id="Header">
        <div className='h-center'>
          <div className='h-c-left'>

            <div className='h-c-l-img-wrap'>
              {!home && <GoBack />}
              <Link to='/'>
                <img
                  className='h-c-l-img'
                  src={require('../../../assets/logo.webp')}
                  alt=''
                />
              </Link>
              <div className='h-c-l-title'>
                <Link to='/'><h1>Servicess</h1></Link>
              </div>
            </div>
          </div>
          <div className='h-c-mid'>
            <div className='h-c-mid-form-wrap'>
              <form className='h-c-mid-form' onSubmit={handleSearch}>
                <input
                  className='h-c-mid-input'
                  placeholder={tx.header_input}
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                />
                <button type='submit' className='h-c-mid-serach-button'>
                  <IoSearch />
                </button>
              </form>
            </div>
          </div>
          <div className='h-c-right'>
            <HIconsFixed
              toOrders={() => navigate('/orders')}
              setSideBarIsVisible={handleSideBar}
              uid={userData.uid}
            />
          </div>
        </div>
      </div>
      {!scrolling && <SubHeader
        setSideBarIsVisible={handleSideBar}

        OpenCatSideBar={OpenCatSideBar}
        uid={userData.uid}
      />}
    </div>
  );
}

export default HeaderWithoutChat;
