import { useEffect, useState } from 'react';
import AvatarPlaceholder from '../AvatarPlaceholder';
import {getFire} from '../../../functions/getFire';

interface IAvatar {
    uid: any,
    size?: number,
    handleOnAvatarError?: () => void,
    borderRadius?: string,
    placeholder?: 'transparent'
}
function Avatar({ uid, size = 40, handleOnAvatarError, borderRadius = '100%', placeholder }: IAvatar) {

    const [avatar, setAvatar] = useState<string>('')

    const handleAvatar = () => {
        if (uid)
            getFire(`${uid}/${uid}.webp`, setAvatar)
    }

    useEffect(() => {
        handleAvatar()
    }, [uid]);

    return (
        <span
            style={{
                position: 'relative',
                width: '100%',
                height: '100%'
            }}
            className='center'
        >
            {
                avatar &&
                <img
                    style={{
                        zIndex: 1,
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        top: 0,
                        left: 0,
                        borderRadius
                    }}
                    src={avatar.toString()}
                    alt=''
                    onError={() => {
                        handleOnAvatarError && handleOnAvatarError()
                        setAvatar('')
                    }}
                />
            }
            <AvatarPlaceholder
                size={size}
                backgroundColor={placeholder}
                borderRadius={borderRadius}
            />
        </span>
    )
}

export default Avatar