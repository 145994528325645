import { useEffect, useState } from 'react'
import bannersStorage from '../../../constants/firebase/bannersStorage'
import './styles.css'
import { getFireSingle } from '../../../functions/getFire'

interface Banner_ {
    uid?: any,
    wid?: any,
    current?: string,
    type?: 'default' | 'main' | 'details',
    handleOnBannerError?: () => void,
    link?: string,

    idx?: undefined | number,
    hideWrapper?: any,

}
function Banner(
    { link = '', uid, wid,
        type = 'default',
        handleOnBannerError,
        idx, hideWrapper, current
    }: Banner_
) {

    const [banner, setBanner] = useState<string | false>(false);

    const handleBanner = () => {
        if (uid && wid)
            getFireSingle(`${uid}/${wid}`, setBanner)
        else if (link)
            setBanner(link)
    }

    useEffect(() => {
        handleBanner()
    }, [uid, link]);

    return (
        <>
            {
                (banner && typeof banner === 'string') &&
                <>
                    <div
                        className={`banners-image-wrap center ${type !== 'default' && 'banners-image-wrap-' + type}`}
                    >
                        <img
                            alt=''
                            className={`banners-img ${type !== 'default' && 'banners-img-' + type}`}
                            src={banner}
                        />
                    </div>
                    <img
                        alt=''
                        className='banners-blur'
                        src={banner}
                        onError={() => {
                            handleOnBannerError
                                && handleOnBannerError()
                            setBanner(false)
                            hideWrapper && hideWrapper()
                        }}
                    />
                </>
            }
        </>
    )
}

export default Banner;