import translations_ from "../../../interfaces/translations.interface";
import lang from "../../../languages/lang";

interface tx_ {
    s_r_l: string,
    cats: {
        main: string,
        all: string,
        list: [string, string, string]
    },
    header_input: string
}

const def_tx: translations_<tx_> = {
    "pt-BR": {
        s_r_l: 'Cadastrar ou entrar',
        cats: {
            main: 'Principais Categorias',
            all: 'Todos os serviços',
            list: [
                'Pintor', 'Designer', 'Diarista'
            ]
        },
        header_input: 'Pesquise por serviços ou profissionais'
    },
    "en-US": {
        s_r_l: 'Register or Login',
        cats: {
            main: 'Main Categories',
            all: 'All services',
            list: [
                'Painter', 'Designer', 'diarist'
            ]
        },
        header_input: 'Search for services or professionals'
    }
};

const tx = def_tx[lang];
export default tx;