interface User_ {
    avatar: boolean,
    description: string,
    name: string,
    phone: string,
    profession: string,
    token: string,
    uid: string
}

export const getUser = (): User_ => JSON.parse(window.localStorage.getItem('serss-user') || '{}');
export const getToken = (): User_['token'] => JSON.parse(window.localStorage.getItem('serss-user') || '{}').token;


