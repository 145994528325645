import translations_ from "../../../interfaces/translations.interface";
import lang from "../../../languages/lang";

interface tx_ {
    keep: {
        p: string,
        strong: string
    },
    conft: string,
    talk: string,
    no_acc: {
        p: string,
        strong: string
    },
    first: string,
    here: string,
    here_too: string,
    expl: string,
    nav_bar: [
        string, string
    ]
}

const def_tx: translations_<tx_> = {
    "pt-BR": {
        keep: {
            p: 'Acompanhe os ',
            strong: ' profissionais que você contratou.'
        },
        conft: 'Confirmar e Liberar Pagamento',
        talk: 'Conversar',
        no_acc: {
            p: ' Não tem conta? ',
            strong: ' Crie uma agora. '
        },
        first: 'Contrate pela primeira vez',
        here: 'Quando você contrata um serviço pela primeira vez, ele aparece aqui.',
        here_too: 'Quando você é contratado, seus clientes também aparecem aqui.',
        expl: 'Explore serviços',
        nav_bar: [
            "Compras",
            "Vendas"
        ]
    },
    "en-US": {
        keep: {
            p: 'Keep track of the ',
            strong: ' professionals you have hired.'
        },
        conft: 'Confirm and Release Payment',
        talk: 'Chat',
        no_acc: {
            p: ' Não tem conta? ',
            strong: ' Crie uma agora. '
        },
        first: 'Hire for the first time',
        here: 'When you purchase a service for the first time, it appears here.',
        here_too: 'When you get hired, your clients will appear here too.',
        expl: 'Explore services',
        nav_bar: [
            "My purchases",
            "My sales"
        ]
    }

};

const tx = def_tx[lang];
export default tx;