import translations_ from "../../interfaces/translations.interface";
import lang from "../../languages/lang";

type tx_ = [
    string, string
]

const def_tx: translations_<tx_> = {
    "pt-BR": [
        'Cancelar',
        'Sim'
    ],
    "en-US": [
        'Cancel',
        'Yes'
    ]
};

const tx = def_tx[lang];
export default tx;