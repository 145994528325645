import {
    IoAddCircleOutline, IoCalendarOutline,
    IoCreateOutline, IoListOutline,
    IoLocationOutline, IoNewspaperOutline
} from 'react-icons/io5'
import { Link } from 'react-router-dom';
import '../styles.css'
import { getUser } from '../../../constants/localStorage/User';
import Avatar from '../../imagesComponents/Avatar';
import ShLi from './ShLi';
import { useState } from 'react';
import ShLiMainCat from './ShLiMainCat';
import scheduled_services from '../../../languages/scheduled_services'
import tx from '../translation';

interface SideBarContent_ {
    Button?: JSX.Element,

    makeLocVisible?: (b: boolean) => void,
    type?: 'default' | 'categories' | 'works'
}

function SideBarContent(
    { Button, makeLocVisible, type }:
        SideBarContent_
) {
    const User = getUser();
    const [switcher, setSwitcher] =
        useState<SideBarContent_['type']>(type || 'default');

    return (
        <div style={{ backgroundColor: '#fff' }}>
            {Button && Button}
            {switcher === 'default' &&
                <div>
                    <ul className='SB-list'
                    >
                        <li className='SB-list-li-user'
                        >
                            <div className='SB-list-li-user-container'
                            >
                                <div className='SB-list-li-user-info'
                                >
                                    <div
                                        style={{
                                            position: 'relative',
                                            width: '60px',
                                            height: '60px'
                                        }}
                                    >
                                        <Avatar
                                            uid={User.uid}
                                            size={16}
                                            borderRadius='12px'
                                        />
                                    </div>
                                    <span className='SB-list-li-user-text'
                                    >
                                        {
                                            User.name
                                                ? (
                                                    <>
                                                        <h5>{
                                                            User.profession}
                                                        </h5>
                                                        {User.name}
                                                    </>

                                                )
                                                : (
                                                    <>
                                                        {tx[1]}
                                                        <br />
                                                        {tx[2]}
                                                    </>
                                                )
                                        }
                                    </span>
                                </div>
                                <div className='SB-list-li-user-buttons'
                                >
                                    {
                                        User.name
                                            ? (

                                                <Link
                                                    to='/work/create'
                                                    className='SB-list-li-user-btn-login SB-btn'
                                                    style={{ width: '100%' }}
                                                >
                                                    {tx[3]}
                                                </Link>

                                            )
                                            : (
                                                <>
                                                    <Link
                                                        to='/login'
                                                        className='SB-list-li-user-btn-login SB-btn'
                                                    >
                                                        {tx[4]}
                                                    </Link>
                                                    <Link
                                                        to='/signin'
                                                        className='SB-list-li-user-btn-signin SB-btn'
                                                    >
                                                        {tx[5]}
                                                    </Link>
                                                </>
                                            )
                                    }
                                </div>
                            </div>
                        </li>
                        <div>
                            <div>
                                <Link 
                                to={
                                    User.uid 
                                    ? '/orders' 
                                    : '/login'
                                }
                                >
                                    <ShLi
                                        text={scheduled_services}
                                        borderless
                                    >
                                        <IoCalendarOutline />
                                    </ShLi>

                                    <div>
                                        <ShLi
                                            borderless
                                            text={tx[6]}
                                        />
                                        <ShLi
                                            borderless
                                            text={tx[7]}
                                        />
                                    </div>
                                    <div
                                     className='SB-li'
                                      style={{ height: 0 }} 
                                      />
                                </Link>
                            </div>
                            <Link
                                to={
                                    User.uid
                                        ? '/work/create'
                                        : '/login'}
                            >
                                <ShLi
                                    text={tx[8]}
                                >
                                    <IoAddCircleOutline />
                                </ShLi>
                            </Link>
                            {
                                <Link
                                    to={
                                        User.uid
                                            ? '/cards/belongs'
                                            : '/login'
                                    }
                                >
                                    <ShLi
                                        text={tx[9]}
                                    >
                                        <IoNewspaperOutline />
                                    </ShLi>
                                </Link>

                            }
                            <Link
                                to={
                                    User.uid
                                        ? '/registration'
                                        : '/login'
                                }
                            >
                                <ShLi
                                    text={tx[10]}
                                >
                                    <IoCreateOutline />
                                </ShLi>
                            </Link>
                            {makeLocVisible && 
                            <ShLi
                                text={tx[11]}
                                onClick={makeLocVisible}
                            >
                                <IoLocationOutline />
                            </ShLi>}
                            <ShLi
                                text={tx[12]}
                                onClick={() => setSwitcher('categories')}
                            >
                                <IoListOutline />
                            </ShLi>

                        </div>
                    </ul>
                </div>
            }
            {
                switcher === 'categories' &&
                <ShLiMainCat setSwitcher={setSwitcher} />
            }
        </div>
    )
}

export default SideBarContent