import translations_ from "../../../interfaces/translations.interface";
import lang from "../../../languages/lang";

type tx_ = [
    string, string, string, string, string
]

const def_tx: translations_<tx_> = {
    "pt-BR": [
        'Senha alterada com sucesso!',
        'Redifinir Senha',
        'crie uma nova senha com no mínimo 8 caracteres',
        'Nova Senha',
        'Alterar'
    ],
    "en-US": [
        'Password changed successfully!',
        'Reset Password',
        'create a new password with at least 8 characters',
        'New Password',
        'Change'
    ],
};

const tx = def_tx[lang];
export default tx;