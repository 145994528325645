import GreenBorderButton from "../../../Buttons/GreenBorderButton"
import Modal from "../../../Modal"
import tx from "../translation";

function ImageChangedMessage(
    { visible, setVisible, avatar }: { visible: boolean, setVisible: any, avatar?: JSX.Element }
) {

    const handleClick = (e: Event) => {
        e.preventDefault();
        setVisible(false);
    }

    return (
        <Modal
            visible={visible}
            setVisible={setVisible}
            closeBtn={false}
            padding={'80px  60px'}
            children={
                <div
                    style={{
                        textAlign: 'center',
                        maxWidth: '400px',
                        padding: '0 40px'
                    }}
                    className='center column'
                >
                    {avatar && avatar}
                    <strong
                        style={{
                            marginTop: '50px',
                            fontSize: '1.2em'
                        }}
                    >
                        {
                            avatar
                                ? tx.pics.perfil
                                : tx.pics.imagens
                        }
                    </strong>
                    <p style={{ color: '#00000090' }}>
                        {tx.await}
                    </p>
                    <GreenBorderButton
                        onClick={handleClick}
                        text={tx.btn}
                    />
                </div>
            }
        />
    )
}

export default ImageChangedMessage