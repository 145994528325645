import { useState } from 'react'
import api from '../../config/connection/axios'
import regex from '../../functions/regex'
import FormButton from '../Form/Inputs/nested/FormButton'
import Input from '../Form/Inputs/nested/Input'

import Modal from '../Modal'
import './styles.css'
import tx from './translation'


interface ISendResetPassword {
    email: string,
    setEmail: (value: string) => void
}

function SendResetPassword(
    { email, setEmail }:
        ISendResetPassword
) {

    const [sent, setSent] = useState<string>('')
    const [spinner, setSpinner] = useState<boolean>(false)
    const [visible, setVisible] = useState<boolean>(false)

    const handleResetPassword = async (e: any, alertfy: any) => {
        e.preventDefault()

        if (!regex.email.test(email.trim()))
            return alertfy(tx[0])

        setSpinner(true)

        await api.get(`/code/generate/utoken/${email}`)
            .then(() => {
                setSpinner(false)
                setSent(email)
            })
            .catch(() => {
                setSpinner(false)
                alertfy(tx[1])
            })
    }

    return (
        <div>
            <p
                className='SRP-btn'
                onClick={() =>
                    setVisible(true)}
            >
                {tx[2]}
            </p>
            <Modal
                visible={visible}
                setVisible={setVisible}
                children={
                    sent
                        ? <div style={{ width: '400px' }}>
                            <strong className='SRP-stron'>
                                {tx[3]}
                            </strong>
                            <p className='SRP-text'>
                                {`${tx[4]} ${email} ${tx[5]}`}
                            </p>
                        </div>
                        : <form style={{ width: '400px' }}>
                            <strong className='SRP-stron'>{tx[6]}</strong>
                            <p className='SRP-text'>{tx[7]}</p>
                            <Input
                                label={tx[8]}
                                placeholder={tx[9]}
                                value={email}
                                onChange={setEmail}
                            />
                            <FormButton
                                text={tx[10]}
                                onClick={handleResetPassword}
                                spinner={spinner}
                            />
                        </form>
                }
            />
        </div>
    )
}

export default SendResetPassword