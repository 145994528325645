function hideContactsDescription(text: string | null) {
    if (typeof text !== 'string') return text;

    const regexNumbers = /\d+/g;
    const regexPalavras = /\b(instagram|facebook|twitter|getninja|twitter|@|gmail.com|outlook.com|hotmail.com|yahoo.com|zap|whatsapp|telegram)\b/gi;

    const textWithoutNumbers = text.replace(regexNumbers, (match) => {
        return "*".repeat(match.length);
    });

    return textWithoutNumbers.replace(regexPalavras, "*");
}

export default hideContactsDescription;