
import { IoCalendarClear, IoChatbox, } from 'react-icons/io5'
import { SocList_ } from "../../interface"
import Avatar from '../../../../imagesComponents/Avatar'

import SocNavBar from '../SocNavBar'

import SafeDiv from '../../../../SafeDiv'
import { useNavigate } from 'react-router-dom'
import tx from '../../translation'

interface SocListCard_ {
    list: SocList_
}
interface OrdersList_ {
    listData: Array<SocList_>
    switcher: any,
    setSwitcher: any
}

function OrdersList(
    { listData, switcher, setSwitcher }:
        OrdersList_
) {
    return (
        <SafeDiv>
            <div className='center'>
                <div className='orders-resize'>
                    <div
                        className="center column"
                        style={{ marginBottom: '30px' }}
                    >
                        <div style={{ width: '90%' }}>
                            <div
                                className='center soc-title-pro'
                                style={{
                                    borderRadius: '100px',
                                    width: 'fit-content',
                                    marginBottom: '8px',
                                }}
                            >
                                <p>{tx.keep.p} <strong> {tx.keep.strong}</strong></p>
                            </div>
                        </div>
                        <SocNavBar setSwitcher={setSwitcher} switcher={switcher} />
                    </div>
                    <div className='center'>
                        <div style={{ width: '96%' }}>
                            {
                                listData.map((it: SocList_, idx) => {
                                    return (
                                        <SocListCard
                                            key={idx}
                                            list={it}

                                        />

                                    )

                                })
                            }
                        </div>
                    </div>
                    <div style={{ height: '110px' }} />
                </div>
            </div>
        </SafeDiv>


    )
}

const SocListCard = ({
    list
}: SocListCard_) => {
    const navigate = useNavigate();
    const handleOpenChat = () => {
        navigate("/chat", {
            state: {
                name: list.original_subwork_title,
                uid: list.provider_professional_uid
            }
        });
    };
    return (
        <div

            className='soc-white-card-wrap card156 center'
            style={{
                position: 'relative',
                top: 0,
                marginBottom: '50px',
                textAlign: 'start'
            }}
        >
            <div className='soc-white-card'>
                <div className='soc-white-card-container'>
                    <div className='soc-w-top'>
                        <div className='soc-w-right'>
                            <h3 className='soc-h3 ellipsis'>
                                {list.original_subwork_title}
                                </h3>
                            <p className='description-ellipsis soc-p'>
                                {list.profession}
                            </p>
                        </div>
                        <div className='soc-w-left'>
                            <div className='soc-w-avatar-wrap'>
                                <Avatar
                                    uid={list.provider_professional_uid}
                                    size={14} borderRadius='18px'
                                />
                            </div>
                        </div>
                    </div>
                    <div className='soc-w-bottom soc-w-bottom-b-b'>
                        <div className="center">
                            <span 
                            className="center" 
                            style={{ marginRight: '10px' }}
                            >
                                <IoCalendarClear size={20} />
                            </span>
                            <strong
                                style={{
                                    textTransform: 'capitalize',
                                    fontSize: '16px'
                                }}
                            >
                                {
                                `${list.execution_date}`
                                .slice(0, 10)
                                .replaceAll('-', '/')
                                }
                            </strong>
                        </div>
                    </div>

                    <div style={{ marginBottom: '10px' }}>
                        <button
                            type='button'
                            onClick={() => { }}
                            className='center'
                            style={{
                                border: '1px solid var(--dark)',
                                borderRadius: '8px',
                                height: '45px',
                                width: '100%',
                                pointerEvents: 'none',
                                opacity: .4
                            }}>
                            <p
                                style={{
                                    fontWeight: 600,
                                    color: 'var(--dark)'
                                }}
                            >
                                {tx.conft}
                            </p>
                        </button>
                    </div>
                    <div>
                        <button
                            onClick={handleOpenChat}
                            className='center'
                            style={{
                                backgroundColor: 'var(--dark)',
                                borderRadius: '8px',
                                height: '45px',
                                width: '100%'
                            }}>
                            <div
                                className='center'
                                style={{ marginRight: '6px' }}
                            >
                                <IoChatbox size={16} color='white' />
                            </div>
                            <p
                                style={{
                                    fontWeight: 600,
                                    color: 'white'
                                }}
                            >
                                {tx.talk}
                            </p>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OrdersList;