import { getUser } from "../constants/localStorage/User";
import { removeNotDigitsFromString } from "./onlyDigits";

const setLocalStoragePhone = (phone: string) => {
    const u = getUser();
    window.localStorage.setItem('serss-user', JSON.stringify(
        {
            ...u,
            phone: removeNotDigitsFromString(phone)
        }
    ))

    return;
};

export default setLocalStoragePhone;