import { IoChatbubble } from "react-icons/io5"
import Icon from "../../IconMarginRight"
import { Link } from "react-router-dom"
import tx from "./tx/translations"

function DoubtsButton() {
    return (
        <button style={{ width: '210px' }}>
            <Link
                to='/central'
                style={{ height: '100%', color: 'white', fontSize: '12px' }}
            >
                <p className='center' style={{ margin: 0 }}>
                    {tx}
                    <Icon style={{ paddingLeft: '8px' }}>
                        <IoChatbubble size={16} />
                    </Icon>
                </p>
            </Link>
        </button>
    )
}

export default DoubtsButton