import translations_ from "../../../interfaces/translations.interface";
import lang from "../../../languages/lang";

interface i_ {
    h4: string,
    p: string
}
type tx_ = [i_, i_, i_, i_, i_]

const def_tx: translations_<tx_> = {
    "pt-BR": [
        {
            h4: 'Rápido',
            p: 'procurou, achou!',
        },
        {
            h4: 'Atendemos',
            p: 'todo o Brasil'
        },
        {
            h4: 'Novos serviços',
            p: 'todos os dias'
        },
        {
            h4: 'Segurança',
            p: 'de ponta a ponta'
        },
        {
            h4: 'Todos os profissionai',
            p: 'na palma da sua mão'
        }
    ],
    "en-US": [
        {
            h4: 'Fast',
            p: 'searched, found!',
        },
        {
            h4: 'We serve',
            p: 'the whole world'
        },
        {
            h4: 'New services',
            p: 'every day'
        },
        {
            h4: 'End-to-end',
            p: 'security'
        },
        {
            h4: 'All professionals',
            p: 'in the palm of your hand'
        }
    ]
};

const tx = def_tx[lang];
export default tx;