import { IoCalendarOutline, IoChatboxOutline, IoDocumentTextOutline, } from 'react-icons/io5'
import { SocList_ } from "../../interface"

import Avatar from '../../../../imagesComponents/Avatar'
import SocNavBar from '../SocNavBar'

import currency from '../../../../../functions/currency'
import SafeDiv from '../../../../SafeDiv'
import WcTaxs from '../../../WorkCreateContainer/nested/WcTaxs'
import { useNavigate } from 'react-router-dom'

interface SocListCard_ {
  list: SocList_

}

interface OLP_ {
  listData: Array<SocList_>
  switcher: any,
  setSwitcher: any
}

function OrdersListProf({ listData, switcher, setSwitcher }: OLP_) {
  return (
    <SafeDiv>
      <div className='center'>
        <div className='orders-resize'>
          <div className="center column" style={{ marginBottom: '30px' }}>
            <div style={{ width: '90%' }}>
              <div
                className='center soc-title-pro'
                style={{
                  borderRadius: '100px',
                  width: 'fit-content',
                  marginBottom: '8px',
                }}
              >
                <p>Acompanhe os <strong>serviços que você vendeu.</strong></p>
              </div>
            </div>
            <SocNavBar setSwitcher={setSwitcher} switcher={switcher} />
          </div>
          <div className='center'>
            <div style={{ width: '96%' }}>
              {
                listData.map((it: SocList_, idx) => {
                  return (<SocListCard
                    key={idx}
                    list={it}
                  />)
                })
              }
            </div>
          </div>
          <div style={{ height: '110px' }} />
        </div>
      </div>
    </SafeDiv>



  )
}

const SocListCard = ({
  list
}: SocListCard_) => {
  const navigate = useNavigate();
  const handleOpenChat = () => {
    navigate("/chat", {
      state: {
        name: list.name,
        uid: list.payer_customer_uid
      }
    });
  };
  return (
    <div
      className='soc-white-card-wrap card156 center'
      style={{
        position: 'relative',
        top: 0,
        marginBottom: '50px',
        textAlign: 'start'
      }}
    >
      <div className='soc-white-card'>
        <div className='soc-white-card-container'>
          <div className='soc-w-top column'>
            <div className='center'>
              <div
                className='soc-w-avatar-wrap'
                style={{
                  height: '90px',
                  width: '90px',
                  marginBottom: '15px',
                  borderRadius: '100%',
                  position: 'relative'
                }}
              >
                <Avatar
                  uid={list.payer_customer_uid}
                  size={14} borderRadius='100%'
                />
              </div>
            </div>
            <div className='center column'>
              <p className='description-ellipsis soc-p' style={{ marginBottom: '5px' }}>
                Cliente:
              </p>
              <h3 className='soc-h3 ellipsis'>{list.name}</h3>
            </div>
          </div>
          <div className='soc-w-bottom'>
            <div className="center">
              <span className="center" style={{ marginRight: '10px' }}>
                <IoCalendarOutline size={20} />
              </span>
              <p
                style={{ textTransform: 'capitalize' }}
              >
                {`${list.execution_date}`.slice(0, 10).replaceAll('-', '/')}
              </p>
            </div>
          </div>
          {<div className='soc-w-bottom soc-w-bottom-b-b'>
            <div style={{ width: '100%' }}>
              <div style={{ width: '100%', display: 'flex', marginBottom: '18px' }}>
                <div style={{ width: '100%' }} className="center">
                  <span className="center" style={{ marginRight: '10px' }}>
                    <IoDocumentTextOutline size={20} />
                  </span>
                  <div
                    style={{
                      flex: 1,
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center'
                    }}
                  >
                    <p>Valor a receber:</p>
                    {
                      list.transaction_amount &&
                      <WcTaxs
                        p={currency(list.transaction_amount)}
                        price={list.transaction_amount}
                      />
                    }
                  </div>
                </div>
              </div>
              <div>
                <div style={{ marginTop: '20px' }}>
                  <p>
                    O valor pago será liberado entre <strong> 7 - 15 dias</strong> após a confirmação do serviço.
                  </p>
                </div>

              </div>
            </div>
          </div>}

          <button
            type='button'
            onClick={handleOpenChat}
          >
            <div className='center' style={{
              height: '45px'
            }}>
              <div
                className='center'
                style={{ marginRight: '6px' }}
              >
                <IoChatboxOutline size={16} color='var(--dark)' />
              </div>
              <p
                style={{
                  fontWeight: 600,
                  color: 'var(--dark)'
                }}
              >
                Entrar em contato com o cliente
              </p>
            </div>
          </button>
        </div>
      </div>
    </div>
  )
}

export default OrdersListProf;