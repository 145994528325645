import { useState } from "react";
import { IoEye, IoEyeOff } from "react-icons/io5";
import { maskPhone } from "../../../../functions/masks";
import ClearButton from "../../../Buttons/ClearButton";
import { Input_ } from "../inputs.interfaces";

import '../styles.css';

export default function Input({
    label, placeholder, type,
    space, onChange, value,
    mask, cap, style, max, min, clear,
    required, maxlength
}: Input_) {
    const [T, setT] = useState(type)

    const changeVision = () => {
        setT(T === 'password' ? '' : 'password');
    }

    return (
        <label
            style={{
                marginTop: `${space !== undefined ? space : 18}px`
            }}
            className='Label'
        >
            <p>
                {label}&nbsp;
                {
                    required &&
                    <strong style={{ color: 'tomato' }}>
                        *
                    </strong>
                }
            </p>
            <input
                autoComplete="off"
                style={{
                    textTransform: cap ? 'capitalize' : 'none',
                    paddingRight: type === 'Date' ? '10px' : 'auto',
                    ...style
                }}
                className='I Input'
                placeholder={placeholder}
                spellCheck={false}
                type={T}
                value={value}
                maxLength={maxlength && maxlength}
                max={max && max}
                min={min && min}
                onChange={
                    mask === 'phone'
                        ? (e) => onChange(maskPhone(e.target.value))
                        : (e) => onChange(e.target.value)
                }
            />
            {type === 'password' &&
                (
                    <span className='I Eye' onClick={changeVision}>
                        {T === 'password' ? <IoEye /> : <IoEyeOff />}
                    </span>
                )
            }

            {(clear && value) && (
                <ClearButton handleClear={onChange} />
            )}
        </label>
    )
}