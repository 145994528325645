import './styles.css'
import tx from './translation'

function DownApp() {
    return (
        <div className='center' id='DownApp'>
            <div>
                <img 
                className='down-tablet' 
                src={require('../../../assets/down.webp')} 
                alt='' 
                />
            </div>
            <div className='down-middle'>
                <h2>{tx.h2}</h2>
                <span>{tx.span}</span>
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-around',
                        marginTop: '60px'
                    }}
                >
                    <a
                        href='https://apps.apple.com/br/app/servicess-encontre-servi%C3%A7os/id1641960454'
                    >
                        <img
                            className='down-store'
                            src={require('../../../assets/appstore.webp')}
                            alt=''
                        />
                    </a>
                    <a
                        href='https://play.google.com/store/apps/details?id=servicess.app.mobile'
                    >
                        <img
                            className='down-store'
                            src={require('../../../assets/googleplay.webp')}
                            alt=''
                        />
                    </a>
                </div>
            </div>
        </div>
    )
}

export default DownApp