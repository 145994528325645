import translations_ from "../../interfaces/translations.interface";
import lang from "../../languages/lang";


type tx_ = [
    string, string, string, string, string,
    string, string, string, string, string,
    string, string, string
]

const def_tx: translations_<tx_> = {
    "pt-BR": [
        'Principais Categorias',

        'Faça login',
        'ou cadastre-se',
        'Anunciar',
        'Entrar',
        'Cadastrar',
        'Minhas vendas',
        'Minhas compras',
        'Anunciar meu serviço',
        'Meus anúncios',
        'Dados pessoais',
        'Localização',
        'Categorias'

    ],
    "en-US": [
        'Main Categories',

        'Log in',
        'or register',
        'Advertise',
        'Log in',
        'Register',
        'My sales',
        'My purchases',
        'Advertise my service',
        'My ads',
        'Personal data',
        'Location',
        'Categories'
    ]
};

const tx = def_tx[lang];
export default tx;