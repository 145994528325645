import { useContext } from 'react';
import Avatar from '../../../../imagesComponents/Avatar';
import DetailsContext from '../../context';
import './styles.css';
import hideContactsDescription from '../../../../../functions/hideContactsDescription.simple';

function ProfDetailsSquare() {
    const {
        user_description, user_name,
        user_uid, user_profession
    } = useContext(DetailsContext).work || {};

    return (
        <div
            className={`Details-user`}
        >
            <div className='DUser-wrapper'>
                <div className='DUser-wrapper-columns'>

                    <div className='DUser-img-wrap'>
                        <div className='DUser-img center'>
                            <Avatar
                                borderRadius="12px"
                                uid={user_uid}
                                size={20}
                            />
                        </div>
                    </div>
                    <div className='DUser-informations'>
                        <h6
                            className="ellipsis du-max-90"
                            style={{ maxWidth: '100%' }
                            }
                        >
                            {user_profession}
                        </h6>
                        <div
                            className="du-100 center"
                        >
                            <h4
                                className='ellipsis'
                                style={{ maxWidth: '100%' }}
                            >
                                {user_name}
                            </h4>
                        </div>
                        <p
                            className={'description-ellipsis'}
                        >
                            {hideContactsDescription(user_description)}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProfDetailsSquare