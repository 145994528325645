import translations_ from "../../../interfaces/translations.interface";
import lang from "../../../languages/lang";


type tx_ = { fail: string, change_p: string, r:string }

const def_tx: translations_<tx_> = {
    "pt-BR": {
        fail: 'Falha ao alterar foto, por favor tente novamente mais tarde',
        change_p: 'Alterar foto de perfil',
        r: 'Remover'
    },
    "en-US": {
        fail: 'Failed to change photo, please try again later',
        change_p: 'Change profile picture',
        r: 'Remove'
    }
};

const tx = def_tx[lang];
export default tx;